import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  Box,
  Table,
  Select,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  setSelectOptions,
  Pagination,
  sliceTableData,
  Modal,
  AuthProvider
} from '@oneclass/ui-components';
import { getQueryPracticeList, postPracticeStart } from 'api/practice';
import { getCookie } from 'utils/common';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { getTime, format } from 'date-fns';
import { useBoolean } from 'ahooks';
import { Tooltip } from 'antd';
import { StyledStudentExamPaperList, StyledFilterBar } from './StudentExamPaperList.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { SlidersHSolid } from '@onedesign/icon';
import classNames from 'classnames';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { useLocation, useHistory } from 'react-router-dom';
import { getQueryExamPaper } from 'api/examPaper';

const examStatusOptions = [
  {
    value: 'not',
    name: '未測驗'
  },
  {
    value: 'isReport',
    name: '已測驗'
  },
  {
    value: 'isExam',
    name: '已建立'
  }
];

const examStatusOptionsMobile = [
  {
    value: 'not',
    label: '未測驗'
  },
  {
    value: 'isReport',
    label: '已測驗'
  },
  {
    value: 'isExam',
    label: '已建立'
  }
];

export const StudentExamPaperList = ({
  className,
  currentTab,
  isLoading,
  setIsLoading,
}) => {
  const location = useLocation();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [filterParams, setFilterParams] = useState({});
  const { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse } = useEduSubjectModal(authState.context.userDetails?.preference, 'mainLayout', location.pathname);
  const [data, setData] = useState([]); // 存放 試卷清單。
  const [eduSubjectOptions, setEduSubjectOptions] = useState([]);

  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const [stateGetQueryExamPaper, sendGetQueryExamPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setIsLoading(true);
        const res = await getQueryExamPaper();
        const { data, isSuccess, systemCode, message } = res;
        const { yearMap = [], eduSubjectMap = [], typeMap = [], patternMap = [], examPaper = [] } = data || {};
        if (isSuccess) {
          setEduSubjectOptions(setSelectOptions(eduSubjectMap) || []);
          setData(examPaper);
        }
        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
          yearOptions: setSelectOptions(yearMap) || [],
          // eduSubjectOptions: setSelectOptions(eduSubjectMap) || [],
          typeOptions: setSelectOptions(typeMap) || [],
          patternOptions: setSelectOptions(patternMap) || [],
          examPaper,
        };
      },
    },
  });

  // const [stateGetQueryPracticeList, sendGetQueryPracticeList] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async(_context, event) => {
  //       const res = await getQueryPracticeList();
  //       const { data, isSuccess, systemCode, message } = res;
  //       const { eduSubjectMap = [], practice = [] } = data || {};

  //       return {
  //         isSuccess, systemCode, message,
  //         eduSubjectOptions: setSelectOptions(eduSubjectMap) || [],
  //         practice
  //       };
  //     },
  //   },
  // });

  const [statePostPracticeStart, sendPostPracticeStart] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postPracticeStart(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);

        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  // const { eduSubjectOptions, practice } = stateGetQueryPracticeList.context.result || {};

  const filterData = useMemo(() => {
    if (!data) return [];
    const nextData = data.filter((item) => {
      const checkResult = Object.entries(filterParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (!value) return true;
        if (key === 'eduSubject') {
          return item.eduSubject === value;
        } else if (key === 'examStatus') {
          if (value === 'not') {
            return (!item.isReport && !item.isExam);
          } else {
            return item[value];
          }
        } else {
          return item.attributes[key] === value;
        }

      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;

    });
    return nextData;
  }, [data, filterParams]);

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const setFilterCondition = (value, key) => {
    setFilterParams({
      ...filterParams,
      [key]: value
    });
  };

  const goToExamPage = (uid) => {
    const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
    const payload = {
      uid,
      oneclubToken
    };
    sendPostPracticeStart(FETCH_DATA_EVENT.FETCH, {
      payload
    });
  };

  useEffect(() => {
    if (currentTab === '1') {
      // sendGetQueryPracticeList(FETCH_DATA_EVENT.FETCH);
      sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH);
      const main = document.getElementById('mainLayout');
      main.scrollTop = 0;
    }
  }, [currentTab]);

  // useEffect(() => {
  //   if (statePostPracticeStart.matches(FETCH_DATA_STATE.DONE)) {
  //     sendGetQueryPracticeList(FETCH_DATA_EVENT.FETCH);
  //   }
  // }, [statePostPracticeStart.value]);

  // 手機版 相關

  const [filterParamsMobile, setFilterParamsMobile] = useState({});
  const [eduSubjectMobile, setEduSubjectMobile] = useState({
    edu: '',
    subject: '',
  });
  const [modalDetailData, setModalDetailData] = useState('');
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);

  // 詳情彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (modalVisible | filterDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [modalVisible, filterDrawerVisible]);

  // 重新組成 學制 / 科目 列表
  const eduOptions = useMemo(() => {
    let eduArray = [];
    let checkArray = [];
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (checkArray.indexOf(item.value.slice(0, 1)) === -1) {
          let order = 0;
          if (item.value.slice(0, 1) === 'J') order = 1;
          if (item.value.slice(0, 1) === 'H') order = 2;
          eduArray.push({
            label: item.name.slice(0, 2),
            value: item.value.slice(0, 1),
            order
          });
          checkArray.push(item.value.slice(0, 1));
        }
      });
    }
    return eduArray.sort((a, b) => {
      return a.order - b.order;
    });
  }, [eduSubjectOptions]);

  const subjectOptions = useMemo(() => {
    let subjectArray = {};
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (subjectArray[item.value.slice(0, 1)]) {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)].push({
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          });
          subjectArray = next;
        } else {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)] = [{
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          }];
          subjectArray = next;
        }
      });
    }
    return subjectArray;
  }, [eduSubjectOptions]);

  const showDetailModal = (item) => {
    setModalDetailData(item);
    setModalVisibleToggle();
  };

  useEffect(() => {
    if (eduSubjectMobile.edu && eduSubjectMobile.subject) {
      setFilterParamsMobile({
        ...filterParamsMobile,
        eduSubject: eduSubjectMobile.edu + eduSubjectMobile.subject,
      });
    }
  }, [eduSubjectMobile]);

  const transformMobileOptions = (array) => {
    let options = [];
    if (array && array.length > 0) {
      array.forEach((item) => {
        options.push({
          label: item.name,
          value: item.value
        });
      });
    }
    return options;
  };

  const changeEduSubjectMobile = (val, type) => {
    if (type === 'edu') {
      setEduSubjectMobile({
        edu: val,
        subject: '',
      });
    } else {
      setEduSubjectMobile({
        ...eduSubjectMobile,
        subject: val,
      });
    }
    setFilterIsChanged(true);
  };

  const changeFilterParamsMobile = (val, type) => {
    setFilterParamsMobile({
      ...filterParamsMobile,
      [type]: val,
    });
    setFilterIsChanged(true);
  };

  const showMobileFilter = () => {
    filterDrawerToggle();
    setFilterIsChanged(false);
    setEduSubjectMobile({
      edu: filterParams?.eduSubject ? filterParams?.eduSubject.slice(0, 1) : '',
      subject: filterParams?.eduSubject ? filterParams?.eduSubject.slice(1, 3) : '',
    });
    setFilterParamsMobile(filterParams);
  };

  const clearAllFilter = () => {
    if (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu) {
      setFilterParamsMobile({});
      setEduSubjectMobile({
        edu: '',
        subject: '',
      });
      setFilterIsChanged(true);
    }
  };

  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const checkChangeFilterBtn = useMemo(() => {
    if (filterIsChanged) {
      if (eduSubjectMobile.edu) {
        if (eduSubjectMobile.subject) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [eduSubjectMobile, filterIsChanged]);

  const doneChangeFilter = () => {
    setFilterParams(filterParamsMobile);
    filterDrawerFalse();
  };

  // 組成 試卷範圍字串。
  const formatPaperRange = (yearName, booksArray) => {
    if (!yearName || !booksArray || booksArray.length === 0) return '';
    let booksName = '';
    booksArray.forEach((book, index) => {
      if (index !== 0) {
        booksName += '、';
      }
      booksName += book;
    });
    let res = `${yearName} - ${booksName}`;
    const num = deviceData.isDesktop ? 40 : 25;
    if (res.length > num) res = (res.substring(0, num) + '...');
    return res;
  };

  return (
    <StyledStudentExamPaperList className={className} data-testid="StudentExamPaperList">
      {
        deviceData.isDesktop ?
          <>
            <Box mb={4} mt={9}>
              <StyledFilterBar>
                <Select placeholder='學制科目' allowClear={filterParams?.eduSubject ? true : false} options={eduSubjectOptions} value={filterParams.eduSubject || null} onChange={(val) => {setFilterCondition(val, 'eduSubject');}} />
                {/* <Select placeholder='狀態' allowClear={filterParams?.examStatus ? true : false} options={examStatusOptions} value={filterParams.examStatus || null} onChange={(val) => {setFilterCondition(val, 'examStatus');}} /> */}
              </StyledFilterBar>
            </Box>
          </>
          :
          <div className='filterBtnBox'>
            <div className={Object.keys(filterParams).length !== 0 ? 'active' : ''}>
              <SlidersHSolid onClick={showMobileFilter}/>
            </div>
          </div>
      }
      <Box>
        <Table>
          {
            deviceData.isDesktop &&
            <Table.Header>
              <Table.Row>
                <Table.Item flex={2.5} >出卷時間</Table.Item>
                <Table.Item flex={5} >試卷範圍</Table.Item>
                <Table.Item flex={1.5}>學制科目</Table.Item>
                <Table.Item flex={0.75} >題數</Table.Item>
                {/* <Table.Item >狀態</Table.Item> */}
                <Table.Item flex={4}>操作</Table.Item>
              </Table.Row>
            </Table.Header>
          }
          <Table.Body className='tableBody'>
            {
              filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                <Table.Row key={item.uid} >
                  {
                    deviceData.isDesktop ?
                      <>
                        <Table.Item flex={2.5}>{format(getTime(new Date(item?.createTime)), 'yyyy/MM/dd HH:mm')}</Table.Item>
                        <Table.Item flex={5} >
                          {/* <Tooltip placement="top" title={item.name} zIndex={100} color='#4e4e5a' > */}
                          {formatPaperRange(item?.attributes?.yearName, item?.attributes?.bookNames)}
                          {/* </Tooltip> */}
                        </Table.Item>
                        <Table.Item flex={1.5}>{item.eduSubjectName}</Table.Item>
                        <Table.Item flex={0.75}>{item?.attributes?.questionCount}題</Table.Item>
                        {/* <Table.Item className={(!item.isExam && !item.isReport) ? 'warning' : 'normal'} >{item.status}</Table.Item> */}
                        <Table.Item flex={4} className='btnTableItem'>
                          <Button variant='brand1' onClick={() => { window.open(item?.examUrl);}}>前往測驗</Button>
                          {/* {
                            item.isReport ?
                              <>
                                <Button variant='brand1' onClick={() => {(item.examPath) ? window.open(item.examPath) : goToExamPage(item.uid);}}>再次測驗</Button>
                                {
                                  item.examReport &&
                                  <Button type="solid" textcolor={'#121232'} variant="brand1" onClick={() => {window.open(item.examReport);}}>測驗結果</Button>
                                }
                              </> :
                              <Button variant='brand1' onClick={() => { (item.isExam && item.examPath) ? window.open(item.examPath) : goToExamPage(item.uid);}}>前往測驗</Button>
                          } */}
                        </Table.Item>
                      </>
                      :
                      <Table.Item className='mobileItem' onClick={() => showDetailModal(item)}>
                        <div>{item?.eduSubjectName}</div>
                        <div>{formatPaperRange(item?.attributes?.yearName, item?.attributes?.bookNames)}</div>
                        <div>
                          <div>{format(getTime(new Date(item?.createTime)), 'yyyy/MM/dd HH:mm')}</div>
                          {/* <div className={(!item.isExam && !item.isReport) ? 'warning' : 'normal'}>{item.status}</div> */}
                        </div>
                      </Table.Item>
                  }
                </Table.Row>
              )) :
                <div className="tableEmpty">
                  <div>
                    目前還未擁有任何試卷喔!
                  </div>
                  {
                    authState?.context?.userDetails &&
                    <>
                      <Button borderRadius='24px' variant={'brand1'} className="makeATextPaperBtn" onClick={setEduSubjectModalToggle}>
                          我要出卷
                      </Button>
                      {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
                    </>
                  }
                </div>
            }
          </Table.Body>
        </Table>
        {
          filterData?.length > pageState.limit &&
          <div className="pageBlock">
            <Pagination
              total={filterData?.length || 0}
              defaultCurrent={1}
              current={pageState.currentPage}
              onChange={pageChange}
              defaultPageSize={pageState.limit}
            />
          </div>
        }
      </Box>

      {/* 試卷詳情彈窗 */}
      {
        modalDetailData &&
        <Modal
          id={'detailModal'}
          visible={modalVisible}
          width={'100%'}
          onCancel={setModalVisibleFalse}
          title={'試卷詳情'}
        >
          <div className='detailModalContent'>
            <div>
              <div>學制科目</div>
              <div>{modalDetailData.eduSubjectName}</div>
            </div>
            <div>
              <div>試題範圍</div>
              <div>{modalDetailData.name}</div>
            </div>
            <div>
              <div>題數</div>
              <div>{modalDetailData.amount}題</div>
            </div>
            <div>
              <div>出卷時間</div>
              <div>{format(getTime(new Date(modalDetailData.createTime)), 'yyyy/MM/dd HH:mm')}</div>
            </div>
            {/* <div>
              <div>測驗狀態</div>
              <div className={(!modalDetailData.isExam && !modalDetailData.isReport) ? 'warning' : 'normal'}>{modalDetailData.status}</div>
            </div> */}
          </div>
          <div className='detailModalBtnBox'>
            {
              modalDetailData.isReport ?
                <>
                  <Button variant='brand1' onClick={() => {(modalDetailData.examPath) ? window.open(modalDetailData.examPath) : goToExamPage(modalDetailData.uid);}}>再次測驗</Button>
                  {
                    modalDetailData.examReport &&
                    <Button type="solid" textcolor={'#121232'} variant="brand1" onClick={() => {window.open(modalDetailData.examReport);}}>測驗結果</Button>
                  }
                </> :
                <Button variant='brand1' onClick={() => { (modalDetailData.isExam && modalDetailData.examPath) ? window.open(modalDetailData.examPath) : goToExamPage(modalDetailData.uid);}}>前往測驗</Button>
            }
          </div>
        </Modal>
      }

      {/* 篩選彈窗 */}
      <Modal
        id={'filterDrawer'}
        visible={filterDrawerVisible}
        zIndex={1001}
        onCancel={filterDrawerFalse}
        width='100%'
        title={
          <>
            <span>篩選</span><div className={classNames('clearAllFilterBtn', (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu) ? 'active' : '' )} onClick={clearAllFilter}>清除</div>
          </>
        }
        footer={
          <>
            <Button
              className='reportModalBtn'
              variant={!checkChangeFilterBtn ? 'ui02' : 'brand2'}
              disabled={!checkChangeFilterBtn}
              onClick={doneChangeFilter}
            >使用</Button>
          </>
        }
      >
        <div className='filterDrawerContent'>
          <div className='title'>學制科目</div>
          <div>
            <div className='subTitle'>學制</div>
            <RadioGroup
              key={'eduOptions'}
              name={'eduOptions'}
              className='radioList' buttonStyle='solid'
              optionType='button'
              options={eduOptions}
              value={eduSubjectMobile.edu}
              onChange={(e) => {changeEduSubjectMobile(e.target.value, 'edu');}}
            />
          </div>
          {
            eduSubjectMobile.edu &&
            <div>
              <div className='subTitle'>科目</div>
              <RadioGroup
                key={'subjectOptions'}
                name={'subjectOptions'}
                className='radioList' buttonStyle='solid'
                optionType='button'
                options={subjectOptions[eduSubjectMobile.edu]}
                value={eduSubjectMobile.subject}
                onChange={(e) => {changeEduSubjectMobile(e.target.value, 'subject');}}
              />
            </div>
          }
        </div>
        {/* <div className='filterDrawerContent'>
          <div className='title'>測驗狀態</div>
          <RadioGroup
            key={examStatusOptionsMobile}
            className='radioList' buttonStyle='solid'
            optionType='button'
            options={examStatusOptionsMobile}
            value={filterParamsMobile.examStatus}
            onChange={(e) => {changeFilterParamsMobile(e.target.value, 'examStatus');}}
          />
        </div> */}
      </Modal>
    </StyledStudentExamPaperList>
  );
};
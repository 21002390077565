import React from 'react';
import PropTypes from 'prop-types';
import { isAfter, isBefore } from 'date-fns';
import { StyledStatusLabel } from './StatusLabel.style';

export const StatusLabel = ({ isNew, startTime, endTime }) => {
  const currentTime = new Date();
  const startTime_ = new Date(startTime);
  const endTime_ = new Date(endTime);
  if (isNew) {
    return <StyledStatusLabel className='new'>NEW</StyledStatusLabel>;
  }
  if (isBefore(currentTime, endTime_) && isAfter(currentTime, startTime_)) {
    return <StyledStatusLabel className='processing'>進行中</StyledStatusLabel>;
  } else if (isBefore(currentTime, startTime_)) {
    return <StyledStatusLabel className='preparing'>未開始</StyledStatusLabel>;
  } else if (isAfter(currentTime, endTime_)) {
    return <StyledStatusLabel className='finished'>已結束</StyledStatusLabel>;
  }
  return '';
};

StatusLabel.propTypes = {
  isNew: PropTypes.bool,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};
import React, { useEffect } from 'react';
import { StyledQuestionLimitHint } from './QuestionLimitHint.style';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { PROCESS_PARAMS } from 'constants/index';

export const QuestionLimitHint = ({ className, currentQuesNum, processType, questionNumLimit }) => {

  return (
    <StyledQuestionLimitHint className={className} data-testid="QuestionLimitHint">
      <ExclamationCircleSolid/>
      每卷{PROCESS_PARAMS[processType]?.quesLimitText}數上限為{questionNumLimit}{PROCESS_PARAMS[processType]?.quesLimitText}
      ，已選{currentQuesNum}{PROCESS_PARAMS[processType]?.quesLimitText}，請減少{PROCESS_PARAMS[processType]?.quesLimitText}數。
    </StyledQuestionLimitHint>
  );
};

/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
  EquationDojoAreaSelectContent,
  EquationDojoQuestionSelectContent,
  EquationDojoLayoutOptionContent,
  EquationDojoQuestionReviewContent,
  HomeQuestionCompleteContent,
} from 'containers';
import {
  StepBox,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  Modal,
  AuthProvider,
  CreateExamPaperParamsProvider,
  MobileStepBox,
  EditCopyExamPaperProvider,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import {
  ExamPaperHeader,
  QuestionLimitHint,
  QuestionLimitModal,
} from 'components';
import { Button } from '@oneclass/onedesign';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { postExamCreate, postExamEdit } from 'api/examPaper';
import {
  setCookie,
  getCookie,
  formatSelectedBook,
  autoSettingScore,
} from 'utils/common';
import { useBoolean } from 'ahooks';
import { useHistory } from 'react-router-dom';
import {
  StyledEquationDojoPage,
  StyledEquationDojoPageContent,
  UiBtnZone,
} from './EquationDojoPage.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { postMemberTypeSetting } from 'api/member';
import { postGetDojos } from 'api/query';
import { PROCESS_PARAMS } from 'constants/index';
import { useFilterQuestionModal } from 'utils/hooks/useFilterQuestionModal';
import * as GA from 'utils/googleAnalytics';
import SuspendedQuestionsBar from 'components/SuspendedQuestionsBar';

const steps = [
  {
    title: '範圍選擇',
  },
  {
    title: '試題選擇',
  },
  {
    title: '出題預覽',
  },
  {
    title: '版面配置',
  },
  {
    title: '出卷完成',
  },
];

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
};
const formateTableData = (data) => {
  if (!data) return null;
  let nextData = {};
  Object.entries(data).forEach(([key, value], index) => {
    nextData = {
      ...nextData,
      [key]: {
        ...value,
        inputValue: defaultCountInputValue,
      },
    };
  });
  return nextData;
};
const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};
const initSaveData = {
  education: '',
  subject: '',
  areaParams: {},
  quesReviewQuestionRenderList: {},
  createExamPaperParams: {},
  tableData: {},
  examUID: '',
};

export const EquationDojoPage = ({ className, props }) => {
  const { setIsLoadingMark } = props;
  const { deviceData, deviceDataProviderChange } = useContext(
    deviceProvider.deviceProviderContext
  );
  const { editCopyExamPaperData, resetCopyEditPaperParams } = useContext(
    EditCopyExamPaperProvider.EditCopyExamPaperContext
  );
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { createExamPaperParams: globalExamPaperData } = useContext(
    CreateExamPaperParamsProvider.createExamPaperContext
  );
  const [saveData, setSaveData] = useState({ ...initSaveData });
  const [areaParams, setAreaParams] = useState({});
  const [sendParams, setSendParams] = useState({});
  const [resetToggle, setResetToggle] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [step, setStep] = useState(1);
  const [checkedSend, setCheckedSend] = useState([]);
  const [jumpStep, setJumpStep] = useState(null);
  const [createExamPaperParams, setCreateExamPaperParams] = useState({});
  const [questions, setQuestions] = useState([]);
  const [tableData, setTableData] = useState({});
  const [
    areaModalVisible,
    { toggle: setAreaModalToggle, setFalse: setAreaModalFalse },
  ] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [reCreateExam, setReCreateExam] = useState(false);
  const [reCreateSearchParams, setReCreateSearchParams] = useState({});
  const [isJumpToStep2, setIsJumpToStep2] = useState(false);
  const [
    emptyTextModalVisible,
    { toggle: setEmptyTextModalToggle, setFalse: setEmptyTextModalFalse },
  ] = useBoolean(false);
  const [isGoBack, setIsGoBack] = useState(false);
  const [
    questionCountModalVisible,
    {
      toggle: setQuestionCountModalToggle,
      setFalse: setQuestionCountModalFalse,
    },
  ] = useBoolean(false);
  const [isShowQuestionLimitText, setIsShowQuestionLimitText] = useState(false);
  const [reBuildData, setReBuildData] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [isAgainDo, setIsAgainDo] = useState(false);
  const [bookList, setBookList] = useState({});
  const [treeCheckedList, setTreeCheckedList] = useState([]);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitResponseData, setSubmitResponseData] = useState(null);
  const [isSuspendedQuestionsBarOpen, setIsSuspendedQuestionsBarOpen] = useState(true);

  // 計算 試卷中各種題型的 總分、總題數、總答數。
  const totalQuesTypeData = useMemo(() => {
    let nextData = {
      score: 0,
      questionCount: 0,
      totalAnswer: 0,
    };
    if (!tableData) return nextData;

    let score = 0;
    let questionCount = 0;
    let totalAnswer = 0;
    Object.values(tableData).forEach(item => {
      score += Number(item.inputValue.score.allScore) || 0;
      questionCount += item.inputValue.question ? parseInt(item.inputValue.question) : 0;
      totalAnswer += Number(item.inputValue.answer) || 0;
    });
    nextData = {
      score: Number(Number(score).toFixed(1)) || 0, // 試卷總分。
      questionCount: parseInt(questionCount) || 0, // 總題數。
      totalAnswer: Number(totalAnswer) || 0, // 總答數。
    };
    return nextData;
  }, [tableData]);
  // 出卷類型。
  const processType = useMemo(() => {
    return (
      createExamPaperParams?.outputType || globalExamPaperData.output || null
    );
  }, [globalExamPaperData.output, createExamPaperParams.outputType]);

  // 題數/答數 限制
  const questionNumLimit = useMemo(() => {
    if (!processType) return 0;
    return PROCESS_PARAMS[processType]?.questionLimit;
  }, [processType]);

  // 要用來判斷的 題數/答數
  const currentQuesNum = useMemo(() => {
    return totalQuesTypeData?.totalAnswer;
  }, [processType, totalQuesTypeData.totalAnswer]);

  // 儲存使用者設定
  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          // 成功顯示
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess,
          systemCode,
          message,
        };
      },
    },
  });

  const getSendParams = (data) => {
    setSendParams(data);
  };

  // 取得試題類型
  const [stateGetQuestionType, sendGetQuestionType] = useMachine(
    fetchDataMachine,
    {
      services: {
        fetchData: async (_context, event) => {
          setIsLoadingMark(true);
          const res = await postGetDojos(event.payload);
          const { isSuccess, systemCode, message } = res;
          const data = res?.data?.dojoGroup;
          if (isSuccess) {
            if (JSON.stringify(transformQuestionTypeData(data)) !== '{}') {
              goTop();
            }
          }
          setReBuildData(transformQuestionTypeData(data));
          setOriginalData(data);
          setIsLoadingMark(false);
          return {
            isSuccess,
            systemCode,
            message,
          };
        },
      },
    }
  );
  // 過濾試題 相關 －－－

  // 初始化資料 相關
  const initQuestionTypeData = () => {
    setSaveData({
      ...initSaveData,
      areaParams,
      education: globalExamPaperData?.edu || 'J',
      subject: globalExamPaperData?.subject || 'MA',
    });
    setCreateExamPaperParams({});
  };

  const {
    renderFilterQuestionModal,
    filterQuestionModalToggle,
    setFilterQuestionModalFalse,
    filterPaperUID,
    filterQuestions,
    isChangeFilter,
    setIsChangeFilter,
    setFilterPaperUID,
    paperFilter,
  } = useFilterQuestionModal(initQuestionTypeData);

  useEffect(() => {
    if (isAgainDo) {
      setFilterPaperUID([]);
      setIsAgainDo(false);
    }
  }, [isAgainDo]);

  const data = useMemo(() => {
    if (!reBuildData | !originalData) return {};
    if (!filterQuestions || filterQuestions.length === 0) return reBuildData;
    let nextData = {
      ...reBuildData,
    };
    originalData.forEach((quesType) => {
      quesType.questions.forEach((ques) => {
        if (filterQuestions.indexOf(ques?.uid) !== -1) {
          nextData = {
            ...nextData,
            [quesType?.groupName]: {
              ...nextData[quesType?.groupName],
              questions: nextData[quesType?.groupName]?.questions.filter(
                (item) => {
                  return filterQuestions.indexOf(item?.uid) === -1;
                }
              ),
            },
          };
        }
      });
    });
    return nextData;
  }, [filterQuestions, originalData]);
  // 建立測驗
  const [statePostExamCreate, sendPostExamCreate] = useMachine(
    fetchDataMachine,
    {
      services: {
        fetchData: async (_context, event) => {
          setIsLoading(true);
          const res = await postExamCreate(event.payload);
          const { data, isSuccess, systemCode, message } = res;
          const { examUID } = data;
          if (isSuccess) {
            setCreateExamPaperParams({
              ...createExamPaperParams,
              examUID: examUID,
            });
          } else {
            openNotificationWithIcon('error', message);
          }
          setIsSubmitSuccess(isSuccess);
          setSubmitResponseData(data);
          return {
            createIsSuccess: isSuccess,
            isSuccess,
            systemCode,
            message,
            data,
          };
        },
      },
    }
  );

  // 編輯試卷
  const [statePostExamEdit, sendPostExamEdit] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postExamEdit(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          setCreateExamPaperParams({
            ...createExamPaperParams,
            examUID: event.payload.examUID,
          });
        } else {
          openNotificationWithIcon('error', message);
        }
        setIsSubmitSuccess(isSuccess);
        setSubmitResponseData(data);
        return {
          editIsSuccess: isSuccess,
          systemCode,
          message,
          data,
        };
      },
    },
  });

  const transformQuestionTypeData = (data) => {
    if (!data) return {};
    let nextData = {};
    data.forEach((item) => {
      const { groupName, questions, answer, questionType } = item;
      const question = questions.map(item => {
        return {
          imageUrl: item.imageUrl,
          uid: item.questionId,
          name: groupName,
          answerAmount: answer,
        };
      });
      let newData = {
        ...nextData,
        [groupName]: {
          code: questionType,
          count: {
            answer: answer,
            answerTotal: questions.length * answer,
            questionTotal: questions.length,
          },
          name: groupName,
          questions: question,
        },
      };
      nextData = newData;
    });
    return nextData;
  };
  const searchParams = useMemo(() => {
    return {
      eduSubject:
        editCopyExamPaperData?.eduSubject ||
        globalExamPaperData?.edu + globalExamPaperData?.subject,
      books: editCopyExamPaperData?.bookIDs || areaParams?.bookIDs,
      knowledges: editCopyExamPaperData?.knowledgeIDs || areaParams?.keys,
      sources: editCopyExamPaperData?.sourceIDs || areaParams?.source,
      curriculum: editCopyExamPaperData?.curriculum || areaParams.curriculum,
      year: editCopyExamPaperData?.year || areaParams.year,
      version: editCopyExamPaperData?.version || areaParams.version,
      isQuick:
        (editCopyExamPaperData?.output || globalExamPaperData.output) ===
        'Quick',
    };
  }, [
    globalExamPaperData.edu,
    globalExamPaperData.subject,
    areaParams.bookIDs,
    areaParams.keys,
    areaParams.source,
    areaParams.year,
    areaParams.version,
    areaParams.curriculum,
    globalExamPaperData.output,
    editCopyExamPaperData,
  ]);

  const disabled = useMemo(() => {
    let result = false;
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = [
      'amount',
      'paperSize',
      'wordSetting',
      'paperContents',
    ];
    let pickupQuestion = [];
    switch (step) {
      case 4:
        if (createExamPaperParams?.outputType !== 'Files') {
          const {
            paperName = {},
            outputType = '',
            examType = '',
          } = createExamPaperParams || {};
          const checkParams = {
            paperName: paperName?.value || '',
            outputType,
            examType,
          };
          Object.entries(checkParams).forEach(([key, value]) => {
            if (value === '') result = true;
          });
        } else {
          Object.entries(createExamPaperParams).forEach(([key, value]) => {
            if (
              (key === 'grade' || key === 'room') &&
              checkedSend.findIndex((item) => item === 'classInfo') !== -1
            ) {
              if (value.value === '') result = true;
            }
            const needCheck =
              checkArray.findIndex((item) => item === key) !== -1 &&
              checkedSend.findIndex((item) => item === key) !== -1;
            if (needCheck) {
              if (value.value === '') result = true;
            }
            if (advancedCheckArray.findIndex((item) => item === key) !== -1) {
              if (!value || value === '' || value.length === 0) result = true;
            }
          });
        }

        break;
      case 3:
        Object.entries(tableData).forEach(([key, value]) => {
          if (value?.pickUpQuestions) {
            Object.entries(value?.pickUpQuestions).forEach(
              ([pickKey, pickValue]) => {
                pickupQuestion = pickupQuestion.concat(pickValue);
              }
            );
          }
        });
        if (pickupQuestion.length === 0) return true;
        break;
      case 2:
        if (
          !totalQuesTypeData?.questionCount ||
          totalQuesTypeData.questionCount === 0
        )
          return true;
        break;
      case 1:
        Object.entries(areaParams).forEach(([key, value]) => {
          if (!value || value.length === 0) result = true;
        });
        break;
      default:
        break;
    }
    return result;
  }, [step, areaParams, tableData, createExamPaperParams, totalQuesTypeData]);

  const isChangeData = useMemo(() => {
    let result = false;
    if (step === 1) {
      if (
        Object.keys(saveData.areaParams).length === 0 ||
        Object.keys(areaParams).length === 0
      )
        return false;
      Object.entries(areaParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (saveData.areaParams[key].length === value.length) {
            if (
              saveData.areaParams[key].filter((item) => !value.includes(item))
                .length > 0
            )
              result = true;
          } else {
            result = true;
          }
        } else {
          if (saveData.areaParams[key] !== value) result = true;
        }
      });
    }
    return result;
  }, [areaParams, step, tableData]);

  const getSendAreaParams = (data) => {
    setAreaParams({ ...data });
  };
  const getTableData = (data) => {
    setTableData({ ...data });
  };
  const getKeyList = (data) => {
    setKeyList([...data]);
  };
  const getQuestions = (data) => {
    setQuestions([...data]);
  };
  const getCreateExamPaperParams = (data) => {
    setCreateExamPaperParams(data);
  };
  const getCheckedSend = (data) => {
    setCheckedSend(data);
  };
  const getSaveData = (key, data) => {
    setSaveData({ ...saveData, [key]: data });
  };
  const prevStep = () => {
    setStep(step - 1);
    goTop();
  };

  const onStepClick = (current) => {
    GA.clickStep(steps[current].title);
    setIsGoBack(true);
    setStep(current + 1);
  };

  const resetData = () => {
    setSaveData({ ...initSaveData });
    setTableData({});
    setQuestions([]);
    setCreateExamPaperParams({});
    setKeyList([]);
  };

  const questionGroups = useMemo(() => {
    if (!tableData) return;
    let nextData = keyList
      .map((key) => {
        let questionList = [];
        if (JSON.stringify(saveData?.quesReviewQuestionRenderList) !== '{}') {
          saveData?.quesReviewQuestionRenderList[key] &&
            saveData?.quesReviewQuestionRenderList[key].forEach((ques) => {
              questionList.push(ques.uid);
            });
        } else {
          if (tableData[key]?.pickUpQuestions) {
            Object.values(tableData[key].pickUpQuestions.questionsList
            ).forEach((question) => {
              questionList = [...questionList, question.uid];
            }
            );
          }
        }
        return {
          typeCode: tableData[key].code,
          scoreType: tableData[key].inputValue.score.perQues
            ? 'PerQuestion'
            : 'PerAnswer',
          score: tableData[key].inputValue.score.perQues
            ? Number(
              Number(tableData[key].inputValue.score.perQues).toFixed(1)
            ) || 0
            : Number(
              Number(tableData[key].inputValue.score.perAns).toFixed(1)
            ) || 0,
          questionList,
        };
      })
      .filter((item) => item.questionList.length > 0);
    return nextData;
  }, [tableData, keyList]);

  const isEditCopy = useMemo(() => {
    if (
      editCopyExamPaperData &&
      JSON.stringify(editCopyExamPaperData) !== '{}'
    ) {
      setIsLoading(true);
      return true;
    } else {
      return false;
    }
  }, [editCopyExamPaperData]);

  const eduSubjectData = useMemo(() => {
    return isEditCopy ? editCopyExamPaperData : globalExamPaperData;
  }, [isEditCopy, editCopyExamPaperData, globalExamPaperData]);

  const sendStep4Api = () => {
    const {
      schoolName = {},
      paperName,
      teacherSign = {},
      grade = {},
      room = {},
      paperSize,
      wordSetting,
      paperContents,
      analyzeContent,
      amount,
      advanced,
      studentSign,
      eduSubject,
      ordering,
      header,
    } = sendParams || {};

    let setting = {};
    switch (processType) {
      case 'Files':
        // 組成試卷 - 紙本
        setting = {
          schoolName:
            checkedSend.findIndex((item) => item === 'schoolName') !== -1
              ? schoolName.value
              : '',
          paperName: paperName.value,
          teacherSign:
            checkedSend.findIndex((item) => item === 'teacherSign') !== -1
              ? teacherSign.value
              : '',
          grade:
            checkedSend.findIndex((item) => item === 'classInfo') !== -1
              ? grade.value
              : '',
          room:
            checkedSend.findIndex((item) => item === 'classInfo') !== -1
              ? room.value
              : '',
          eduSubject:
            checkedSend.findIndex((item) => item === 'eduSubject') !== -1
              ? eduSubject
              : '',
          studentSign:
            checkedSend.findIndex((item) => item === 'studentSign') !== -1
              ? studentSign
              : '',
          paperSize,
          wordSetting,
          paperContents: paperContents || [],
          analyzeContent: analyzeContent || [],
          amount,
          advanced: advanced || [],
          ordering,
          header,
        };
        break;
      case 'Online':
      case 'Quick':
        // 組成試卷 - 速測
        setting = {
          paperName: paperName.value,
          paperSize: 'A4',
          wordSetting: globalExamPaperData?.edu === 'H' ? 'HHD' : 'VHD',
          paperContents: ['Question', 'Analyze'],
          analyzeContent: [
            'Question',
            'Answer',
            'Analyze',
            'Difficulty',
            'Source',
            'Knowledge',
          ],
          amount: 1,
          ordering: [
            'schoolName',
            'paperName',
            'teacherSign',
            'classInfo',
            'eduSubject',
            'studentSign',
          ],
          header: paperName.value,
        };
        break;
      default:
        break;
    }
    if (isEditCopy && editCopyExamPaperData?.actionType === 'Edit') {
      sendPostExamEdit(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID: editCopyExamPaperData.paperData.uid,
          examName: sendParams.paperName.value,
          outputType: sendParams.outputType,
          drawUp: 'EquationDojo',
          examType: sendParams.examType,
          isPublic: false,
          questionGroups,
          setting,
        },
      });
    } else {
      sendPostExamCreate(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: searchParams.eduSubject,
          examName: sendParams.paperName.value,
          searchCondition: {
            bookIDs: isEditCopy
              ? reCreateSearchParams.bookIDs
              : searchParams.books,
            knowledgeIDs: isEditCopy
              ? reCreateSearchParams.knowledges
              : searchParams.knowledges,
            sourceIDs: isEditCopy
              ? reCreateSearchParams.sources
              : searchParams.sources,
            curriculum: isEditCopy
              ? reCreateSearchParams.curriculum
              : searchParams.curriculum,
            year: isEditCopy ? reCreateSearchParams.year : searchParams.year,
            version: isEditCopy
              ? reCreateSearchParams.version
              : searchParams.version,
            bookSelection: selectedBooks,
          },
          examType: sendParams.examType,
          outputType: sendParams.outputType,
          drawUp: 'EquationDojo',
          questionGroups,
          paperFilter: paperFilter || [],
          setting,
        },
      });
    }
  };

  const nextStep = (jumpTo = null) => {
    setJumpStep(jumpTo);
    switch (step) {
      case 4:
        sendStep4Api();
        break;
      case 1:
        if (isChangeData) {
          setSaveData({
            ...initSaveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'MA',
            bookOptions: saveData.bookOptions,
          });
          setTableData({});
          setQuestions([]);
          setCreateExamPaperParams({});
          setKeyList([]);
        } else {
          setSaveData({
            ...saveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'MA',
          });
        }
        sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: globalExamPaperData?.edu + globalExamPaperData?.subject,
            knowledges: areaParams.keys,
            sources: areaParams.source,
          },
        });
        break;

      default:
        if (jumpTo !== null) {
          setStep(jumpTo);
        } else {
          setStep(step + 1);
        }

        goTop();
        break;
    }
  };

  useEffect(() => {
    if (!data) return;
    if (Object.keys(tableData).length > 0) return;
    const nextData = formateTableData(data);
    getTableData(nextData);
    setIsJumpToStep2(true);
  }, [data]);

  useEffect(() => {
    if (isEditCopy && isJumpToStep2) {
      // 設置一秒延遲，避免跳轉太快，父層資料尚未傳給子層。
      setTimeout(() => {
        setStep(2);
      }, 1000);
    }
  }, [isEditCopy, isJumpToStep2]);

  useEffect(() => {
    if (stateGetQuestionType.matches(FETCH_DATA_STATE.DONE) && step === 1) {
      if (!data || JSON.stringify(data) === '{}') {
        setEmptyTextModalToggle();
        return null;
      } else {
        if (!isEditCopy) setStep(step + 1);
      }
    }
  }, [stateGetQuestionType.value]);

  useEffect(() => {
    if (saveData.education === '' || saveData.subject === '') return;
    if (
      globalExamPaperData?.edu === saveData.education &&
      globalExamPaperData?.subject === saveData.subject
    )
      return;
    resetData();
    setStep(1);
  }, [globalExamPaperData]);

  useEffect(() => {
    if (
      Object.keys(tableData).length > 0 &&
      getCookie('createNewPaper') === null
    ) {
      setCookie('createNewPaper', true, 1);
    }
  }, [tableData]);

  useEffect(() => {
    Object.entries(globalExamPaperData).forEach(([key, value]) => {
      if ((key === 'edu' || key === 'subject') && value === '') {
        if (!editCopyExamPaperData[key]) {
          history.push('./');
        }
      }
    });

    if (
      !editCopyExamPaperData ||
      JSON.stringify(editCopyExamPaperData) === '{}'
    )
      return;
    const {
      bookIDs,
      eduSubject,
      knowledgeIDs,
      sourceIDs,
      output,
      curriculum,
      year,
      version,
    } = editCopyExamPaperData;

    // 取得 題目類型
    setSendParams({ bookIDs, knowledges: knowledgeIDs, sourceIDs, eduSubject });
    sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
      payload: {
        eduSubject,
        bookIDs,
        knowledges: knowledgeIDs,
        sources: sourceIDs,
        curriculum,
        year,
        version,
        isQuick: output === 'Quick',
      },
    });

    setReCreateSearchParams({
      eduSubject,
      bookIDs,
      knowledges: knowledgeIDs,
      sources: sourceIDs,
      curriculum,
      year,
      version,
      isQuick: output === 'Quick',
    });
  }, []);

  useEffect(() => {
    if ((statePostExamCreate.matches(FETCH_DATA_STATE.DONE) || statePostExamEdit.matches(FETCH_DATA_STATE.DONE)) && isSubmitSuccess) {
      setStep(step + 1);
    }
  }, [statePostExamCreate, statePostExamEdit, isSubmitSuccess]);

  useEffect(() => {
    if (authState.context.userDetails?.userStatus?.identity === 'Student') {
      history.push('/');
    }
  }, []);

  const identity = useMemo(() => {
    return authState?.context?.userDetails?.onePaperIdentity || '';
  }, [authState]);

  const prevStepDisabled = useMemo(() => {
    if (!editCopyExamPaperData?.actionType) return false;
    if (
      (editCopyExamPaperData?.actionType === 'Edit' ||
        editCopyExamPaperData?.actionType === 'Copy') &&
      step <= 2
    )
      return true;
  }, [editCopyExamPaperData?.actionType, step]);

  // 再次出題
  useEffect(() => {
    if (reCreateExam) {
      initAllData();
    }
  }, [reCreateExam]);

  const initAllData = () => {
    setSaveData({ ...initSaveData });
    setResetToggle(false);
    setAreaParams({});
    setSendParams({});
    setQuestions([]);
    setTableData({});
    setKeyList([]);
    setSearchKey('');
    setCreateExamPaperParams({});
    setCheckedSend([]);
    setJumpStep(null);
  };

  const nextStepClickHandler = () => {
    // 確認是否有超過 出題上限。
    if (currentQuesNum > questionNumLimit && step >= 2) {
      setQuestionCountModalToggle();
      setIsShowQuestionLimitText(true);
    } else {
      if (isChangeData) {
        if (step === 1) {
          setAreaModalToggle();
        }
      } else {
        nextStep();
      }
    }
  };

  useEffect(() => {
    setIsShowQuestionLimitText(false);
  }, [totalQuesTypeData.questionCount]);

  // 已選取的範圍。
  const selectedBooks = useMemo(() => {
    if (
      !editCopyExamPaperData ||
      JSON.stringify(editCopyExamPaperData) === '{}'
    ) {
      // 非編輯
      if (!areaParams?.version || !areaParams?.year || !areaParams?.curriculum)
        return {};
      if (
        !bookList ||
        JSON.stringify(bookList) === '{}' ||
        treeCheckedList.length === 0
      )
        return {};
      const filterBookList = {
        ...bookList[areaParams?.curriculum][areaParams?.year][
          areaParams?.version
        ],
      };
      return formatSelectedBook(filterBookList, treeCheckedList);
    } else {
      // 編輯時。
      const { topicSelection, bookSelection } = editCopyExamPaperData;
      return topicSelection ? topicSelection : bookSelection;
    }
  }, [
    bookList,
    areaParams?.version,
    areaParams?.year,
    areaParams?.curriculum,
    treeCheckedList,
  ]);

  useEffect(() => {
    switch (step) {
      case 4:
        // 每次進入版面配置頁面，皆要檢查是否有進行配分，若沒有 則進行自動配分。
        if (totalQuesTypeData?.score === 0) {
          setTableData(
            autoSettingScore(tableData, keyList, totalQuesTypeData?.totalAnswer)
          );
        }
        break;

      default:
        break;
    }
  }, [step]);

  return (
    <StyledEquationDojoPage
      className={className}
      data-testid="EquationDojoPage"
    >
      <ExamPaperHeader
        pageName="無限命題"
        globalExamPaperData={eduSubjectData}
        step={step}
      />
      {deviceData.isDesktop ? (
        <StepBox
          isEditPaper={editCopyExamPaperData?.actionType}
          steps={steps}
          current={step - 1}
          onChange={onStepClick}
          stepdisabled={step === 5 ? true : false}
          type={'EquationDojo'}
        >
          {step === 1 && Object.keys(tableData).length > 0 && !isEditCopy && (
            <div className="warningBar">
              <ExclamationCircleSolid />
              若變更選擇的範圍，則已選題型與試題將會被清空，請小心操作！
            </div>
          )}
          {
            (step === 2 && Object.values(tableData).some(item => item?.goThrough === 'step3')) && !isEditCopy &&
            <div className='warningBar'><ExclamationCircleSolid />若變更選擇的題型、題數或答數，則已選試題將會被清空，請小心操作！</div>
          }
          {
            step === 2 && isShowQuestionLimitText && (
              <QuestionLimitHint
                currentQuesNum={currentQuesNum}
                processType={processType}
                questionNumLimit={questionNumLimit}
              />
            )
          }
          {step === 2 && editCopyExamPaperData && editCopyExamPaperData?.error?.total > 0 && isSuspendedQuestionsBarOpen && (
            <SuspendedQuestionsBar
              currentTotal={totalQuesTypeData.questionCount}
              error={editCopyExamPaperData.error}
              onClose={() => setIsSuspendedQuestionsBarOpen(false)}
            />
          )}
          <StyledEquationDojoPageContent>
            {step === 1 && (
              <EquationDojoAreaSelectContent
                examPaperType="EquationDojo"
                resetToggle={resetToggle}
                memberEduSubject={globalExamPaperData}
                saveData={saveData}
                getSaveData={getSaveData}
                getSendAreaParams={getSendAreaParams}
                getKeyList={getKeyList}
                totalQuesTypeData={totalQuesTypeData}
                identity={identity}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isEditCopy={isEditCopy}
                isGoBack={isGoBack}
                setIsGoBack={setIsGoBack}
                setBookList={setBookList}
                treeCheckedList={treeCheckedList}
                setTreeCheckedList={setTreeCheckedList}
                globalExamPaperData={globalExamPaperData}
                reCreateExam={reCreateExam}
              />
            )}
            {step === 2 && JSON.stringify(tableData) !== '{}' && (
              <EquationDojoQuestionSelectContent
                examPaperInfo={data}
                tableData={tableData}
                setTableData={setTableData}
                // searchKey={searchKey}
                // searchParams={searchParams}
                examPaperTotalScoreData={totalQuesTypeData}
                prevKeyList={keyList}
                // memberEduSubject={eduSubjectData}
                // saveData={saveData}
                getSaveData={getSaveData}
                totalQuesTypeData={totalQuesTypeData}
                getQuestions={getQuestions}
                getKeyList={getKeyList}
                // globalExamPaperData={globalExamPaperData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isEditCopy={isEditCopy}
                reCreateSearchParams={reCreateSearchParams}
                editCopyExamPaperData={editCopyExamPaperData}
                // filterPaperUID={filterPaperUID}
                renderFilterQuestionModal={renderFilterQuestionModal}
                // filterQuestionModalToggle={filterQuestionModalToggle}
                // setFilterQuestionModalFalse={setFilterQuestionModalFalse}
                isChangeFilter={isChangeFilter}
                setIsChangeFilter={setIsChangeFilter}
                // filterQuestions={filterQuestions}
                // selectedBooks={selectedBooks}
              />
            )}

            {step === 3 && (
              <EquationDojoQuestionReviewContent
                // examPaperInfo={data}
                tableData={tableData}
                setTableData={setTableData}
                // searchKey={searchKey}
                // searchParams={searchParams}
                examPaperTotalScoreData={totalQuesTypeData}
                prevKeyList={keyList}
                questions={questions}
                // saveData={saveData}
                getSaveData={getSaveData}
                getKeyList={getKeyList}
                // totalQuesTypeData={totalQuesTypeData}
                memberEduSubject={eduSubjectData}
                eduSubjectData={eduSubjectData}
                // renderLoadingContent={renderLoadingContent}
                // isLoading={isLoading}
                // setIsLoading={setIsLoading}
              />
            )}
            {step === 4 && (
              <EquationDojoLayoutOptionContent
                // searchKey={searchKey}
                keyList={keyList}
                tableData={tableData}
                setTableData={setTableData}
                // examPaperTotalScoreData={totalQuesTypeData}
                saveData={saveData}
                globalExamPaperData={globalExamPaperData}
                eduSubjectData={eduSubjectData}
                getCreateExamPaperParams={getCreateExamPaperParams}
                getCheckedSend={getCheckedSend}
                getSaveData={getSaveData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                areaParams={areaParams}
                setOutSideSendParams={getSendParams}
                totalQuesTypeData={totalQuesTypeData}
                // searchParams={searchParams}
                isEditCopy={isEditCopy}
                editCopyExamPaperData={editCopyExamPaperData}
              />
            )}
            {step === 5 && (
              <HomeQuestionCompleteContent
                onlineExamUID={createExamPaperParams?.examUID}
                examUID={createExamPaperParams?.examUID}
                UID={submitResponseData?.exportId}
                outputType={createExamPaperParams?.outputType}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                sendParams={sendParams}
                setStep={setStep}
                setReCreateExam={setReCreateExam}
                examPaperTotalScoreData={totalQuesTypeData}
                eduSubjectData={eduSubjectData}
                setIsAgainDo={setIsAgainDo}
                step={step}
              />
            )}
          </StyledEquationDojoPageContent>

          <UiBtnZone>
            {step !== 1 && step !== 5 && (
              <Button
                disabled={prevStepDisabled}
                type={prevStepDisabled ? 'default' : 'solid'}
                textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                variant={prevStepDisabled ? 'ui02' : 'brand1'}
                size="large"
                isFull={false}
                onClick={() => {
                  prevStep();
                  setIsGoBack(true);
                }}
              >
                上一步
              </Button>
            )}
            {step !== 5 && (
              <Button
                disabled={disabled}
                variant={disabled ? 'ui02' : 'brand1'}
                size="large"
                isFull={false}
                onClick={() => {
                  nextStepClickHandler();
                  setIsGoBack(false);
                }}
              >
                {step !== 4 ? '下一步' : '輸出試卷'}
              </Button>
            )}
          </UiBtnZone>
        </StepBox>
      ) : (
        <MobileStepBox
          steps={steps}
          current={step - 1}
          onChange={onStepClick}
          stepdisabled={step === 5 ? true : false}
          type={'EquationDojo'}
        >
          {step === 1 && Object.keys(tableData).length > 0 && (
            <div className="warningBar">
              <ExclamationCircleSolid />
              若變更選擇的範圍，則已選題型與試題將會被清空，請小心操作！
            </div>
          )}

          {
            step === 2 && isShowQuestionLimitText && (
              <QuestionLimitHint
                currentQuesNum={currentQuesNum}
                processType={processType}
                questionNumLimit={questionNumLimit}
              />
            )
          }

          <StyledEquationDojoPageContent>
            {step === 1 && (
              <EquationDojoAreaSelectContent
                examPaperType="EquationDojo"
                resetToggle={resetToggle}
                memberEduSubject={globalExamPaperData}
                saveData={saveData}
                getSaveData={getSaveData}
                getSendAreaParams={getSendAreaParams}
                getKeyList={getKeyList}
                totalQuesTypeData={totalQuesTypeData}
                identity={identity}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isEditCopy={isEditCopy}
                isGoBack={isGoBack}
                setIsGoBack={setIsGoBack}
                setBookList={setBookList}
                treeCheckedList={treeCheckedList}
                setTreeCheckedList={setTreeCheckedList}
                globalExamPaperData={globalExamPaperData}
                reCreateExam={reCreateExam}
              />
            )}
            {step === 2 && JSON.stringify(tableData) !== '{}' && (
              <EquationDojoQuestionSelectContent
                examPaperInfo={data}
                tableData={tableData}
                setTableData={setTableData}
                searchKey={searchKey}
                searchParams={searchParams}
                examPaperTotalScoreData={totalQuesTypeData}
                prevKeyList={keyList}
                memberEduSubject={eduSubjectData}
                saveData={saveData}
                getSaveData={getSaveData}
                totalQuesTypeData={totalQuesTypeData}
                getQuestions={getQuestions}
                getKeyList={getKeyList}
                globalExamPaperData={globalExamPaperData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isEditCopy={isEditCopy}
                reCreateSearchParams={reCreateSearchParams}
                editCopyExamPaperData={editCopyExamPaperData}
                filterPaperUID={filterPaperUID}
                renderFilterQuestionModal={renderFilterQuestionModal}
                filterQuestionModalToggle={filterQuestionModalToggle}
                setFilterQuestionModalFalse={setFilterQuestionModalFalse}
                isChangeFilter={isChangeFilter}
                setIsChangeFilter={setIsChangeFilter}
                filterQuestions={filterQuestions}
                selectedBooks={selectedBooks}
              />
            )}
            {step === 3 && (
              <EquationDojoQuestionReviewContent
                examPaperInfo={data}
                tableData={tableData}
                setTableData={setTableData}
                searchKey={searchKey}
                searchParams={searchParams}
                examPaperTotalScoreData={totalQuesTypeData}
                prevKeyList={keyList}
                questions={questions}
                saveData={saveData}
                getSaveData={getSaveData}
                getKeyList={getKeyList}
                totalQuesTypeData={totalQuesTypeData}
                memberEduSubject={eduSubjectData}
                eduSubjectData={eduSubjectData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
            {step === 4 && (
              <EquationDojoLayoutOptionContent
                searchKey={searchKey}
                keyList={keyList}
                tableData={tableData}
                setTableData={setTableData}
                examPaperTotalScoreData={totalQuesTypeData}
                saveData={saveData}
                globalExamPaperData={globalExamPaperData}
                eduSubjectData={eduSubjectData}
                getCreateExamPaperParams={getCreateExamPaperParams}
                getCheckedSend={getCheckedSend}
                getSaveData={getSaveData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                areaParams={areaParams}
                setOutSideSendParams={getSendParams}
                totalQuesTypeData={totalQuesTypeData}
                searchParams={searchParams}
                isEditCopy={isEditCopy}
                editCopyExamPaperData={editCopyExamPaperData}
              />
            )}
            {step === 5 && (
              <HomeQuestionCompleteContent
                onlineExamUID={createExamPaperParams?.examUID}
                examUID={createExamPaperParams?.examUID}
                UID={submitResponseData?.exportId}
                outputType={createExamPaperParams?.outputType}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                sendParams={sendParams}
                setStep={setStep}
                setReCreateExam={setReCreateExam}
                examPaperTotalScoreData={totalQuesTypeData}
                eduSubjectData={eduSubjectData}
                setIsAgainDo={setIsAgainDo}
                step={step}
              />
            )}
          </StyledEquationDojoPageContent>

          <UiBtnZone>
            {step !== 1 && step !== 5 && (
              <Button
                className={'prevStepBtn'}
                disabled={prevStepDisabled}
                type={prevStepDisabled ? 'default' : 'solid'}
                textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                variant={prevStepDisabled ? 'ui02' : 'brand1'}
                size="large"
                isFull={false}
                onClick={() => {
                  prevStep();
                  setIsGoBack(true);
                }}
              >
                上一步
              </Button>
            )}
            {step !== 5 && (
              <Button
                disabled={disabled}
                variant={disabled ? 'ui02' : 'brand1'}
                size="large"
                isFull={false}
                onClick={() => {
                  nextStepClickHandler();
                  setIsGoBack(false);
                }}
              >
                {step !== 4 ? '下一步' : '輸出試卷'}
              </Button>
            )}
          </UiBtnZone>
        </MobileStepBox>
      )}
      <Modal
        visible={areaModalVisible}
        onCancel={setAreaModalFalse}
        className={'mobileModal'}
        title={<div>確定要變更範圍？</div>}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              variant="ui01"
              onClick={() => {
                getSendAreaParams(saveData.areaParams);
                setAreaModalFalse();
                setResetToggle(!resetToggle);
              }}
            >
              取消變更
            </Button>
            <Button
              variant={'brand1'}
              onClick={() => {
                nextStep();
                setAreaModalFalse();
                setIsGoBack(false);
              }}
            >
              確定變更
            </Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>變更後，原已選題型與試題將會被清空，需要重新操作後續步驟。</div>
          <div>確定變更請按「確定變更」</div>
          <div>使用原範圍請按「取消變更」</div>
        </div>
      </Modal>

      <Modal
        visible={emptyTextModalVisible}
        onCancel={setEmptyTextModalFalse}
        className={'mobileModal'}
        id={'emptyTextModal'}
        title={<div>該範圍無可用試題</div>}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant={'brand1'} onClick={setEmptyTextModalFalse}>
              確認
            </Button>
          </div>
        }
      >
        <div>該範圍試題還在陸續更新中，請重新選擇範圍！</div>
      </Modal>

      <QuestionLimitModal
        visible={questionCountModalVisible}
        onCancel={setQuestionCountModalFalse}
        currentQuesNum={currentQuesNum}
        processType={processType}
        questionNumLimit={questionNumLimit}
      />
    </StyledEquationDojoPage>
  );
};

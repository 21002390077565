/* eslint-disable react/prop-types */
import { ExclamationCircleSolid, XmarkOutline } from '@onedesign/icon';
import { StyledSuspendedQuestionsBar } from './SuspendedQuestionsBar.style';

export const SuspendedQuestionsBar = ({ className, currentTotal, error, onClose }) => {
  const { missingKnowledges, total, undefinedCount } = error;
  const originalTotal = currentTotal + total;

  return (
    <StyledSuspendedQuestionsBar className={className} data-testid="SuspendedQuestionsBar">
      <ExclamationCircleSolid />
      <div className="banner__text">
        <div>
        原試卷已選 {originalTotal} 題試題，其中 {total} 題已被停用，將不會再顯示於試卷中：
        </div>
        {missingKnowledges.map((knowledge) => (
          <div key={knowledge.name}>
            ．知識向度「{knowledge.name}」{knowledge.count} 題
          </div>
        ))}
        {undefinedCount > 0 && <div>．已停用的知識向度 {undefinedCount} 題</div>}
      </div>
      <div className="banner__close" onClick={onClose}>
        <XmarkOutline />
      </div>
    </StyledSuspendedQuestionsBar>
  );
};
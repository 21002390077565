import { Modal } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { ButtonBlock, TextBlock } from './LogoutAler.style';

export default function LogoutAlert({
  show,
  onLeave,
  onStay
}) {
  return (
    <Modal visible={show}
      onCancel={onStay}
      title={
        <div>確定要登出嗎？</div>
      }
      footer={
        <ButtonBlock>
          <Button className='leave' onClick={onStay}>取消</Button>
          <Button className='think' onClick={onLeave}>確定登出</Button>
        </ButtonBlock>
      }>
      <TextBlock>
        登出後不會儲存任何正在編輯中的資料
      </TextBlock>
    </Modal>
  );
}

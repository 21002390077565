import styled from 'styled-components';

export const StyledActionIcon = styled.div`
>div{
    width: 20px;
    height:20px;
}
    svg {
        cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} ;
        font-size: 24px;
        color:${({ theme, disabled }) => disabled ? theme.colors.ui.ui_03 : theme.colors.ui.ui_07};
        margin-right: 0 !important;
        margin: 0 4px !important;
        &:not(:last-child){
            margin-right: 19.5px;
        }
    }
`;

export const StyleReportModalContent = styled.div`
    >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div:nth-child(1){
            width:40px;
        }
    }
    >div:nth-child(1){
        margin-bottom:10px;
    }
    >div:nth-child(2){
        align-items: flex-start;
    }
    .select{
        width: 130px;
    }
    .textArea{
        width: 90%;
    }
`;

export const StyledHomeQuestionReviewPage = styled.div.attrs(props => ({
  className: props.className
}))`
padding: 32px 16px;
width: 100%;

.dndItem{
    padding: 0px 15px !important;
    /* border-bottom: 1px solid #f0f0f0;
    >div{
        border: 0px !important;
    } */
}

.iconBtn{
    font-size: 20px;
    cursor: pointer;
}

.tooltipText{
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        color: ${({ theme }) => theme.colors.support.warning};
        font-size: 16px;
    }
}

.pageTitle{
    font-size: 18px;
    padding-left: 16px;
    font-weight:500;
}
.pageTitleBox{
        display:flex;
        justify-content:space-between;
        align-items:center;
}
.tableFooter{
    display: flex;
    justify-content:flex-end;
}
.radioSwitch{
    .ant-radio-group-solid .ant-radio-button-wrapper{
        border-radius: 0;
        padding: 4px 12px;
        width: 102px;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper:not(:first-child){
        margin-left: 0;
        border-left-width: 0px;
        &:hover{
            border-left-width: 1px;
        }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper:first-child{
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper:last-child{
        margin-left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.questionTableBody, .tableBody{
        min-height: auto;
        max-height: none;
        .questionImage {
            width: 100%;
            height: auto;
            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
        .emptyMsg{
            display: flex;
            justify-content: center;
            margin-top:16px;
        }
        .questionImage {
            width: 100%;
            height: auto;
            @media (max-width: 1024px) {
            max-width: 100%
            }
        }
        .questionItem {
          position: relative;
          .triangleZone {
            display: none;
          }
          .questionContent{
              overflow: hidden;
              width: 94%;
              margin: auto;
              .questionContentText{
                  overflow-wrap: anywhere;
              }
              .contentDetail{
                  position: absolute;
                  cursor: pointer;
                  bottom: 12px;
                  right:0;
                  padding: 4px;
                  border-radius: 4px;
                  opacity: 0.5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: ${({ theme }) => theme.colors.ui.ui_07};
                  color:#fff;
                  font-size: 24px;
                  svg{
                      margin-right: 0;
                  }
              }
          }
        }
        .metaBlock{
                display: flex;
                flex-direction: column;
                >div{
                    margin:4px 0;
                }
                .meta{
                    color:${({ theme }) => theme.colors.text.text_05};
                    .metaContent{
                        color:${({ theme }) => theme.colors.text.text_07};
                    }
                }
            }
    }
    .questionImageModal{
        position: fixed;
        top:0;
        left:0;
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: rgba(0, 0, 0, 0.6);
        svg{
                    margin-right: 0;
                }
        .questionImageModalContent{
            width: 60%;
            background: ${({ theme }) => theme.colors.ui.white};
            opacity: 1;
            position: relative;
            top: 10%;
            border-radius: 4px;
            img{
                width: 100%;
            }
            .questionImageModalClose{
                position: absolute;
                top: 0;
                right: -54px;
                padding:4px;
                border: 1px solid ${({ theme }) => theme.colors.ui.white};
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                color:${({ theme }) => theme.colors.text.white};
            }
            .questionImageModalContentImage{
                overflow-y: auto;
                max-height:80vh
            }
        }
    }
    @media (max-width: 1024px) {
        padding: 10px 0px;
        .pageTitleBox{
            /* display: none; */
            margin: 24px 0 12px 0;
        }
        .tableFooter{
            display: none;
        }
        .dndItem{
            padding: 0 0 !important;
        }
        .tableHeader{
            padding: 0 16px;
            >div:nth-child(1){
                padding: 6px 10px;
            }
        }
        .tableBody{
            position: relative;
            padding-bottom: 32px;
            >div{
                padding: 0 16px;
                >div{
                    >div{
                        padding: 6px 10px !important;
                    }
                }
            }
        }
        .blackDiv{
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 8px;
            background-color: #E5E5E5;
        }
        .pageTitle{
            padding: 0 24px;
        }
        .radioSwitch{
            padding: 0 10px 0 24px;
            >div{
                .ant-radio-button-wrapper{
                    margin: 0 8px 8px 0 !important;
                    border-radius: 4px !important;
                    border-left: 1px solid #d9d9d9 !important;
                }
            }
        }
        .questionTableHeader{
            display: none;
        }
        .questionTableBody{
            padding: 0 16px;
            >div{
                margin-bottom: 8px;
                border: 1px solid #F2F4F8;
                border-radius: 8px;
                overflow: hidden;

                >div{
                    min-width: 30px;
                    padding: 12px 5px;
                    border-bottom: 0px !important;
                }
            }
            .dndItem{
                >div:first-child{
                    border-bottom: 0px !important;
                }
            }
        }
        .dndItem{
            svg{
                font-size: 16px;
            }
        }
        .quesTypeTitle{
            margin-bottom: 8px;
            padding: 0px 16px;
            >div{
                background-color: ${({ theme }) => theme.colors.ui.ui_01};
                padding: 8px 16px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
        .triangleZone{
            display: none;
        }
        /// 
        /// 單一題目詳情頁面
        .ant-modal-content{
			width: 100vw;
			height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
            height: calc(var(--vh, 1vh) * 100);
		}
        #questionDetailDrawer{
            .ant-modal-close{
                left: 0 !important;
                right: unset !important;
                color: #fff;
            }
            .ant-modal-close-x{
                transform: rotate(180deg);
            }
            .ant-modal-content{
                width: 100vw;
                height: calc(var(--vh, 1vh) * 100);
                border-radius: 0px;
                background-color: #E5E5E5;
                .ant-modal-body{
                    padding: 0 0 0 0;
                }
            }
        }
        .isHidden .ant-modal-mask,.isHidden .ant-modal-wrap{
            z-index: 900 !important;
        }
        .detailImgBox{
            position: relative;
            width: 100%;
            height: 516px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            .backBtnColorDiv{
                position: absolute;
                top: 0px;
                width: 100vw;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
                height: 63px;
            }
            >img{
                width: 100%;
                max-height: 516px;
            }
            .preQuesBtn,.nextQuesBtn{
                position: absolute;
                bottom:20px;
                height: 44px;
                width: 44px;
                border-radius: 50%;
                overflow: hidden;
                background: rgba(69, 75, 92, 0.5);
                color:#fff;
                display: flex;
                align-items: center;
                justify-content: center;
                >svg{
                    margin: 0 0;
                    font-size: 20px;
                }
            }
            .preQuesBtn{
                left: 8px;
            }
            .nextQuesBtn{
                right:8px;
            }
        }
        .detailControlBox{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 16px;
            background-color: #fff;
            margin-bottom: 8px;
            >div:nth-child(1){
                display: flex;
                align-items: center;
                >div{
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                    >svg{
                        font-size: 24px;
                    }
                }
                >div:nth-child(1){
                    margin-right: 30px;
                    svg{
                        color: ${({ theme }) => theme.colors.ui.ui_04};
                    }
                }
                >div:nth-child(2){
                    svg{
                        color: ${({ theme }) => theme.colors.ui.ui_05};
                    }
                }
            }
            >div:nth-child(2){
                padding: 8px 20px;
                border-radius: 8px;
                border: 1px solid #D5D7DE;
                >label{
                    margin-right: 8px;
                }
            }
            .checked{
                border: 1px solid ${({ theme }) => theme.colors.brand.brand02} !important;
                color: #fff;
                background-color: ${({ theme }) => theme.colors.brand.brand02};
                .ant-checkbox-inner{
                    background-color: #fff !important;
                    border-color: #fff !important;
                }
                .ant-checkbox-inner:after{
                    border-color:${({ theme }) => theme.colors.brand.brand02} !important;
                }
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
                border:1px solid #E4E7EC;
            }
        }
        .detailContent{
            background-color: #fff;
            padding: 16px 24px;
            >div{
                padding: 0 5px;
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                color: ${(props) => props.theme.colors.text.text_07};
                >div:nth-child(1){
                    width: 20%;
                    text-align: right;
                    width: 60px;
                    margin-right: 20px;
                    color: ${(props) => props.theme.colors.text.text_06};
                }
                >div:nth-child(2){
                    width: 80%;
                }
            }
            >div:nth-child(1){
                padding: 0 0;
                margin-bottom: 12px;
                >div:nth-child(1){
                    text-align: left;
                    width: auto;
                    font-size: 16px;
                    color: ${(props) => props.theme.colors.text.text_10};
                }
                >div:nth-child(2){
                    width: auto;
                    >div{
                        padding: 2px 8px;
                    }
                }
            }
        }
        
        .reportDrawer{
            .ant-drawer-content-wrapper{
                height: auto !important;
                max-height:calc(var(--vh, 1vh) * 100);
                overflow: auto !important;
            }
            .ant-drawer-footer{
                >div:nth-child(2){
                    margin-top: 12px;
                }
            }
            .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
                border-color: ${({ theme }) => theme.colors.brand.brand01};
            }
            .ant-radio-inner::after{
                background-color: ${({ theme }) => theme.colors.brand.brand01};
            }
            .ant-radio-checked .ant-radio-inner{
                border-color: ${({ theme }) => theme.colors.brand.brand01};
            }
            .radioBox{
                margin-bottom: 20px;
                >div:nth-child(1){
                    margin-bottom: 15px;
                    span{
                        color: red;
                    }
                }
                >div:nth-child(2){
                    padding: 0 5px;
                    display: flex;
                    flex-direction: column;
                    >label{
                        margin-bottom: 8px !important;
                    }
                }
            }
            .explanationBox{
                >div:nth-child(1){
                    margin-bottom: 15px;
                    span{
                        color: red;
                    }
                } 
                .textArea{
                    >textarea{
                        min-height: 130px !important;
                        max-height: 200px !important;
                    }
                }
            }
        }
        .setupDrawer{
            // 配題與配分
            .ant-drawer-content-wrapper{
                height: unset !important;
            }
            .ant-drawer-content{
                overflow: hidden;
                border-radius:12px 12px 0 0;
                height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
                height: calc(var(--vh, 1vh) * 100);
            }
            .ant-drawer-body{
                padding: 16px 24px 16px !important;
                height: calc(calc(var(--vh, 1vh) * 100) - 54px - 84px) !important;
                overflow-y: auto;
            }
            .setupDrawerTotalScore{
                color: ${({ theme }) => theme.colors.brand.brand03};
                padding: 0 0 8px 0;
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
                margin-bottom: 24px;
                >span{
                    display: block;
                }
                >span:nth-child(1){
                    margin-bottom: 4px;
                }
            }
            .questionTypeBox{
                margin-bottom: 24px;
            }
            .drawerTitle{
                font-size:16px;
                color:${({ theme }) => theme.colors.text.text_07};
                margin-bottom:24px;
            }
            .drawerSection{
                font-size:14px;
                color:${({ theme }) => theme.colors.text.text_07};
                margin-bottom:8px;
            }
            .sectionSubTitle{
                margin-bottom: 8px;
                font-size:14px;
                color:${({ theme }) => theme.colors.text.text_06};                        
            }
            .drawerTable{
                border-bottom: 1px solid #E4E7EC;
                >div{
                    display: flex;
                    >div{
                        width: 50%;
                        padding: 8px 16px;
                        >span{
                            color:${({ theme }) => theme.colors.text.text_06};                        
                        }
                    }
                }
                >div:nth-child(1){
                    background-color:${({ theme }) => theme.colors.ui.ui_01};
                }
                >div:nth-child(2){
                    display: flex;
                    >div{
                        align-items:flex-end;
                        display: flex;
                        >div{
                            width: 50%;
                            margin-right: 4px;
                        }
                    }
                }
            }
            .drawerTotalScore{
                display: flex;
                justify-content:flex-end;
                color: ${({ theme }) => theme.colors.brand.brand03};
                padding:8px 16px;
                width: 100%;
                border-bottom: 1px solid #E4E7EC;
            }
            .setupDrawerScoreBox{
                .ant-input{
                    width: 50%;
                }
                input{
                    text-align: right;
                }
            }
            .chartPieSolidIcon{
                position: absolute;
                top: 16px;
                right: 20px;
            }
            .tipText{
                color:${({ theme }) => theme.colors.text.text_05};                        
            }
            .ant-drawer-close{
                right: unset !important;
                left: 0px !important;
            }
        }
    }
`;
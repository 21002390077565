import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useSheet from 'utils/hooks/useSheet';
import { UserProviderContext } from './UserProvider';

export const hideOrdinalNumberChaptersContext = createContext(() => { });

export const HideOrdinalNumberChaptersProvider = ({ children }) => {
  const { userData } = useContext(UserProviderContext);
  const { getSheet } = useSheet();
  const [chaptersMap, setChaptersMap] = useState({});

  useEffect(() => {
    if (!userData) return;
    getSheet(
      process.env.REACT_APP_NO_ORDINAL_NUMBER_CHAPTERS_SHEET_ID,
      '工作表1')
      .then(({ data }) => setChaptersMap(Object.fromEntries(data.values.slice(1))))
      .catch(console.error);
  }, [userData]);

  const value = useMemo(() => ({ chaptersMap }), [chaptersMap]);

  return (
    <hideOrdinalNumberChaptersContext.Provider value={value}>
      {children}
    </hideOrdinalNumberChaptersContext.Provider>
  );
};

export const useHideOrdinalNumberChaptersContext = () => useContext(hideOrdinalNumberChaptersContext);

HideOrdinalNumberChaptersProvider.propTypes = {
  children: PropTypes.node,
};

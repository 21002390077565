/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { getExportPaperSetting } from 'api/exportPaper';
import { postMemberTypeSetting } from 'api/member';
import { useMachine } from '@xstate/react';
import { Box, Table, setRadioOptions, Input, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, openNotificationWithIcon } from '@oneclass/ui-components';
import { ExamPaperSetting } from 'components';
import { arrayUnique } from 'utils/common';
import { StyledMpLayoutOptionContent } from './MpLayoutOptionContent.style';
import { useQuestionAnalysisModal } from 'utils/hooks/useQuestionAnalysisModal';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import QuestionTypeName from 'components/QuestionTypeName';
import LayoutSettingPaper from 'components/LayoutSettingPaper';
import EnglishListenSettings from 'components/EnglishListenSettings';
import { LISTEN_DEFAULT_SETTINGS } from 'constants/index';
import { EditCopyExamPaperProvider } from '@oneclass/ui-components';

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
  difficulty: {
    BEGIN: '',
    INTERMEDIATE: '',
    EXPERT: '',
  }
};

export const MpLayoutOptionContent = ({
  className,
  searchKey,
  keyList,
  // examPaperData,
  examPaperTotalScoreData,
  saveData,
  getCreateExamPaperParams,
  getSaveData,
  globalExamPaperData,
  getCheckedSend,
  // getTableData,
  organizationType,
  eduSubjectData,
  areaParams,
  setOutSideSendParams,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  // getTotalQuesTypeData,
  searchParams,
  isEditCopy,
  editCopyExamPaperData,
  tableData,
  setTableData,
  totalQuesTypeData,
}) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  // const [tableData, setTableData] = useState(examPaperData);
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { editCopyExamPaperData: _editCopyExamPaperData } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const [sendParams, setSendParams] = useState({
    paperName: {
      value: '',
      message: '',
    },
    // examType: '',
    outputType: globalExamPaperData?.output,
    amount: 2,
    engAudioSetting: LISTEN_DEFAULT_SETTINGS
  });
  const [checkedSend, setCheckedSend] = useState(['paperName']);

  //導入 試題分析 Hook
  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.entries(tableData).forEach(([key, value]) => {
      if (value?.pickUpQuestions) {
        Object.entries(value.pickUpQuestions).forEach(([quesKey, quesValue]) => {
          quesValue.forEach(item => {
            nextData = [...nextData, item.uid];
          });
        });
      }
    });
    return nextData;
  }, [tableData]);

  const hasListen = useMemo(() => {
    return Object.values(tableData)
      .filter((quesType) => quesType?.pickUpQuestions)
      .some((quesType) => quesType.isListen);
  }, [tableData]);

  const { renderQuestionAnalysisModal, doShowAnalysisModal } = useQuestionAnalysisModal(pickUpQuestionID, searchParams, isEditCopy);

  const rowData = ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'];

  const [stateExportPaperRelated, sendExportPaperRelated] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (ctx, evt) => {
        setIsLoading(true);
        const res = await getExportPaperSetting(evt?.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (!isSuccess) openNotificationWithIcon('error', message);
        const { settings } = data;

        const languageSubject = ['CH', 'PC', 'CN'];

        if (languageSubject.indexOf(eduSubjectData.subject) === -1) {
          settings.Files.wordSettingMap = settings?.Files?.wordSettingMap.filter((item) => { return (item?.code !== 'HSS' && item?.code !== 'HSD'); });
        }

        const nowTypesetting = isEditCopy
          ? editCopyExamPaperData?.typeSetting?.ordering
            ? editCopyExamPaperData?.typeSetting
            : { ...editCopyExamPaperData?.typeSetting, ordering: rowData }
          : settings?.Files?.typesetting?.ordering
            ? settings?.Files?.typesetting
            : { ...settings?.Files?.typesetting, ordering: rowData };

        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
          paperSizeMap: setRadioOptions(settings?.Files?.paperSizeMap) || [],
          wordSettingMap: setRadioOptions(settings?.Files?.wordSettingMap) || [],
          paperContent: settings?.Files?.paperContent,
          typesetting: nowTypesetting,
          advancedSetting: settings?.Files?.advancedSetting,
          analyzeContent: settings?.Files?.analyzeContent,
          onlineAdvanced: settings?.Online?.advanced,
          onlineAdvancedSetting: settings?.Online?.advancedSetting,
        };
      }
    }
  });
  const {
    paperSizeMap,
    wordSettingMap,
    paperContent,
    advancedSetting,
    typesetting,
    analyzeContent,
    onlineAdvanced,
    onlineAdvancedSetting,
  } = stateExportPaperRelated.context.result || {};


  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', '儲存成功');
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const scoreInputChange = (quesType, inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e.target.value;
    let allScore = 0;
    if (inputKey === 'perQues') {
      allScore = tableData[quesType].inputValue.question ? (value * tableData[quesType].inputValue.question).toFixed(1) : 0;
    } else {
      allScore = tableData[quesType].inputValue.answer ? (value * tableData[quesType].inputValue.answer).toFixed(1) : 0;
    }
    let nextScore = {
      ...defaultCountInputValue.score,
      [inputKey]: value,
      allScore
    };
    let nextData = {};
    nextData = {
      [quesType]: {
        ...tableData[quesType],
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          }
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  const onSaveExamPaperInfo = () => {
    let nextData = {};
    if (sendParams?.outputType !== 'Files') {
      nextData = {
        paperName: sendParams.paperName.value
      };
      sendUserSetting(FETCH_DATA_EVENT.FETCH, {
        payload: nextData
      });
    } else {
      const {
        schoolName = {},
        paperName,
        teacherSign = {},
        grade = {},
        room = {},
        paperSize,
        wordSetting,
        paperContents,
        analyzeContent,
        amount,
        advanced,
        studentSign,
        eduSubject,
        ordering,
        header,
      } = sendParams || {};
      nextData = {
        schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
        paperName: paperName.value,
        teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
        grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
        room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
        eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
        studentSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? studentSign : '',
        paperSize,
        wordSetting,
        paperContents: paperContents || [],
        analyzeContent: analyzeContent || [],
        amount,
        advanced: advanced || [],
        ordering,
        header,
      };

      sendUserSetting(FETCH_DATA_EVENT.FETCH, {
        payload: nextData
      });
    }
  };



  const getSendParams = (data) => {
    setSendParams({ ...sendParams, ...data });
  };

  const changeCheckedSend = (data) => {
    setCheckedSend(data);
  };

  useEffect(() => {
    if (!saveData?.createExamPaperParams.examUID) {
      if (saveData?.actionType) {
        sendExportPaperRelated(FETCH_DATA_EVENT.FETCH, {
          payload: {
            examUID: saveData.rootExamUID
          }
        });
      } else {
        sendExportPaperRelated(FETCH_DATA_EVENT.FETCH);
      }
    } else {
      sendExportPaperRelated(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID: saveData.rootExamUID
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   getTableData(tableData);
  // }, [tableData]);

  useEffect(() => {
    getCheckedSend(checkedSend);
  }, [checkedSend]);

  useEffect(() => {
    getCreateExamPaperParams(sendParams);
    getSaveData('createExamPaperParams', sendParams);
  }, [sendParams]);

  useEffect(() => {
    if (saveData?.createExamPaperParams) {
      setSendParams({ ...saveData?.createExamPaperParams });
    }
  }, []);

  useEffect(() => {
    if (!stateExportPaperRelated.matches(FETCH_DATA_STATE.DONE)) return;
    if (!typesetting) return;
    const {
      room = '',
      grade = '',
    } = typesetting || {};
    let nextCheckSend = [];
    rowData.forEach(item => {
      if ((item === 'classInfo') && room && grade) {
        nextCheckSend = [...nextCheckSend, item];
      } else if (item === 'paperName') {
        nextCheckSend = [...nextCheckSend, item];
      } else {
        if (typesetting[item]) {
          nextCheckSend = [...nextCheckSend, item];
        }
      }
    });
    setCheckedSend(nextCheckSend);
  }, [typesetting, stateExportPaperRelated.value]);

  const paperDefaultName = useMemo(() => {
    if (!areaParams || !eduSubjectData) return '';
    return `${areaParams.year || _editCopyExamPaperData.searchCondition.year}學年度${eduSubjectData.eduSubjectName.edu + eduSubjectData.eduSubjectName.subject}測驗`;
  }, [areaParams, eduSubjectData]);

  useEffect(() => {
    const {
      paperSize,
      // wordSetting,
      paperContents,
      // paperName = '',
      amount = 1,
      advanced = [],
      schoolName = '',
      room = '',
      grade = '',
      teacherSign = '',
      analyzeContent = [],
      ordering = [],
      header = '',
    } = typesetting || {};

    setSendParams({
      ...sendParams,
      // examUID: data.examUID,
      paperSize: paperSize || (paperSizeMap && paperSizeMap[0].value),
      wordSetting: wordSetting || (wordSettingMap && wordSettingMap[0].value),
      paperContents: paperContents?.length > 0 ? paperContents : paperContent && paperContent.filter(item => item.code === 'Question' || item.code === 'Analyze').map(item => item.code),
      paperName: {
        value: paperDefaultName, // newPaperName,
        message: '',
      },
      schoolName: {
        value: schoolName,
        message: '',
      },
      grade: {
        value: grade,
        message: '',
      },
      room: {
        value: room,
        message: '',
      },
      teacherSign: {
        value: teacherSign,
        message: '',
      },
      outputType: sendParams?.outputType || 'Files',
      // examType: sendParams?.examType || 'General',
      amount,
      advanced,
      analyzeContent: arrayUnique(['Question', 'Answer', 'Analyze'].concat(analyzeContent)),
      studentSign: '座號：＿＿＿  姓名：＿＿＿＿＿＿',
      eduSubject: `${globalExamPaperData?.eduSubjectName.edu}${globalExamPaperData?.eduSubjectName.subject}`,
      onlineAdvanced,
      ordering,
      header,
    });
  }, [typesetting, globalExamPaperData, onlineAdvanced]);

  useEffect(() => {
    setOutSideSendParams(sendParams);
  }, [sendParams]);

  // 依照學制科目 預先帶入對應的排版方式
  const wordSetting = useMemo(() => {
    if (!eduSubjectData) return;
    const edu = eduSubjectData.edu;
    const subject = eduSubjectData.subject;
    switch (edu) {
      case 'E':
        if (subject === 'CH') {
          return 'HSD';
        } else {
          return 'VHD';
        }
      case 'J':
        if (subject === 'PC') {
          return 'HSD';
        } else {
          return 'VHS';
        }
      case 'H':
        return 'HHD';
      default:
        return '';
    }
  }, [eduSubjectData]);

  return (
    isLoading ? renderLoadingContent()
      :
      <>
        <LayoutSettingPaper title="紙本設定" hasListen={hasListen}>
          <StyledMpLayoutOptionContent className={className} data-testid="MpLayoutOptionContent">
            <Box mb={5} className='pageTitleBox'>
              <div className="pageTitle">題型檢視與配分</div>
              {renderQuestionAnalysisModal()}
            </Box>
            <Box mb={deviceData.isDesktop ? 11 : 10} pl={deviceData.isDesktop ? 2 : 0}>
              {
                deviceData.isDesktop ?
                  <>
                    <Table className={hasListen ? 'tableBorder' : ''}>
                      <Table.Header>
                        <Table.Row textcenter={true}>
                          <Table.Item >順序</Table.Item>
                          <Table.Item flex={10}>題型</Table.Item>
                          <Table.Item flex={1}>題數</Table.Item>
                          <Table.Item flex={1}>答數</Table.Item>
                          {/* <Table.Item flex={1.5}>每題分數</Table.Item> */}
                          <Table.Item flex={1.5}>每答分數</Table.Item>
                          <Table.Item flex={2}>小計</Table.Item>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className="tableBody">
                        {
                          keyList && keyList.map((key, index) => (
                            <Table.Row key={key} textcenter={true} >
                              <Table.Item textalign={'center'}>{index + 1}</Table.Item>
                              <Table.Item flex={10} textalign={'flex-start'}>
                                <QuestionTypeName name={tableData[key].name} isListen={tableData[key].isListen} />
                              </Table.Item>
                              <Table.Item flex={1} textalign={'flex-end'}>{tableData[key].inputValue.question}</Table.Item>
                              <Table.Item flex={1} textalign={'flex-end'}>{tableData[key].inputValue.answer}</Table.Item>
                              {/* <Table.Item flex={1.5} textalign={'flex-end'}>
                                <div>
                                  <Input allowClear={false} value={tableData[key].inputValue.score.perQues || ''} onChange={(e) => {scoreInputChange(key, 'perQues', e);}} />
                                </div>
                              </Table.Item> */}
                              <Table.Item flex={1.5} textalign={'flex-end'}>
                                <div>
                                  <Input allowClear={false} value={tableData[key]?.inputValue.score.perAns || ''} onChange={(e) => { scoreInputChange(key, 'perAns', e); }} />
                                </div>
                              </Table.Item>
                              <Table.Item flex={2} textalign={'flex-end'}>{tableData[key].inputValue.score.allScore}</Table.Item>
                            </Table.Row>
                          ))
                        }
                        <Table.Row>
                          <Table.Item className='tableFooter'>
                            總題數 {totalQuesTypeData.questionCount} 題，總答數 {totalQuesTypeData?.totalAnswer} 答，總分 {totalQuesTypeData.score} 分
                          </Table.Item>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </>
                  :
                  <div className='setupContent'>
                    {
                      keyList &&
                      keyList.map((key) => {
                        return (
                          <div className='questionTypeBox' key={key}>
                            <div className={'drawerTitle'}>
                              {tableData[key]?.name}
                            </div>
                            <div className={'drawerSection'}>
                              <div className={'drawerTable'}>
                                <div>
                                  <div>已選題數</div>
                                  <div>已答題數</div>
                                </div>
                                <div>
                                  <div>
                                    {tableData[key].inputValue.question}
                                  </div>
                                  <div>
                                    {tableData[key].inputValue.answer}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={'drawerSection'}>
                              <div className={'drawerTable'}>
                                <div>
                                  {/* <div>每題分數</div> */}
                                  <div>每答分數</div>
                                </div>
                                <div className={'setupDrawerScoreBox'}>
                                  {/* <Input allowClear={false} value={tableData[key].inputValue.score.perQues} onChange={(e) => {scoreInputChange(key, 'perQues', e);}}/> */}
                                  <Input allowClear={false} value={tableData[key].inputValue.score.perAns} onChange={(e) => { scoreInputChange(key, 'perAns', e); }} />
                                </div>
                              </div>
                            </div>
                            <div className={'drawerTotalScore'}>
                              小計 {tableData[key].inputValue.score.allScore} 分
                            </div>
                          </div>
                        );
                      })
                    }
                    <div className='setupDrawerTotalScore'>
                      總題數 {totalQuesTypeData.questionCount} 題，總答數 {totalQuesTypeData?.totalAnswer} 答，總分 {totalQuesTypeData.score} 分
                    </div>
                  </div>
              }
            </Box>
            {!deviceData.isDesktop && <div className='blackDiv'></div>}
            {/* <Box mb={5}>
              <div className="pageTitle">試卷類別</div>
            </Box>
            <Box mb={deviceData.isDesktop ? 11 : 10} pl={deviceData.isDesktop ? 2 : 0}>
              <div>
                <RadioGroup buttonStyle='solid' optionType='button' value={sendParams.examType} onChange={(e) => { onExamPaperInfoChange(e, 'examType'); }} >
                  {
                    examPaperTypeOptions && examPaperTypeOptions.map(item => (
                      <Radio.Button value={item.value}>{item.label}</Radio.Button>
                    ))
                  }
                </RadioGroup>
              </div>
            </Box>
            {!deviceData.isDesktop && <div className='blackDiv'></div>}
            <Box mb={5}>
              <div className="pageTitle">試卷類型</div>
            </Box>
            <Box mb={deviceData.isDesktop ? 11 : 10} pl={deviceData.isDesktop ? 2 : 0}>
              <div>
                <RadioGroup buttonStyle='solid' optionType='button' value={sendParams.outputType} onChange={(e) => { onExamPaperInfoChange(e, 'outputType'); }} >
                  {
                    outputMap && outputMap.map(item => {
                      if (sendParams.outputType === 'Quick') {
                        if (item.value === 'Quick') {
                          return (
                            <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                          );
                        }
                      } else if (userInfo?.onePaperIdentity === 'TestCram') {
                        if (item.value === 'Files') {
                          return (
                            <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                          );
                        }
                      } else if (IDENTITY['SCHOOL_TEACHER'].indexOf(userInfo?.onePaperIdentity) !== -1) {
                        if (item.value === 'Files' || item.value === 'Quick') {
                          return (
                            <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                          );
                        }
                      } else {
                        return (
                          <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                        );
                      }
                    })
                  }
                </RadioGroup>
              </div>
            </Box>
            {!deviceData.isDesktop && <div className='blackDiv'></div>} */}
            <ExamPaperSetting
              sendParams={sendParams}
              checkedSend={checkedSend}
              rowData={typesetting?.ordering}
              globalExamPaperData={globalExamPaperData}
              getSendParams={getSendParams}
              changeCheckedSend={changeCheckedSend}
              onSaveExamPaperInfo={onSaveExamPaperInfo}
              paperSizeMap={paperSizeMap}
              wordSettingMap={wordSettingMap}
              paperContent={paperContent}
              analyzeContent={analyzeContent}
              advancedSetting={advancedSetting}
              onlineAdvanced={onlineAdvanced}
              onlineAdvancedSetting={onlineAdvancedSetting}
            />

          </StyledMpLayoutOptionContent>
        </LayoutSettingPaper>
        {sendParams.outputType === 'Files' && hasListen && (
          <LayoutSettingPaper title='英聽設定' hasListen={hasListen}>
            <EnglishListenSettings sendParams={sendParams} getSendParams={getSendParams} eduSubjectData={eduSubjectData} editCopyExamPaperData={editCopyExamPaperData} />
          </LayoutSettingPaper>
        )}
      </>
  );
};
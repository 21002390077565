import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Tooltip } from 'antd';
import { RedoCircleSolid, SignOutSolid } from '@onedesign/icon';
import classNames from 'classnames';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { useHistory } from 'react-router-dom';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { StyledExamPaperHeader } from './ExamPaperHeader.style';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';
import { setCookie } from 'utils/common';

// eslint-disable-next-line react/prop-types
export const ExamPaperHeader = ({ className, pageName, globalExamPaperData, step }) => {
  // const { isDesktop } = useDeviceDetect();
  const history = useHistory();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;

  const checkSetupBtnIsShow = () => {
    if (deviceData.isDesktop) {
      return false;
    } else {
      const path = window.location.pathname;
      if (path === '/computerQuestion' && step === 3 | step === 4 | step === 5) {
        return true;
      } else if ((path === '/manualProposition' || path === '/knowledgeProposition') && (step === 2 || step === 3)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isCompleted = useMemo(() => {
    switch (window.location.pathname) {
      case '/computerQuestion':
        return step === 6;
      case '/manualProposition':
      case '/knowledgeProposition':
        return step === 5;
      case '/studentIntelligentProposition':
        return step === 3;
      default:
        return false;
    }
  }, [step, window.location.pathname]);

  const ReturnHomeBtn = () => {
    if (userInfo.onePaperIdentity === 'TestCram' ) {
      history.push('/examPaperList', { completed: isCompleted });
    } else {
      history.push('/', { completed: isCompleted });
    }
  };

  useEffect(() => {
    history.listen((e) => {
      if (isCompleted) {
        history.push(e.pathname, { completed: isCompleted });
      }
    });
  }, []);

  return (
    <>
      <StyledExamPaperHeader className={className} data-testid="ExamPaperHeader" id={'ExamPaperHeader'}>
        <div className='pageTopTitle'>
          {globalExamPaperData?.eduSubjectName?.edu}{globalExamPaperData?.eduSubjectName?.subject}-{pageName}
         
        </div>
        <div className='button-list'>
          {
            !isCompleted &&
              <div className='redo' onClick={() => {
                setCookie('createNewPaperBackHome', true, 1);
                history.push(userInfo.onePaperIdentity === 'TestCram' ? '/examPaperList' : '/');}
              }>
                <Tooltip placement="top" title={'重新選擇欲出卷的學制科目與命題方式'} zIndex={100} color='#4e4e5a'>
                  <img className='redo-img' src='/assets/redoCircle.svg' alt='' />
                  <span>重新組卷</span>
                </Tooltip>
              </div>
          }
          <div className='returnHome' onClick={() => {
            ReturnHomeBtn();
            GA.returnHome();
          }}>
            <SignOutSolid/>{userInfo.onePaperIdentity === 'TestCram' ? '返回試卷列表' : '返回首頁'}
          </div>

        </div>
        {
          !deviceData.isDesktop &&
            <div id={'setupBtn'} className={classNames('setupBtn', checkSetupBtnIsShow() ? 'showSetupBtn' : '')}>題數配分</div>
        }
      </StyledExamPaperHeader>
    </>

  );
};
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oneclass/onedesign';
import { Input, AuthProvider, AuthenticationMachineState, openNotificationWithIcon } from '@oneclass/ui-components';
import { UserSolid, LockSolid } from '@onedesign/icon';
import { createValidator, required } from 'utils/validation';
import { UiLoginPage } from './LoginPage.style';

/**
 * 登入頁面
 */
export const LoginPage = () => {
  const inputRefs = {
    account: useRef(null),
    password: useRef(null),
  };
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const {
    login
  } = useAuthDispatch();
  const authState = useAuthState();
  const history = useHistory();
  const [state, setState] = useState({
    password: {
      value: '',
      message: '',
    },
    account: {
      value: '',
      message: '',
    }
  });
  const [loading, setLoading] = useState(false);
  const onChange = (key, event) => {
    const value = event.target.value;
    setState({
      ...state,
      errorMessage: '',
      [key]: {
        value,
        message: '',
      },
    });
  };
  const submit = async () => {
    const values = {
      account: state.account.value,
      password: state.account.value,
    };
    const rules = {
      account: [
        {
          message: '帳號欄位不得為空',
          validate: required,
        },
      ],
      password: [
        {
          message: '密碼欄位不得為空',
          validate: required,
        },
      ],
    };
    const [isPass, errorGroup] = createValidator(values, rules);
    setState({ ...state, ...errorGroup });
    if (isPass) return;
    const result = await login({ account: state.account.value, password: state.password.value });
    if (!result.isSuccess) {
      setState({
        ...state,
        account: { value: '', message: '' },
        password: { value: '', message: '' },
        errorMessage: `登入失敗：${result.errorMessage}`,
      });
      return;
    }
  };

  const getMoeLoiginUrl = async() => {
    const url = await fetch('https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/createOrgLoginForMoe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientid: process.env.REACT_APP_ONEPAPER_CLIENT_ID,
        params: ''
      })
    })
      .then(res => res.json())
      .then(response => response.data.url)
      .catch(err => console.error(err));
    window.location.href = url;
  };
  useEffect(() => {
    if (state.errorMessage) {
      openNotificationWithIcon('error', state.errorMessage);
    }
  }, [state.errorMessage]);
  useEffect(() => {
    if (authState.matches(AuthenticationMachineState.loginingExamKeeper)) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (authState.matches(AuthenticationMachineState.loggedIn)) {
      history.push('/');
    }
  }, [authState.value]);

  useEffect(() => {
    if (!authState.context.errorMsg) return;
    openNotificationWithIcon('error', authState.context.errorMsg);
  },
  [authState.context]);

  return (
    <UiLoginPage>
      <div className="wrapper">
        <div className="content">
          <div className="title">
            <img src={'/assets/logoNani.svg'} alt='logo' />
          </div>
          <div className="form">
            <Input
              label="帳號"
              id={'inputAccount'}
              antRef={inputRefs.account}
              icon={<UserSolid/>}
              placeholder="請輸入帳號"
              message={state.account && state.account.message}
              value={state.account && state.account.value}
              onChange={(e) => onChange('account', e)}
              onKeyEnter={() => {inputRefs['password']?.current.focus();}}
            />
            <Input.Password
              label="密碼"
              antRef={inputRefs.password}
              id={'inputPassword'}
              icon={<LockSolid/>}
              placeholder="請輸入密碼"
              message={state.password && state.password.message}
              value={state.password && state.password.value}
              onChange={(e) => onChange('password', e)}
              onPressEnter={() => {submit();}}
            />
          </div>
          <Button className="loginBtn" variant="brand1" isFull={true} size="large" isLoading={loading} onClick={() => {submit();}}>
							登入
          </Button>
          <div className='orTextZone'><span className='orText'>或</span></div>
          <Button className="loginBtn" variant="ui02_2" isFull={true} size="large" isLoading={loading} onClick={() => { getMoeLoiginUrl();}}>
            <div className='loginImg'>
              <img src={'/assets/login.svg'} alt='login' />
            </div>
            教育雲端帳號登入
          </Button>
          <div className="links">
            <a href="https://oneclub.oneclass.com.tw/forgetpassword/">忘記密碼</a>
            <div>|</div>
            <a href="https://oneclub.oneclass.com.tw/signup/">前往註冊 →</a>
          </div>
        </div>
      </div>
    </UiLoginPage>
  );
};

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Select,
  Pagination,
  sliceTableData,
  Input,
  PickListDropdown,
} from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { StyledTestListDesktop, StyledFilterBar } from './TestListDesktop.style';
import { v4 as uuidv4 } from 'uuid';
import * as GA from 'utils/googleAnalytics';
import { useHistory } from 'react-router-dom';
import { SESSION_STORAGE_KEY } from 'constants/index';
import StatusLabel from 'components/StatusLabel';

export const TestListDesktop = ({
  className,
  filterParams,
  eduSubjectOptions,
  filterData,
  pageState,
  pageChange,
  setFilterCondition,
  transformTime,
  renderLoadingContent,
  isLoading,
  setTestModalVisibleToggle,
  setModalInfo,
}) => {
  const history = useHistory();
  const newestTest = sessionStorage.getItem(SESSION_STORAGE_KEY.OnePaper_NewTest);

  const showShareModal = (data) => {
    if (!data) return;
    setModalInfo(data);
    setTestModalVisibleToggle();
  };

  return (
    <StyledTestListDesktop className={className} data-testid="TestListDesktop">
      <Box mb={4}>
        <StyledFilterBar>
          <Input.Search
            placeholder="請輸入搜尋的內容"
            allowClear={false}
            onSearch={(v) => {
              setFilterCondition(v, 'quizName');
              GA.setExamListFilter('quizName');
            }}
            maxLength={30}
          />
          <Select
            placeholder='學制科目'
            allowClear={filterParams?.eduSubject ? true : false}
            options={eduSubjectOptions}
            value={filterParams.eduSubject || null}
            onChange={(val) => {
              setFilterCondition(val, 'eduSubject');
              GA.setExamListFilter('eduSubject');
            }}
          />
          <PickListDropdown.DatePickListDropdown
            targetStr='的測驗'
            defaultTitle='測驗結束日期'
            defaultValue={null}
            onChange={(data) => {
              setFilterCondition(data, 'date');
              GA.setExamListFilter('date');
            }}
          />
        </StyledFilterBar>
      </Box>
      <Box>
        <Table className="tableZone">
          <Table.Header className='tableHeader'>
            <Table.Row>
              <Table.Item width='266px' flex='none'>測驗名稱</Table.Item>
              <Table.Item width='90px' flex='none'>學制科目</Table.Item>
              <Table.Item width='144px' flex='none'>測驗開始時間</Table.Item>
              <Table.Item width='144px' flex='none'>測驗結束時間</Table.Item>
              <Table.Item width='90px' flex='none'>狀態</Table.Item>
              <Table.Item width='88px' flex='none'>交卷人數</Table.Item>
              <Table.Item></Table.Item>
            </Table.Row>
          </Table.Header>
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <Table.Body bodyMaxHeight={true}>
                {
                  filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                    <Table.Row key={uuidv4()} className='tableRow'>
                      <Table.Item width='266px' flex='none'>
                        <div>
                          {item.QuizUID === newestTest && <StatusLabel isNew={true} />}
                          {item.QuizName}
                        </div>
                      </Table.Item>
                      <Table.Item width='90px' flex='none'>{item.EduSubjectName === '國中自然與生活科技' ? '國中自然' : item.EduSubjectName}</Table.Item>
                      <Table.Item width='144px' flex='none'>{transformTime(item.StartTime)}</Table.Item>
                      <Table.Item width='144px' flex='none'>{transformTime(item.EndTime)}</Table.Item>
                      <Table.Item width='90px' flex='none'>
                        <StatusLabel startTime={item.StartTime} endTime={item.EndTime} />
                      </Table.Item>
                      <Table.Item width='88px' flex='none' textalign={'center'}>{item.attendance}</Table.Item>
                      <Table.Item className='actions'>
                        <Button
                          className="tableBtn outlined"
                          onClick={() => showShareModal(item)}
                        >
                        測驗連結
                        </Button>
                        <Button
                          className="tableBtn"
                          variant='brand2'
                          onClick={() => history.push(`/testDetail/${item.QuizUID}`)}
                        >
                        測驗詳情
                        </Button>
                      </Table.Item>
                    </Table.Row>
                  )) :
                    <div className="tableEmpty">
                      <div>
                        目前還未擁有任何測驗喔！
                      </div>
                      <div>前往試卷列表，將試卷類型為「速測」的試卷建立測驗，<br/>讓學生快速進行線上測驗吧！
                      </div>
                    </div>
                }
              </Table.Body>
          }
        </Table>
        <div className="pageBlock">
          <Pagination
            total={filterData?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Box>
    </StyledTestListDesktop>
  );
};

TestListDesktop.propTypes = {
  className: PropTypes.string,
  filterParams: PropTypes.object,
  eduSubjectOptions: PropTypes.array,
  typeOptions: PropTypes.array,
  patternOptions: PropTypes.array,
  filterData: PropTypes.array,
  pageState: PropTypes.object,
  pageChange: PropTypes.func,
  setFilterCondition: PropTypes.func,
};
import styled from 'styled-components';

export const StyledQuestionDetail = styled.div`
    width: 100%;
    .questionDetail {
        background-color: ${({ theme }) => theme.colors.ui.ui_01};
        display: flex;
        padding: 8px 11px;
        flex-direction: column;
        row-gap: 4px;
        &__item {
            display: flex;
        }
        &__key {
            min-width: 5em;
            text-align: right;
        }
        &__value {
            
        }   
    }
    .toggleButton {
        background-color: ${({ theme }) => theme.colors.ui.ui_02};
        display: flex;
        width: 100%;
        margin-top: 8px;
        padding: 12px 16px;
        font-size: 16px;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`;
import React, { useState, useCallback, useEffect, useMemo, useRef, useContext } from 'react';
import { Box, DragBox, DragRow, Input, fetchDataMachine, FETCH_DATA_EVENT, setRadioOptions, openNotificationWithIcon, AuthProvider, Drawer } from '@oneclass/ui-components';
import { Checkbox, RadioGroup, CheckableTag, Button } from '@oneclass/onedesign';
import { Radio, Tooltip } from 'antd';
import update from 'immutability-helper';
import { useMachine } from '@xstate/react';
import { getMemberTypeSetting, postMemberTypeSetting } from 'api/member';
import { useHistoryBlock } from 'utils/hooks/useHistoryBlock';
import { useHistory } from 'react-router-dom';
import { OPTION_ERROR_MSG } from 'constants/index';
import { arrayUnique, arrayEqual, getCookie } from 'utils/common';
import {
  ChevronRightOutline,
  HorizontalOneColumnsOutline,
  HorizontalTwoColumnsAOutline,
  HorizontalTwoColumnsBOutline,
  StraightOneColumnsOutline,
  StraightTwoColumnsOutline,
  QuestionCircleSolid,
  CheckCircleSolid,
  ChevronRightSolid,
  SaveSolid,
} from '@onedesign/icon';
import { StyledMemberCenterPage } from './MemberCenterPage.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useBoolean } from 'ahooks';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';

const wordSettingIconMap = {
  HSS: <HorizontalOneColumnsOutline/>,
  HSD: <HorizontalTwoColumnsBOutline />,
  VHS: <StraightOneColumnsOutline />,
  VHD: <StraightTwoColumnsOutline />,
  HHD: <HorizontalTwoColumnsAOutline/>
};

export const MemberCenterPage = ({ className }) => {
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { conditionBlock, renderBlockModal } = useHistoryBlock(history);
  const env = process.env.REACT_APP_NODE_ENV;
  const rowData = ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'];
  const [sendParams, setSendParams] = useState({
    paperSize: '',
    wordSetting: '',
  });
  const [checkedSend, setCheckedSend] = useState(['paperName']);
  const [data, setData] = useState(rowData);
  const [currentCheckedInput, setCurrentCheckedInput] = useState('');
  const inputRefs = {
    schoolName: useRef(null),
    teacherSign: useRef(null),
    grade: useRef(null),
    room: useRef(null),
  };
  const oneClubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
  const [isChangeData, setIsChangeData] = useState(false);
  const [eduSubjectObject, setEduSubjectObject] = useState({
    education: '',
    subject: '',
  });

  const [state, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getMemberTypeSetting(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const {
          uid = '',
          isTeacher = false,
          identityName = '',
          account = '',
          name = '',
          organizationName = '',
          paperSizeMap = [],
          wordSettingMap = [],
          paperContent = [],
          typesetting = {},
          analyzeContent = []
        } = data || {};

        setData(typesetting?.ordering || rowData);

        return {
          isSuccess, systemCode, message,
          uid,
          isTeacher,
          identityName,
          account,
          name,
          organizationName,
          paperSizeMap: setRadioOptions(paperSizeMap) || [],
          wordSettingMap: setRadioOptions(wordSettingMap) || [],
          paperContent,
          typesetting,
          analyzeContent
        };
      }
    }
  });

  const [stateSendUserSetting, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', '儲存成功');
          send(FETCH_DATA_EVENT.FETCH, {
            payload: {
              jwtToken: oneClubToken,
            }
          });
          settingDrawerFalse();
          setIsChangeData(false);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const {
    uid,
    isTeacher,
    identityName,
    account,
    name,
    organizationName,
    paperSizeMap,
    wordSettingMap,
    paperContent,
    typesetting,
    analyzeContent
  } = state.context.result || {};

  const isDisabled = useMemo(() => {
    if (!typesetting) return true;
    let result = true;
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = ['analyzeContent', 'paperSize', 'wordSetting', 'paperContents'];
    Object.entries(sendParams).forEach(([key, value]) => {
      if ((key === 'grade' || key === 'room') && checkedSend.findIndex(item => item === 'classInfo') !== -1) {
        if (value.value !== typesetting[key]) result = false;
      }
      const needCheck = ((checkArray.findIndex(item => item === key) !== -1 && checkedSend.findIndex(item => item === key) !== -1));
      if (needCheck) {
        if (value.value !== typesetting[key]) result = false;
      }
      if (advancedCheckArray.findIndex(item => item === key) !== -1) {
        if (Array.isArray(value)) {
          if (key === 'analyzeContent') {
            if (!arrayEqual(value, typesetting[key]) && (sendParams?.paperContents ? sendParams?.paperContents.findIndex(item => item === 'Analyze') !== -1 : false )) result = false;
          } else {
            if (!arrayEqual(value, typesetting[key])) result = false;
          }
        } else {
          if (value !== typesetting[key]) result = false;
        }

      }
    });
    return result;
  }, [sendParams, typesetting, checkedSend]);

  const saveExamPaperDisabled = useMemo(() => {
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = ['amount', 'analyzeContent', 'paperSize', 'wordSetting', 'paperContents'];
    if (!sendParams) return true;
    let result = false;
    Object.entries(sendParams).forEach(([key, value]) => {
      if ((key === 'grade' || key === 'room') && checkedSend.findIndex(item => item === 'classInfo') !== -1) {
        if (!value.value || value.value === '') result = true;
      }
      const needCheck = ((checkArray.findIndex(item => item === key) !== -1 && checkedSend.findIndex(item => item === key) !== -1));
      if (needCheck) {
        if (!value.value || value.value === '') result = true;
      }
      if (advancedCheckArray.findIndex(item => item === key) !== -1) {
        if (!value || value === '' || value.length === 0) result = true;
      }
    });
    return (result || !isChangeData);
  }, [sendParams, checkedSend, isChangeData]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
      setIsChangeData(true);
    },
    [data],
  );

  const renderRowData = (key) => {
    switch (key) {
      case 'paperName':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'paperName') !== -1} disabled={true} className='dragRowCheckBox' />
            <div className='dragRowInnerRow'>
              <div className='label paperNameLabel'>
                <span>試卷名稱</span>
                <Tooltip
                  placement="top"
                  title={<span>於出卷時設定</span>}
                  zIndex={2999}
                  color='#4e4e5a'
                  trigger={deviceData.isDesktop ? 'hover' : 'click'}
                >
                  <div className={'paperNameTip'} >
                    <QuestionCircleSolid/>
                  </div>
                </Tooltip>
              </div>
              {/* <Input onBlur={(e) => {onInputBlur('paperName', e);}} placeholder='請輸入試卷名稱' message={sendParams?.paperName?.message} value={sendParams?.paperName?.value} onChange={(e) => onInputChange('paperName', e)}/> */}
            </div>
          </div>
        );
      case 'schoolName':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'schoolName') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('schoolName', e );}} />
            <div className='dragRowInnerRow'>
              <div className='label'>{titleName}</div>
              <Input antRef={inputRefs.schoolName} onBlur={(e) => {onInputBlur('schoolName', e);}} disabled={checkedSend.findIndex(item => item === 'schoolName') === -1} placeholder={`請輸入${titleName}`} message={sendParams?.schoolName?.message} value={sendParams?.schoolName?.value} onChange={(e) => onInputChange('schoolName', e)}/>
            </div>
          </div>
        );
      case 'teacherSign':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'teacherSign') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('teacherSign', e );}} />
            <div className='dragRowInnerRow'>
              <div className='label'>命題教師</div>
              <Input antRef={inputRefs.teacherSign} onBlur={(e) => {onInputBlur('teacherSign', e);}} placeholder='請輸入命題教師' disabled={checkedSend.findIndex(item => item === 'teacherSign') === -1} message={sendParams?.teacherSign?.message} value={sendParams?.teacherSign?.value} onChange={(e) => onInputChange('teacherSign', e)}/>
            </div>
          </div>
        );
      case 'classInfo':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'classInfo') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('classInfo', e );}} />
            <div className='dragRowInnerRow'>
              <div>班級</div>
              <div className='classInfo'>
                <Input antRef={inputRefs.grade} onBlur={(e) => {onInputBlur('grade', e);}} disabled={checkedSend.findIndex(item => item === 'classInfo') === -1} allowClear={false} error={sendParams?.grade?.message} value={sendParams?.grade?.value} onChange={(e) => {onInputChange('grade', e);}} />年
                <Input antRef={inputRefs.room} onBlur={(e) => {onInputBlur('room', e);}} disabled={checkedSend.findIndex(item => item === 'classInfo') === -1} allowClear={false} error={sendParams?.room?.message} value={sendParams?.room?.value} onChange={(e) => {onInputChange('room', e);}} />班
              </div>
            </div>
          </div>
        );
      case 'eduSubject':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'eduSubject') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('eduSubject', e );}} />
            <div className='dragRowInnerRow'>
              <div>學制科目</div>
            </div>
          </div>
        );
      case 'studentSign':
        return (
          <div className='dragRowContent'>
            <Checkbox checked={checkedSend.findIndex(item => item === 'studentSign') !== -1} className='dragRowCheckBox' onChange={(e) => {onRowChecked('studentSign', e );}}/>
            <div className='dragRowInnerRow'>
              <div>座號：＿＿＿  姓名：＿＿＿＿＿＿</div>
            </div>
          </div>
        );
      default :
        break;
    }
  };

  const onRowChecked = (key, e) => {
    setIsChangeData(true);
    const isChecked = e.target.checked;
    let nextData = [].concat(checkedSend);
    if (isChecked) {
      nextData = nextData.concat(key);
      setCurrentCheckedInput(key);
    } else {
      const dataIndex = nextData.findIndex(item => item === key);
      nextData.splice(dataIndex, 1);
      if (key === 'classInfo' && sendParams.grade && sendParams.room) {
        setSendParams({
          ...sendParams,
          grade: {
            value: sendParams?.grade.value,
            message: ''
          },
          room: {
            value: sendParams?.room.value,
            message: ''
          },
        });
      } else if (sendParams[key]) {
        setSendParams({
          ...sendParams,
          [key]: {
            value: sendParams[key].value,
            message: ''
          }
        });
      }
    }
    setCheckedSend(nextData);
  };

  const onInputBlur = (type) => {
    if (!sendParams[type]) return;
    if ((type === 'room' || type === 'grade')) {
      if (checkedSend.findIndex(item => item === 'classInfo') === -1) {
        setSendParams({
          ...sendParams,
          [type]: {
            value: sendParams[type].value,
            message: ''
          }
        });
        return;
      }
    } else {
      if (checkedSend.findIndex(item => item === type) === -1) {
        setSendParams({
          ...sendParams,
          [type]: {
            value: sendParams[type].value,
            message: ''
          }
        });
        return;
      }
    }

    if (!sendParams[type].value) {
      if (type === 'schoolName') {
        setSendParams({
          ...sendParams,
          [type]: {
            value: sendParams[type].value,
            message: `請輸入${titleName}`
          }
        });
      } else {
        setSendParams({
          ...sendParams,
          [type]: {
            value: sendParams[type].value,
            message: OPTION_ERROR_MSG[type]
          }
        });
      }
    }
  };

  useEffect(() => {
    if (currentCheckedInput === 'classInfo' && checkedSend.some(item => item === 'classInfo')) {
      inputRefs['grade'] && inputRefs['grade']?.current.focus();
      if (!inputRefs['grade']?.current?.state?.value) {
        setSendParams({
          ...sendParams,
          'grade': {
            value: sendParams['grade']?.value,
            message: OPTION_ERROR_MSG['grade']
          }
        });
      }
      if (!inputRefs['room']?.current?.state?.value) {
        setSendParams({
          ...sendParams,
          'room': {
            value: sendParams['room']?.value,
            message: OPTION_ERROR_MSG['room']
          }
        });
      }
    } else if (checkedSend.some(item => item === currentCheckedInput)) {
      inputRefs[currentCheckedInput] && inputRefs[currentCheckedInput]?.current.focus();
      if (!inputRefs[currentCheckedInput]?.current?.state?.value) {
        if (currentCheckedInput === 'studentSign' || currentCheckedInput === 'eduSubject') return;
        if (currentCheckedInput === 'schoolName') {
          setSendParams({
            ...sendParams,
            [currentCheckedInput]: {
              value: sendParams[currentCheckedInput]?.value,
              message: `請輸入${titleName}`
            }
          });
        } else {
          setSendParams({
            ...sendParams,
            [currentCheckedInput]: {
              value: sendParams[currentCheckedInput]?.value,
              message: OPTION_ERROR_MSG[currentCheckedInput]
            }
          });
        }
      }
    }
  }, [checkedSend, currentCheckedInput]);

  const onInputChange = (key, event) => {
    setIsChangeData(true);
    const value = event.target.value;
    setSendParams({
      ...sendParams,
      [key]: {
        value,
        message: ''
      }
    });
  };

  const onRadioChange = (type, e) => {
    setIsChangeData(true);
    const value = e.target.value;
    setSendParams({
      ...sendParams,
      [type]: value
    });
  };

  const handleChange = (tag, checked) => {
    setIsChangeData(true);
    let paperContents = [];
    if (sendParams?.paperContents) paperContents = sendParams?.paperContents;
    const nextSelectedTags = checked ? [...paperContents, tag] : paperContents.filter(t => t !== tag);

    setSendParams({
      ...sendParams,
      paperContents: [...nextSelectedTags]
    });
  };
  const isChecked = (tag) => {
    if (!sendParams?.paperContents) return;
    return sendParams?.paperContents.indexOf(tag) > -1;
  };

  const onSave = () => {
    let nextData = {};

    const {
      schoolName = {},
      paperName,
      teacherSign = {},
      grade = {},
      room = {},
      paperSize,
      wordSetting,
      paperContents,
      analyzeContent,
      amount,
      advanced,
      studentSign,
      ordering,
    } = sendParams || {};

    const eduSubject = eduSubjectObject ? (eduSubjectObject?.education + eduSubjectObject?.subject) : '';

    nextData = {
      schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
      paperName: paperName ? paperName.value : '',
      teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
      grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
      room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
      eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
      studentSign: checkedSend.findIndex(item => item === 'studentSign') !== -1 ? studentSign : '',
      paperSize,
      wordSetting,
      paperContents: paperContents || [],
      analyzeContent: analyzeContent || [],
      amount: amount || 1,
      advanced: advanced || [],
      ordering: ordering || rowData,
    };
    sendUserSetting(FETCH_DATA_EVENT.FETCH, {
      payload: nextData
    });
    GA.saveUserSetting();
  };

  const onCancel = () => {
    const {
      paperSize = '',
      wordSetting = '',
      paperContents = [],
      paperName = '',
      amount = 1,
      advanced = [],
      schoolName = '',
      room = '',
      grade = '',
      teacherSign = '',
      analyzeContent = [],
      ordering = rowData,
    } = typesetting || {};
    const eduSubject = eduSubjectObject ? (eduSubjectObject?.education + eduSubjectObject?.subject) : '';

    let nextCheckSend = [].concat(checkedSend);
    rowData.forEach(item => item => {
      if ((item === 'classInfo') && room && grade) {
        nextCheckSend.concat(item);
      } else {
        if (typesetting[item] !== '') {
          nextCheckSend.concat(item);
        }
      }

    });
    setCheckedSend(nextCheckSend);

    const newPaperName = paperName || data?.defaultName;
    setSendParams({
      ...sendParams,
      examUID: data.examUID,
      paperSize,
      wordSetting,
      paperContents,
      paperName: {
        value: newPaperName,
        message: '',
      },
      schoolName: {
        value: schoolName,
        message: '',
      },
      grade: {
        value: grade,
        message: '',
      },
      room: {
        value: room,
        message: '',
      },
      teacherSign: {
        value: teacherSign,
        message: '',
      },
      // outputType: globalExamPaperData?.output || data?.outputType[0].code || 'Online',
      // examType: data?.examType[0].code || 'General',
      amount,
      advanced,
      analyzeContent: arrayUnique(['Question', 'Answer', 'Analyze'].concat(analyzeContent)),
      studentSign: '座號：＿＿＿  姓名：＿＿＿＿＿＿',
      eduSubject,
      ordering,
    });
  };

  const onChecked = (type, key, e) => {
    const isChecked = e.target.checked;
    let nextData = [].concat(sendParams[type] || []);
    if (isChecked) {
      nextData = nextData.concat(key);
    } else {
      const eleIndex = nextData.findIndex(item => item === key);
      nextData.splice(eleIndex, 1);
    }
    setSendParams({
      ...sendParams,
      [type]: nextData
    });
  };

  useEffect(() => {
    send(FETCH_DATA_EVENT.FETCH, {
      payload: {
        jwtToken: oneClubToken,
      }
    });
  }, []);

  useEffect(() => {
    conditionBlock(isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    const eduSubject = eduSubjectObject ? (eduSubjectObject?.education + eduSubjectObject?.subject) : '';

    if (!typesetting) {
      setSendParams({
        ...sendParams,
        paperSize: '',
        wordSetting: '',
        paperName: '',
        amount: 1,
        advanced: [],
        schoolName: '',
        room: '',
        grade: '',
        teacherSign: '',
        analyzeContent: ['Question', 'Answer', 'Analyze'],
        studentSign: '座號：＿＿＿  姓名：＿＿＿＿＿＿',
        eduSubject,
        ordering: rowData,
      });
      return;
    }
    const {
      paperSize = '',
      wordSetting = '',
      paperContents = [],
      paperName = '',
      amount = 1,
      advanced = [],
      schoolName = '',
      room = '',
      grade = '',
      teacherSign = '',
      analyzeContent = [],
      ordering = rowData,
    } = typesetting || {};

    let nextCheckSend = [].concat(checkedSend);
    rowData.forEach(item => {
      if ((item === 'classInfo') && room && grade) {
        nextCheckSend.push(item);
      } else {
        if (typesetting[item] && item !== 'paperName') {
          nextCheckSend.push(item);
        }
      }

    });
    setCheckedSend(nextCheckSend);

    const newPaperName = paperName || data?.defaultName;
    setSendParams({
      ...sendParams,
      examUID: data.examUID,
      paperSize,
      wordSetting,
      paperContents,
      paperName: {
        value: newPaperName,
        message: '',
      },
      schoolName: {
        value: schoolName,
        message: '',
      },
      grade: {
        value: grade,
        message: '',
      },
      room: {
        value: room,
        message: '',
      },
      teacherSign: {
        value: teacherSign,
        message: '',
      },
      // outputType: globalExamPaperData?.output || data?.outputType[0].code || 'Online',
      // examType: data?.examType[0].code || 'General',
      amount,
      advanced,
      analyzeContent: analyzeContent ? arrayUnique(['Question', 'Answer', 'Analyze'].concat(analyzeContent)) : ['Question', 'Answer', 'Analyze'],
      studentSign: '座號：＿＿＿  姓名：＿＿＿＿＿＿',
      eduSubject,
      ordering,
    });
  }, [typesetting]);

  useEffect(() => {
    if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
      if (authState.context.userDetails?.userStatus) {
        const { organization, identity, usetype, name } = authState.context.userDetails?.userStatus;
        let education = 'E';
        let subject = 'CH';
        if (authState.context.userDetails?.preference) {
          education = authState.context.userDetails?.preference.education;
          subject = authState.context.userDetails?.preference.subject;
        }
        setEduSubjectObject({
          education,
          subject,
        });
        // setUserData({
        //   organizationType: organization.type,
        //   userType: usetype,
        //   identity,
        //   name,
        //   subject,
        //   education,
        // });
      }
    }
  }, [authState.value]);

  const titleName = useMemo(() => {
    return userInfo?.onePaperIdentity === 'TestCram' ? '機構名稱' : '學校名稱';
  }, [userInfo]);

  useEffect(() => {
    setSendParams({
      ...sendParams,
      ordering: data,
    });
  }, [data]);

  // ---- 手機版 相關 -----

  const [settingDrawerVisible, { toggle: settingDrawerToggle, setFalse: settingDrawerFalse }] = useBoolean(false);

  return (
    <StyledMemberCenterPage className={className} data-testid="MemberCenterPage">
      {
        userInfo?.onePaperIdentity !== 'TestCram' &&
        <>
          <Box mb={deviceData.isDesktop ? 6 : 4} ml={deviceData.isDesktop ? 4 : 0}>
            <div className='title'>個人資料</div>
          </Box>
          <Box mb={deviceData.isDesktop ? 64 : 24}>
            <div className='userDataBox'>
              <div className='userDataRow'>
                <div>身份：</div>{identityName}
                {
                  isTeacher &&
                  <span><CheckCircleSolid/>已認證教師</span>
                }
              </div>
              <div className='userDataRow'>
                <div>帳號：</div>{account}
              </div>
              <div className='userDataRow'>
                <div>姓名：</div>{name}
              </div>
              {
                typesetting !== null &&
                <div className='userDataRow'>
                  <div>學校：</div>{organizationName}
                </div>
              }

              <div className='userDataRow hint'>
                * 此區塊資料來自 OneClub 會員中心，若需要修改請至 OneClub 會員中心
              </div>
            </div>
          </Box>
        </>
      }
      {renderBlockModal('新增的流程尚未完成，是否要離開此頁？', onSave, onCancel)}
    </StyledMemberCenterPage>
  );
};
import { useState } from 'react';
import { Button } from '@oneclass/onedesign';
import { Modal } from '@oneclass/ui-components';
import { setCookie } from 'utils/common';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'ahooks';
import styled from 'styled-components';
const StyledModal = styled.div`
    .examPaperHeaderModal{
		position: absolute;
	}
    @media (max-width: 1024px) {
        .ant-modal{
			position: absolute;
			bottom: 0;
			max-width:100%;
			padding: 0 0;
			margin: 0 0;
		}
		.ant-modal-content{
			position: absolute;
			bottom: 0;
			border-radius: 12px 12px 0 0;
		}
		.ant-modal-header{
			display: flex;
			justify-content:center;
			align-items:center;
			padding: 21px 40px 21px;
		}
		.ant-modal-close-x{
			display: flex;
			justify-content:center;
			align-items:center;
			width: 50px;
			height:63px;
		}
		.ant-modal-body{
			padding: 12px 40px 12px;
		}
		.ant-modal-footer{
			padding: 4px 40px 20px;
		}
		.footerBtn{
			width: 100%;
		}
		.footerBtnBox{
			width: 100%;
			display: flex;
			flex-direction:column-reverse;
			>div{
				width: 100%;
				&:nth-child(2){
					margin: 0 0 12px 0;
				}
			}
		}
		.showSetupBtn{
			display: block ;
		}
    }
`;
export const useRedoModal = (onePaperIdentity) => {
  const history = useHistory();
  const [modalVisible, { toggle: setModalToggle, setFalse: setModalFalse }] = useBoolean(false);
  const [modalText, setModalText] = useState();
  const [modalType, setModalType] = useState();
  const [backUrl, setBackUrl] = useState('/examPaperList');

  const goToHome = (backUrl = '/', linkText = '試卷列表') => {
    setModalText(<div>離開後此次出卷將被放棄，系統不會保存你的出卷紀錄</div>);
    setModalType('goHome');
    setBackUrl(backUrl);
    setModalToggle();
  };
  const reDo = (backUrl = '/') => {
    setModalText(<div>離開後此次出卷將被放棄，系統不會保存你的出卷紀錄</div>);
    setModalType('reDo');
    setBackUrl(backUrl);
    setModalToggle();
  };
  const renderRedoModal = () => {
    return (
      <StyledModal>
        <Modal
          className='examPaperHeaderModal mobileModal'
          visible={modalVisible}
          onCancel={setModalFalse}
          zIndex={1005}
          title={
            <div>此卷尚未完成，您確定要離開嗎？</div>
          }
          footer={
            <div className="footerBtn footerBtnBox">
              <Button variant='ui01'
                onClick={() => {
                  if (modalType === 'reDo') {
                    setCookie('createNewPaperBackHome', true, 1);
                  }
                  if (onePaperIdentity === 'TestCram') {
                    history.push(backUrl);
                  } else {
                    history.push('./');
                  }
                }}>確定離開</Button>
              <Button
                variant='brand2'
                onClick={setModalFalse}>再考慮一下</Button>
            </div>
          }
        >
          <div className='modalContent'>{modalText}</div>
        </Modal>
      </StyledModal>

    );
  };

  return { renderRedoModal, goToHome, reDo };
};
import { useEffect } from 'react';
import { useBitrix } from 'utils/hooks/useBitrix';
import AppRoutes from './routes';

const App = () => {
  const { insert } = useBitrix();

  useEffect(() => {
    insert();
  }, []);

  return <AppRoutes />;
};
export default App;

/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, TreeCheck, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, setSelectOptions, sectionToChinese } from '@oneclass/ui-components';
import { Checkbox, RadioGroup } from '@oneclass/onedesign';
import { Row, Col } from 'antd';
import { useMachine } from '@xstate/react';
import { useSelections } from 'ahooks';
import { LANGUAGE_LIST, VERSION_NAME, multiSubjectMap } from 'constants/index';
import { UiFlexBox } from 'styles/index';
import { StyledHomeAreaSelectContent, UiSelectList } from './HomeAreaSelectContent.style';
import { postRangeSelection } from 'api/query';
import { ALL_GRADE, HISTORIC_EXAM_ID, subjectMap } from 'constants/index';
import { getCustomUid, removeCustomUid, getSources } from 'utils/common';

import _ from 'lodash';
import { shouldHideOrdinalNumber } from 'utils/common';
import { useHideOrdinalNumberChaptersContext } from 'providers/hideOrdinalNumberChaptersProvider';

export const HomeAreaSelectContent = ({
  className,
  resetToggle,
  examPaperType,
  saveData,
  getSendAreaParams,
  memberEduSubject,
  getKeyList,
  setTableData,
  getSaveData,
  identity,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  globalExamPaperData,
  isGoBack,
  setIsGoBack,
  setBookList,
  treeCheckedList,
  setTreeCheckedList,
  reCreateExam,
  multiSubjectList,
  setMultiSubjectList
}) => {
  const [sendParams, setSendParams] = useState({
    ...saveData.areaParams,
    year: saveData.areaParams.year,
    books: [],
  });
  const [sendYear, setYear] = useState(saveData?.areaParams?.year);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const { chaptersMap: hideOrdinalNumberChapters } = useHideOrdinalNumberChaptersContext();
  const [allSourceList, setAllSourceList] = useState({});

  const fetchBookList = async (subject, basicPayload) => {
    return await postRangeSelection({
      edusubject: subject,
      ...basicPayload
    });
  };

  const [stateGetBook, sendGetBook] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setSelectLoading(true);
        setIsLoading(true);

        const basicPayload = {
          drawUpStyle: 'CustomPattern',
          outputStyle: memberEduSubject?.output,
        };


        // 社會科 合科
        if (event.payload.edusubject in multiSubjectMap) {
          const subjects = multiSubjectMap[event.payload.edusubject];

          const bookListPromises = subjects.map(subject => fetchBookList(subject, basicPayload));

          const bookLists = await Promise.all(bookListPromises);
          bookLists.forEach(bookList => {
            setMultiSubjectList(prev => [...prev, bookList.data.content]);
          });
        }

        const eduSubject = () => {
          if (event.payload.edusubject in multiSubjectMap) {
            return multiSubjectMap[event.payload.edusubject][0];
          } else {
            return event.payload.edusubject;
          }
        };

        const payload = {
          ...event.payload,
          edusubject: eduSubject(),
        };

        const res = await postRangeSelection(payload);
        const { data, isSuccess, systemCode, message } = res;
        const { content } = data;
        setSelectLoading(false);
        if (!isEditCopy) setIsLoading(false);

        if ((JSON.stringify(content) !== '{}')) {
          setBookList(content);
        }
        return {
          isSuccess, systemCode, message,
          bookList: content,
        };
      },
    },
  });

  const {
    bookList = {},
    // sourceOptions,
  } = stateGetBook.context.result || {};

  // 自行產生UID 讓畫面渲染時不會有顯示錯誤
  const formatBookList = useCallback((bookList) => {
    if (!bookList) return {};
    if (JSON.stringify(bookList) === '{}') return {};
    const nextData = JSON.parse(JSON.stringify(bookList));
    let num = 1000;
    Object.entries(nextData).forEach(([key_1, value_1]) => { // 課綱
      Object.entries(value_1).forEach(([key_2, value_2]) => { // 學年度
        Object.entries(value_2).forEach(([key_3, value_3]) => { // 版本
          Object.entries(value_3).forEach(([key_4, value_4]) => { // 冊次
            num++;
            nextData[key_1][key_2][key_3][key_4] = {
              ...value_4,
              uid: num,
              chapters: value_4?.chapters ? value_4.chapters.map((chapter) => {
                const setChaptersUid = (chapter) => {
                  num++;
                  if (chapter.data) {
                    return {
                      ...chapter,
                      uid: num,
                      data: chapter.data.map(chapter_ => setChaptersUid(chapter_))
                    };
                  } else if (chapter.Knowledges) {
                    return {
                      ...chapter,
                      uid: num,
                      Knowledges: chapter.Knowledges.map(chapter_ => setChaptersUid(chapter_))
                    };
                  } else {
                    return {
                      ...chapter,
                      uid: num,
                    };
                  }
                };
                return setChaptersUid(chapter);
              }) : []
            };

          });
        });
      });
    });

    return nextData;
  }, [bookList]);

  const sourceOptions = useMemo(() => {
    let _sourceOptions = [];
    treeCheckedList.forEach((item) => {
      if (allSourceList[item]) {
        _sourceOptions.push(...allSourceList[item]);
      }
    });

    const sortArray = (array) => {
      return array.sort((a, b) => {
        return a.code.substring(2, 5) - b.code.substring(2, 5);
      }).sort((a, b) => {
        return a.code.substring(0, 2) - b.code.substring(0, 2);
      });
    };

    return _.uniqBy(sortArray(_sourceOptions), 'code').map((item) => ({
      name: item.name,
      value: item.code
    }));
  }, [treeCheckedList]);

  useEffect(() => {
    if (!sourceOptions | sourceOptions.length === 0) return null;
    if (!chapterOptions | !bookOptions | !versionOptions | !yearOptions | !curriculumOptions) return null;
    if (!stateGetBook.matches(FETCH_DATA_STATE.DONE)) return null;
    if (!isGoBack) {
      // 預設全選
      setSendParams({
        ...sendParams,
        source: sourceOptions.map(item => item?.value) || [],
      });
      sourceSetSelected(sourceOptions.map(item => item?.value) || []);
    }
  }, [sourceOptions]);

  const setRadioOptions = (type, array) => {
    return array.map((item) => {
      if (type === 'curriculum') {
        return {
          label: item + '課綱',
          value: item,
        };
      } else if (type === 'year') {
        return {
          label: item + '學年度',
          value: item,
        };
      } else if (type === 'version') {
        return {
          label: VERSION_NAME[item],
          value: item,
        };
      } else if (type === 'books') {
        return {
          value: item[0],
          name: item[1].volumnName,
        };
      }
    });
  };

  const curriculumOptions = useMemo(() => {
    if (!bookList || JSON.stringify(bookList) === '{}') return [];
    const data = (memberEduSubject.edu === 'J' || memberEduSubject.edu === 'H') ? setRadioOptions('curriculum', Object.keys(bookList)).filter(item => item.value !== '99') : setRadioOptions('curriculum', Object.keys(bookList));
    // 數字越大牌越前面。
    return data.filter(({ value }) => memberEduSubject.output === 'Series' || value !== '000').sort((a, b) => {
      return Number(b?.value) - Number(a?.value);
    });
  }, [bookList, memberEduSubject]);

  const yearOptions = useMemo(() => {
    if ((!bookList || JSON.stringify(bookList) === '{}') || !sendParams.curriculum) return [];
    const data = bookList[sendParams.curriculum] ? setRadioOptions('year', Object.keys(bookList[sendParams.curriculum])).filter(item => item.value !== '109') : [];
    return data.sort((a, b) => {
      return Number(b?.value) - Number(a?.value);
    });
  }, [sendParams.curriculum, bookList]);

  const versionOptions = useMemo(() => {
    if ((!bookList || JSON.stringify(bookList) === '{}') || !sendParams.curriculum || !sendParams.year) return [];
    const data = bookList[sendParams.curriculum][sendParams.year] ? setRadioOptions('version', Object.keys(bookList[sendParams.curriculum][sendParams.year])) : [];
    return data.sort((a, b) => {
      return b?.value.charCodeAt() - a?.value.charCodeAt();
    });
  }, [sendParams.curriculum, sendParams.year, bookList]);

  const bookOptions = useMemo(() => {
    if ((!bookList || JSON.stringify(bookList) === '{}') || !sendParams.curriculum || !sendParams.year || !sendParams.version) return [];

    const eduSubject = memberEduSubject.edu + memberEduSubject.subject;

    // 自然合科
    if (eduSubject === 'JNA') {
      const multiSubjectBookList = multiSubjectList.flatMap((item) => {
        let list = [];

        if (item?.[sendParams.curriculum]?.[sendParams.year]?.[sendParams.version]) {
          list.push(...setRadioOptions('books', Object.entries(item[sendParams.curriculum][sendParams.year][sendParams.version])));
        }

        return list;
      });

      const seen = {};
      const uniqueValues = multiSubjectBookList.reduce((acc, item) => {
        if (!seen[item.value]) {
          seen[item.value] = true;
          acc.push(item);
        }
        return acc;
      }, []);

      return uniqueValues;
    }


    return bookList[sendParams.curriculum][sendParams.year][sendParams.version] ? setRadioOptions('books', Object.entries(bookList[sendParams.curriculum][sendParams.year][sendParams.version])) : [];
  }, [sendParams.curriculum, sendParams.year, sendParams.version, bookList]);

  const formattedBookList = useMemo(() => formatBookList(bookList), [bookList]);

  const chapterOptions = useMemo(() => {
    const getTree = (chapter, isFirstHierarchy = false, isHistoricExam = false, isOrdinalNumberHided = false, key, book) => {
      // 遇到特定章節時，不顯示序數
      const hideOrdinalNumber = isOrdinalNumberHided || shouldHideOrdinalNumber(chapter.name, sendParams.version, memberEduSubject.edu + memberEduSubject.subject) || Object.keys(hideOrdinalNumberChapters).includes(chapter.name);
      let ordinalNumber = '';

      if (!isHistoricExam) {
        if (!hideOrdinalNumber) {
          if (LANGUAGE_LIST.includes(memberEduSubject?.subject)) {
            ordinalNumber = `第${sectionToChinese(parseInt(chapter.code))}課_`;
          } else {
            ordinalNumber = `第${sectionToChinese(parseInt(chapter.code))}章_`;
          }
        }
      }

      // 取得全部出處
      if (chapter.Knowledges) {
        setAllSourceList(prev => ({
          ...prev,
          [`${chapter?.uid}_${key}_${book}`]: chapter.Knowledges[0].sources
        }));
      }

      return ({
        title: isFirstHierarchy ? `${ordinalNumber}${hideOrdinalNumberChapters?.[chapter.name] || chapter.name}` : `${hideOrdinalNumber ? '' : `[${chapter.code}] `}${chapter.name}`,
        key: `${chapter?.uid}_${key}_${book}`,
        children: chapter?.Knowledges?.length ? chapter.Knowledges.map(knowledge => ({
          title: knowledge.name,
          key: `${getCustomUid(chapter, knowledge)}_${key}_${book}`,
        })) : chapter?.data?.map(chapter_ => getTree(chapter_, false, isHistoricExam, hideOrdinalNumber, key, book))
      });
    };

    if ((!formattedBookList || JSON.stringify(formatBookList) === '{}') || !sendParams.curriculum || !sendParams.year || !sendParams.version || !sendParams.books || !memberEduSubject?.subject) return [];
    const nextData = (renderList, eduSubject) => sendParams.books.sort((a, b) => { return a.substring(1, 3) - b.substring(1, 3); }).map((book) => {

      // 是歷屆大考
      const isHistoricExam = book === HISTORIC_EXAM_ID;
      const key = renderList?.[sendParams.curriculum]?.[sendParams.year]?.[sendParams.version]?.[book]?.bookId?.split('-')[1]?.slice(0, 3) || '';
      const keyItem = `${renderList[sendParams.curriculum][sendParams.year][sendParams.version][book]?.uid}`;
      const titleItem = renderList?.[sendParams.curriculum]?.[sendParams.year][sendParams.version][book]?.volumnName;
      const allEduSubject = Object.values(multiSubjectMap).flat();
      const subject = eduSubject?.slice(1);

      return {
        title: (allEduSubject.includes(eduSubject) && titleItem) ? `${subjectMap[subject]}_${titleItem}` : titleItem,
        key: allEduSubject.includes(eduSubject) ? `${eduSubject}_${keyItem}` : keyItem,
        children: key && renderList[sendParams.curriculum][sendParams.year][sendParams.version][book].chapters
          .map((chapter) => key && getTree(chapter, true, isHistoricExam, false, key, book)),
      };
    });

    const eduSubject = memberEduSubject.edu + memberEduSubject.subject;

    const multiSubjectNextData = multiSubjectList.flatMap((item, index) => {
      return nextData(formatBookList(item), multiSubjectMap[eduSubject][index]);
    });
    const result = multiSubjectNextData.length > 0 ? multiSubjectNextData : nextData(formattedBookList);

    return result.filter((item) => item.title);
  }, [sendParams.curriculum, sendParams.year, sendParams.version, sendParams.books]);

  const defaultOpenArray = useMemo(() => {
    if (!chapterOptions || chapterOptions.length === 0) return [];
    return chapterOptions.map((book) => {
      return book?.key;
    });
  }, [chapterOptions]);

  const getAllTreeData = (data) => {
    if (!data || data.length === 0) return [];

    const nextData = [];
    const stack = [...data];

    while (stack.length > 0) {
      const node = stack.pop();
      nextData.push(node.key);

      if (node.children && node.children.length > 0) {
        stack.push(...node.children);
      }
    }

    return nextData;
  };

  const treeDataLength = useMemo(() => {
    if (!chapterOptions) return 0;
    return getAllTreeData(chapterOptions).length;
  }, [chapterOptions]);

  const onTreeChange = (data) => {
    setTreeCheckedList(data);
    setIsGoBack(false);
  };

  const onCheckAllChange = e => {
    setTreeCheckedList(e.target.checked ? getAllTreeData(chapterOptions) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const {
    selected: bookSelected,
    allSelected,
    isSelected,
    toggle,
    toggleAll,
    unSelectAll,
    setSelected,
    partiallySelected,
  } = useSelections(bookOptions.map(item => item.value), (reCreateExam ? [] : saveData.areaParams?.books));

  // 出處勾選狀態
  const {
    selected: sourceSelected,
    allSelected: sourceAllSelected,
    isSelected: sourceIsSelected,
    unSelectAll: sourceUnSelectAll,
    setSelected: sourceSetSelected,
    toggle: sourceToggle,
    toggleAll: sourceToggleAll,
    partiallySelected: sourcePartiallySelected,
  } = useSelections(sourceOptions ? sourceOptions.map(item => item.value) : [], saveData.areaParams?.source || []);

  const onRadioChange = (type, e) => {
    if (type === 'curriculum') {
      const curriculum = e.target.value;
      const year = setRadioOptions('year', Object.keys(bookList[curriculum])).filter(item => item.value !== '109')[0].value;
      // const version = setRadioOptions('version', Object.keys(bookList[curriculum][year]))[0].value;
      setSendParams({
        ...sendParams,
        [type]: curriculum,
        year: year,
        version: 'N',
        books: [],
        source: [],
      });
      setSelected([]);
      setTreeCheckedList([]);
      sourceUnSelectAll();
    } else if (type === 'year') {
      const year = e.target.value;
      // const version = setRadioOptions('version', Object.keys(bookList[sendParams.curriculum][year]))[0].value;
      setSendParams({
        ...sendParams,
        [type]: year,
        version: 'N',
        books: [],
        source: [],
      });
      setSelected([]);
      setTreeCheckedList([]);
      sourceUnSelectAll();
    } else if (type === 'version') {
      setSendParams({
        ...sendParams,
        [type]: e.target.value,
        books: [],
        source: [],
      });
      setSelected([]);
      setTreeCheckedList([]);
      sourceUnSelectAll();
    } else {
      setSendParams({
        ...sendParams,
        [type]: e.target.value
      });
    }
  };

  const onChangeYear = (type, e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    if (isEditCopy) {
      setIsLoading(true);
    }
    if (!memberEduSubject?.edu || !memberEduSubject?.subject || sendYear === '') return;

    sendGetBook(FETCH_DATA_EVENT.FETCH, {
      payload: {
        edusubject: memberEduSubject?.edu + memberEduSubject?.subject,
        outputStyle: globalExamPaperData?.output,
        drawUpStyle: 'FastPattern',
      }
    });

    setTreeCheckedList([]);
    unSelectAll();
    sourceUnSelectAll();
    if (memberEduSubject?.edu === saveData.education && memberEduSubject?.subject === saveData.subject && sendYear === saveData?.areaParams?.year) return;
    setSendParams({
      curriculum: sendParams.curriculum,
      year: sendYear
    });

    getKeyList([]);
    setTableData({});
  }, [memberEduSubject.edu, memberEduSubject.subject]);

  useEffect(() => {
    if (stateGetBook.matches(FETCH_DATA_STATE.DONE)) {
      if (sendYear === '' || sendYear === saveData?.areaParams?.year) {
        setSendParams(saveData.areaParams);
        setSelected(saveData.areaParams?.books);
        sourceSetSelected(saveData.areaParams?.source);
        setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
      }
    }
  }, [stateGetBook.value, sendYear]);

  useEffect(() => {
    setSendParams({
      ...sendParams,
      books: bookSelected
    });
  }, [bookSelected]);

  useEffect(() => {
    setSendParams({
      ...sendParams,
      source: sourceSelected
    });
  }, [sourceSelected]);

  useEffect(() => {
    if (treeCheckedList.length === 0) {
      setIndeterminate(false);
      setCheckAll(false);
    } else {
      setIndeterminate(treeCheckedList.length !== treeDataLength ? true : false);
      setCheckAll(treeCheckedList.length !== treeDataLength ? false : true);
    }
  }, [treeDataLength, treeCheckedList]);

  useEffect(() => {
    if (!sendParams) return;
    getSendAreaParams({
      ...sendParams,
      bookIDs: sendParams?.books ? sendParams.books.map(item => `${sendParams.year}${sendParams.version}-${memberEduSubject.edu}${memberEduSubject.subject}${item}`) : [],
      keys: treeCheckedList
        .filter(item => typeof item !== 'number')
        .map(item => removeCustomUid(item)),
      treeCheckedList
    });
  }, [sendParams, treeCheckedList, memberEduSubject]);

  useEffect(() => {
    if (sendParams.curriculum === saveData.areaParams.curriculum) return;
    if (sendParams.version === saveData.areaParams.version) return;
    if (!sourceOptions) return;
    unSelectAll();
    sourceUnSelectAll();
    setSelected([]);
    sourceSetSelected(sourceOptions.map(item => item.value));
  }, [sendParams.curriculum, sendParams.version]);

  useEffect(() => {
    const nextData = treeCheckedList.filter(item => getAllTreeData(chapterOptions).includes(item));
    setTreeCheckedList(nextData);
  }, [sendParams.books]);

  useEffect(() => {
    getSaveData('bookOptions', bookOptions);
  }, [bookOptions]);

  const mounted = React.useRef(false);

  useEffect(() => {
    if (mounted.current) {
      setSendParams(saveData.areaParams);
      setSelected(saveData.areaParams?.books);
      sourceSetSelected(saveData.areaParams?.source);
      setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
    } else {
      mounted.current = true;
    }
  }, [resetToggle]);

  // 預設選取 課綱＆年度＆版本 第一個。
  useEffect(() => {
    if (!curriculumOptions || !yearOptions || !versionOptions) return;
    if (sendParams?.curriculum && sendParams?.year && sendParams?.version) return;
    setSendParams({
      ...sendParams,
      curriculum: curriculumOptions[0]?.value,
      year: yearOptions[0]?.value,
      version: versionOptions[0]?.value,
    });
  }, [curriculumOptions, yearOptions, versionOptions, sendParams.curriculum, sendParams.year, sendParams.version]);

  return (
    isLoading ?
      renderLoadingContent()
      :
      <StyledHomeAreaSelectContent data-testid="HomeAreaSelectContent">
        <UiFlexBox>
          <Box mb={6}>
            <div className="label">課綱</div>
            <UiSelectList>
              <RadioGroup key={'curriculumOptions'} buttonStyle='solid' optionType='button' options={curriculumOptions} value={sendParams?.curriculum} onChange={(e) => { onRadioChange('curriculum', e); }} />
            </UiSelectList>
          </Box>
          <Box mb={6}>
            <div className="label">年度</div>
            <UiSelectList>
              <RadioGroup key={'yearOptions'} buttonStyle='solid' optionType='button' options={yearOptions} value={sendParams?.year} onChange={(e) => { onRadioChange('year', e); onChangeYear('year', e); }} />
            </UiSelectList>
          </Box>
          <Box mb={6}>
            <div className='areaBorder'></div>
          </Box>
          {
            !selectLoading &&
            <>
              {
                versionOptions && versionOptions.length > 0 && identity !== 'SchoolTeacher' &&
                <>
                  <Box mb={6}>
                    <div className="label">版本</div>
                    <UiSelectList>
                      <RadioGroup key={'versionOptions'} buttonStyle='solid' optionType='button' options={versionOptions} value={sendParams?.version} onChange={(e) => { onRadioChange('version', e); }} />
                    </UiSelectList>
                  </Box>
                  <Box mb={6}>
                    <div className='areaBorder'></div>
                  </Box>
                </>
              }

              <Box mb={6}>
                <div className="label">
                  冊次
                  {
                    sendParams?.curriculum && sendParams?.year &&
                    <div >
                      <Checkbox key={'allSelectBooks'} checked={allSelected} onClick={toggleAll} indeterminate={partiallySelected}>全選</Checkbox>
                    </div>
                  }


                </div>
                <UiSelectList>
                  {
                    sendParams?.curriculum && sendParams?.year ?
                      bookOptions.map(item => (
                        <Row className='checkBoxRow' key={item.value}>
                          <Col><Checkbox key={item.value} checked={isSelected(item.value)} onClick={() => toggle(item.value)} >{item.name}</Checkbox></Col>
                        </Row>

                      )) :
                      <div className="stepHint">
                        請先選擇課綱與年度。
                      </div>
                  }

                </UiSelectList>
              </Box>

              <Box mb={6}>
                <div className='areaBorder'></div>
              </Box>

              <Box mb={6}>
                <div className="label">
                  章節
                  {
                    sendParams?.books && sendParams?.books.length > 0 && chapterOptions.length > 0 &&
                    <div>
                      <Checkbox key={'allSelectLesson'} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全選</Checkbox>
                    </div>
                  }

                </div>
                <UiSelectList key={defaultOpenArray && defaultOpenArray.length}>
                  {
                    sendParams?.books && sendParams?.books.length > 0 && chapterOptions.length > 0 ?
                      <TreeCheck
                        key={'chapterOptions'}
                        treeData={chapterOptions}
                        checkedList={treeCheckedList}
                        onChange={onTreeChange}
                        defaultExpandedKeys={defaultOpenArray}
                      />
                      :
                      <div className="stepHint">
                        請先選擇冊次。
                      </div>
                  }

                </UiSelectList>
              </Box>

              <Box mb={6}>
                <div className='areaBorder'></div>
              </Box>

              <Box mb={6}>
                <div className="label">
                  出處
                  {
                    sourceOptions && treeCheckedList && treeCheckedList.length > 0 &&
                    <div >
                      <Checkbox key={'allSelectSource'} checked={sourceAllSelected} onClick={sourceToggleAll} indeterminate={sourcePartiallySelected}>全選</Checkbox>
                    </div>
                  }

                </div>
                <UiSelectList>
                  {
                    sourceOptions && treeCheckedList && treeCheckedList.length > 0 ?
                      sourceOptions && sourceOptions.map(item => (
                        <Row className='checkBoxRow' key={item.value}>
                          <Col><Checkbox key={item.value} checked={sourceIsSelected(item.value)} onClick={() => sourceToggle(item.value)} >{item.name}</Checkbox></Col>
                        </Row>

                      )) :
                      <div className="stepHint">
                        請先選擇章節。
                      </div>
                  }
                </UiSelectList>
              </Box>
            </>
          }
        </UiFlexBox>
      </StyledHomeAreaSelectContent>
  );
};



HomeAreaSelectContent.propTypes = {
  className: PropTypes.string,
  setTableData: PropTypes.func,
  saveData: PropTypes.object,
  memberEduSubject: PropTypes.object,
  getSaveData: PropTypes.func,
  getKeyList: PropTypes.func,
  resetToggle: PropTypes.bool,
  examPaperType: PropTypes.string,
  getSendAreaParams: PropTypes.func,
  identity: PropTypes.string,
};
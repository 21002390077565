import { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import behaviorProvider from 'providers/behaviorProvider';
import { useBoolean } from 'ahooks';
import { Button } from '@oneclass/onedesign';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import classNames from 'classnames';
import {
  Tooltip
} from 'antd';
import { getTime, format } from 'date-fns';
import { FileDownloadSolid, DownloadSolid, FolderSolid, EyeSolid, CopySolid, RocketSolid } from '@onedesign/icon';
import {
  Box,
  Table,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  Pagination,
  sliceTableData,
  Modal,
  openNotificationWithIcon,
  CreateExamPaperParamsProvider,
  EditCopyExamPaperProvider
} from '@oneclass/ui-components';
import * as GA from 'utils/googleAnalytics';
import { useMachine } from '@xstate/react';
import { getPaperPackageInfo, deletePaperPackage, getQueryExamPaper, postExamGetData, postExamEditOne, deleteExamPaper } from 'api/examPaper';
import {
  StyledMyPaperDetailPage,
  StyledBackToList,
  StyledMyPaperDetailContent,
  StyledDetailModal,
  StyledHintModal
} from './SharePaperDetailPage.style';
import { blobDownload } from 'utils/common';
import {
  ChevronLeftSolid,
} from '@onedesign/icon';

export const SharePaperDetailPage = () => {
  const history = useHistory();
  const { redemptionCode } = useParams();
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [modalInfo, setModalInfo] = useState(null);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const { behaviorData, behaviorDataProviderChange } = useContext(behaviorProvider.behaviorProviderContext);
  const [modalVisible, { toggle: setModalToggle, setFalse: setModalFalse }] = useBoolean(false);
  const [infoModalVisible, { toggle: setInfoModalToggle, setFalse: setInfoModalFalse }] = useBoolean(false);
  const oneExamUrl = `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/paper/preview`;
  const { createExamPaperParams, createExamPaperParamsChange } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const { editCopyExamPaperData, editCopyEditExamPaperChange } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const [questionErrorModalOpen, { setTrue: setQuestionErrorModalOpenTrue, setFalse: setQuestionErrorModalOpenFalse }] = useBoolean(false);

  const jumpToExamList = () => {
    setCreateQuizExamModalFalse();
    behaviorDataProviderChange('goToExamList', true);
    history.push('/examPaperList');
  };

  const { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse } = useCreateQuizExamModal(jumpToExamList);

  const [statePaperPackageInfo, sendPaperPackageInfo] = useMachine(
    fetchDataMachine, {
      services: {
        fetchData: async(_context, event) => {
          setIsLoading(true);
          const res = await getPaperPackageInfo(event.payload);
          const { isSuccess, systemCode, message, data } = res;
          setIsLoading(false);

          return {
            isSuccess, systemCode, message,
            paperDetail: data,
          };
        }
      }
    }
  );

  const { paperDetail } = statePaperPackageInfo.context.result || {};

  const getPackageInfo = () => {
    const payload = { redemptionCode: redemptionCode };
    sendPaperPackageInfo(FETCH_DATA_EVENT.FETCH, { payload });
  };

  const backToList = () => {
    behaviorDataProviderChange('goToPackageList', true);
    history.push('/examPaperList');
  };

  const showDetailModal = (data) => {
    if (!data) return;
    setModalInfo(data);
    setInfoModalToggle();
  };

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const goToExamPage = (examUID) => {
    if (!examUID) return;
    window.open(`${oneExamUrl}/${examUID}`);
  };

  // 刪除試卷包
  const [_, sendDeletePaperPackage] = useMachine(
    fetchDataMachine, {
      services: {
        fetchData: async(_context, event) => {
          const res = await deletePaperPackage(event.payload);
          const { isSuccess, systemCode, message } = res;
          if (isSuccess) {
            openNotificationWithIcon('success', '成功刪除測驗');
            setModalFalse();
            behaviorDataProviderChange('goToPackageList', true);
            history.push('/examPaperList');
          } else {
            openNotificationWithIcon('error', message);
          }

          return {
            isSuccess, systemCode, message,
          };
        }
      }
    }
  );

  const goToEditPackage = () => {
    history.push({
      pathname: `/examPaperList/editPagePackage/${redemptionCode}`,
      state: {
        prevPage: 'sharePaperDetail',
      }
    });
  };

  const [statePostExamGetData, sendPostExamGetData] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_, event) => {
        try {
          const res = await postExamGetData(event.payload);
          const { data, isSuccess, systemCode } = res;

          const { result2 } = data;
          const itemData = event.itemData;
          const newData = {
            ...result2,
            edu: result2.searchCondition.eduSubject[0],
            subject: result2.searchCondition.eduSubject.slice(1),
            output: result2.examInfo.outputType,
            eduSubjectName: {
              edu: itemData.eduSubject.substring(0, 2),
              subject: itemData.eduSubject.substring(2),
            }
          };
          createExamPaperParamsChange(
            result2.searchCondition.eduSubject[0],
            result2.searchCondition.eduSubject.slice(1),
            result2.examInfo.outputType,
            {
              edu: itemData.eduSubject.substring(0, 2),
              subject: itemData.eduSubject.substring(2),
            }
          );
          return {
            isSuccess,
            systemCode,
            examPaperData: {
              ...newData,
              paperData: itemData
            },
            actionType: event.action
          };
        } catch (error) {
          setQuestionErrorModalOpenTrue();
          return {
            isSuccess: false
          };
        }
      },
    },
  });

  const {
    examPaperData,
    actionType
  } = statePostExamGetData.context.result || {};

  const copyEditExamPaper = (item, action) => {
    sendPostExamGetData(FETCH_DATA_EVENT.FETCH, {
      payload: {
        examUID: item.paperId,
      },
      action,
      itemData: item
    });
  };

  const redirectCreateExamPaper = (type) => {
    switch (type) {
      case '手動命題':
        return '/manualProposition';
      case '智能命題':
        return '/studentIntelligentProposition';
      case '知識向度命題':
        return '/knowledgeProposition';
      case '無限命題':
        return '/equationDojo';
      case '電腦命題':
      default:
        return '/computerQuestion';
    }
  };

  useEffect(() => {
    if (!redemptionCode) return;
    getPackageInfo();
  }, [redemptionCode]);

  useEffect(() => {
    if (examPaperData) {
      editCopyEditExamPaperChange({
        ...examPaperData,
        ...examPaperData?.examInfo,
        ...examPaperData?.searchCondition,
      }, actionType);
      history.push(redirectCreateExamPaper(examPaperData.paperData.drawUpStyle));
    }
  }, [examPaperData]);

  return (
    <StyledMyPaperDetailPage>
      <StyledBackToList onClick={backToList}>
        <ChevronLeftSolid/>
        <span>返回試卷包列表</span>
      </StyledBackToList>

      <StyledMyPaperDetailContent>
        <div className='header'>
          <div className="title">
            <FolderSolid style={{ fontSize: '24px' }} />
            {paperDetail?.packageName}
          </div>
        </div>
        {
          paperDetail && (
            <div className="lastEditTime">
              上次更新時間：{format(getTime(new Date(paperDetail?.updateTime)), 'yyyy/MM/dd HH:mm')}
            </div>
          )
        }

        <Box>
          <Table className="tableZone">
            <Table.Header>
              <Table.Row>
                <Table.Item flex="1">試卷名稱</Table.Item>
                <Table.Item width="89px" flex="none">
                      學制科目
                </Table.Item>
                <Table.Item width="144px" flex="none">
                      出卷時間
                </Table.Item>
                <Table.Item width="144px" flex="none">
                      共享時間
                </Table.Item>
                <Table.Item width="340px" flex="none" />
              </Table.Row>
            </Table.Header>
            {
              isLoading ? (
                renderLoadingContent()
              ) : (
                <Table.Body bodyMaxHeight={true}>
                  {
                    paperDetail?.papers &&
                    sliceTableData(paperDetail?.papers, pageState.currentPage, pageState.limit).map((item) => (
                      <Table.Row key={item.paperId}>
                        <Table.Item flex="1">
                          {item.paperName}
                        </Table.Item>
                        <Table.Item width="89px" flex="none">
                          {item.eduSubject}
                        </Table.Item>
                        <Table.Item width="144px" flex="none">
                          {format(getTime(new Date(item.createTime)), 'yyyy/MM/dd HH:mm')}
                        </Table.Item>
                        <Table.Item width="144px" flex="none">
                          {format(getTime(new Date(item.shareTime)), 'yyyy/MM/dd HH:mm')}
                        </Table.Item>
                        <Table.Item width="340px" flex="none">
                          <div className="buttonGroup">
                            <Button
                              className={classNames('btn', 'contained', item.outputStyle === '紙本卷類' ? 'disabled' : '')}
                              onClick={() => createQuizExamModalToggle({
                                ...item,
                                name: item.paperName,
                                uid: item.paperId
                              }, 'create')}
                              disabled={item.outputStyle === '紙本卷類'}
                            >
                              <RocketSolid />
                              派速測
                            </Button>


                            <Button
                              className="btn"
                              variant='brand2'
                              onClick={() => {
                                blobDownload(
                                  item.downloadUrl,
                                  item.downloadName,
                                  'GET'
                                );
                              }}
                            >
                              <DownloadSolid />
                            下載
                            </Button>
                            <Button
                              className="btn outlined"
                              onClick={() => showDetailModal(item)}
                            >
                            試卷詳情
                            </Button>
                          </div>
                        </Table.Item>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              )
            }
          </Table>
          <div className="pageBlock">
            <Pagination
              total={paperDetail?.papers?.length || 0}
              defaultCurrent={1}
              current={pageState.currentPage}
              onChange={pageChange}
              defaultPageSize={pageState.limit}
            />
          </div>
        </Box>
      </StyledMyPaperDetailContent>
      <Modal
        visible={modalVisible}
        onCancel={setModalFalse}
        className={'mobileModal'}
        title={
          <div>確定要刪除嗎？</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              variant={'brand1'}
              onClick={() => {
                sendDeletePaperPackage(FETCH_DATA_EVENT.FETCH, {
                  payload: {
                    redemptionCode: redemptionCode
                  }
                });
              }}>
                確定刪除
            </Button>
          </div>
        }
      >
        <div>若刪除本試卷，將無法與其他老師共享試卷資源。</div>
      </Modal>
      {
        modalInfo && (
          <StyledDetailModal>
            <Modal
              id={'detailModal'}
              visible={infoModalVisible}
              onCancel={setInfoModalFalse}
              title={
                <div>{modalInfo.paperName}</div>
              }
              width={'860px'}
            >
              <div className="modalContent">
                <div className="modalRow">
                  <Box style={{ marginBottom: '4px' }}>
                    <div className="modalInnerTitle">試卷資料</div>
                    <div className="modalInnerContent">
                      <div className="modalInfoRow"><div>學制科目</div><span>{modalInfo.eduSubject}</span></div>
                      <div className="modalInfoRow"><div>命題方式</div><span>{modalInfo.drawUpStyle}</span></div>
                      <div className="modalInfoRow"><div>試卷類型</div><span>{modalInfo.outputStyle}</span></div>
                      <div className="modalInfoRow"><div>出卷時間</div><span>{format(getTime(new Date(modalInfo.createTime)), 'yyyy/MM/dd HH:mm')}</span></div>
                      <div className="modalInfoRow"><div>卷號</div><span>{modalInfo.paperId}</span></div>
                    </div>
                  </Box>
                  <Box>
                    <div className="modalInnerTitle">試卷內容</div>
                    <div className="modalInnerContent">
                      <div className="modalInfoRow">
                        <div>題數</div>
                        <span>
                          {modalInfo.attributes.questionAmount}
                        </span>
                      </div>
                    </div>
                  </Box>
                </div>
                <div className="modalRow controlBox">
                  <div className="modalInnerTitle">操作</div>
                  <div className="modalInnerContent">
                    <Box>
                      <Button
                        className="saveButton"
                        onClick={() => {
                          copyEditExamPaper(modalInfo, 'Copy');
                        }}
                      >
                        <CopySolid /> 另存副本
                      </Button>
                      <div className='copyEditBtnBox'>
                        <Button
                          variant="brand2"
                          onClick={() => {
                            goToExamPage(modalInfo.paperId);
                            GA.previewPaper();
                          }}
                        >
                          <EyeSolid /> 預覽試卷
                        </Button>
                        {
                          modalInfo?.outputStyle !== '紙本卷類' && (
                            <Button
                              variant="brand2"
                              onClick={() => createQuizExamModalToggle({
                                ...modalInfo,
                                name: modalInfo.paperName,
                                uid: modalInfo.paperId
                              }, 'create')}
                            >
                              <RocketSolid /> 派速測
                            </Button>
                          )
                        }
                        <Button
                          variant={(modalInfo.downloadName && modalInfo.downloadUrl) ? 'brand2' : 'ui02'}
                          disabled={!(modalInfo.downloadName && modalInfo.downloadUrl)}
                          onClick=
                            {
                              () => {
                                blobDownload(
                                  modalInfo.downloadUrl,
                                  modalInfo.downloadName,
                                  'GET'
                                );
                              }
                            }
                        >
                          <FileDownloadSolid />
                          下載試卷
                        </Button>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </Modal>
          </StyledDetailModal>
        )
      }
      <StyledHintModal
        visible={questionErrorModalOpen}
        title='此試卷無法使用'
        onCancel={setQuestionErrorModalOpenFalse}
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              className={'confirmBtn'}
              onClick={setQuestionErrorModalOpenFalse}
            >
              確定
            </Button>
          </div>
        }>
        <span className='hintModalContent'>本範圍題目有異動更新無法再次編輯、複製，請嘗試重新出卷。</span>
      </StyledHintModal>
      {renderCreateQuizExamModal()}
    </StyledMyPaperDetailPage>
  );
};
import React from 'react';
import { StyledQuestionLimitModal } from './QuestionLimitModal.style';
import { PROCESS_PARAMS, OUTPUT_TYPE_NAME } from 'constants/index';
import { Button } from '@oneclass/onedesign';

import { Modal } from '@oneclass/ui-components';

export const QuestionLimitModal = ({ className, visible, onCancel, currentQuesNum, processType, questionNumLimit }) => {
  const questionLimitText = PROCESS_PARAMS[processType]?.quesLimitText;
  return (
    <StyledQuestionLimitModal>
      <Modal
        className={`${className} questionCountModal mobileModal`}
        data-testid="QuestionLimitModal"
        visible={visible}
        onCancel={onCancel}
        title={
          <div>題數超過出題上限，請減少題數</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant={'brand1'} onClick={() => { onCancel();}}>確定</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>
            {OUTPUT_TYPE_NAME[processType]}出{questionLimitText}上限為{questionNumLimit}{questionLimitText}
            ，您當前已選擇{currentQuesNum}{questionLimitText}，請減少{questionLimitText}數。
          </div>
        </div>
      </Modal>
    </StyledQuestionLimitModal>
  );
};
import axios from 'axios';

const envConfig = require('firebaseConfig.json');

const { ONE_PAPER_API_KEY } = envConfig[process.env.REACT_APP_NODE_ENV];

const axiosInstance = axios.create();


const useSheet = () => {
  const getSheet = async (sheetId, sheetName) => {
    try {
      const res = await axiosInstance.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${ONE_PAPER_API_KEY}`,
      );
      return res;
    } catch (error) {
      throw Error(error);
    }
  };

  return { getSheet };
};

export default useSheet;

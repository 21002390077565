/**
 * variable & Object key 大寫
 */

export const DIFFICULTY = ['BEGIN', 'INTERMEDIATE', 'EXPERT', 'BASIC', 'ADVANCED'];

export const DIFFICULTY_TAG_DISPLAY = ['BEGIN', 'INTERMEDIATE', 'EXPERT'];

export const LANGUAGE_LIST = ['EW', 'CN', 'PC', 'EN', 'CH'];

export const OPTION_ERROR_MSG = {
  schoolName: '請輸入學校名稱',
  paperName: '請輸入試卷名稱',
  teacherSign: '請輸入教師名稱',
  room: '請輸入班級',
  grade: '請輸入年級'
};

export const ENVIRONMENT_NAME = {
  DEV: 'dev',
  UAT: 'uat',
  RELEASE: 'release',
};

export const OUTPUT_TYPE_NAME = {
  Files: '紙本卷類',
  Quick: '速測',
  Online: '線上測驗',
};

export const EXAM_TYPE = {
  Others: '其他',
  General: '平時考',
  Quiz: '隨堂測驗',
  Exam: '段考',
};

// 各版本代號對應之名稱。
export const VERSION_NAME = {
  B: '部編',
  CW: '全華',
  G: '綜合',
  H: '適翰',
  K: '適康',
  KX: '康熹',
  L: '龍騰',
  N: '南一',
  S: '三民',
  T: '泰宇',
  A: '教務',
  E: '何嘉仁',
};

// 各個 命題流程 相關參數設定
export const PROCESS_PARAMS = {
  // 線上測驗
  Online: {
    questionLimit: 200, // 答數 限制
    quesLimitText: '答' // 限制 文字
  },
  // 紙本卷類
  Files: {
    questionLimit: 200, // 答數 限制
    quesLimitText: '答' // 限制 文字
  },
  // 速測
  Quick: {
    questionLimit: 200, // 答數 限制
    quesLimitText: '答' // 限制 文字
  },
};

// 不需顯示序數（第 x 課）的章節
export const CHAPTERS_DONT_SHOW_ORDINAL_NUMBER = ['語文天地'];

/**
     * MA: 數學
     * CN: 國文
     * NA: 自然
     * SO: 社會
     * PE : 健康與體育
     * BI: 生物
     * PY: 理化
     * GE: 地理
     * HI: 歷史
     * CT/CS: 公民
     * PH: 物理
     * CH: 化學
     * EA: 地球科學
     * CO: 綜合活動
     *
     */

export const ELEMENTARY_MA = 'EMA';
export const ELEMENTARY_CH = 'ECH';
export const ELEMENTARY_NA = 'ENA';
export const ELEMENTARY_SO = 'ESO';
export const ELEMENTARY_PE = 'EPE';
export const ELEMENTARY_CN = 'ECN';
export const ELEMENTARY_EN = 'EEN';
export const ELEMENTARY_LI = 'ELI';

export const ELEMENTARY_MA_99 = 'E-MA';
export const ELEMENTARY_CH_99 = 'E-CH';
export const ELEMENTARY_NA_99 = 'E-NA';
export const ELEMENTARY_SO_99 = 'E-SO';
export const ELEMENTARY_PE_99 = 'E-PE';
export const ELEMENTARY_CN_99 = 'E-CN';
export const ELEMENTARY_EN_99 = 'E-EN';
export const ELEMENTARY_LI_99 = 'E-LI';

export const JUNIOR_CH = 'JPC';
export const JUNIOR_CN = 'JCN';
export const JUNIOR_EN = 'JEN';
export const JUNIOR_MA = 'JMA';
export const JUNIOR_NA = 'JNA';
export const JUNIOR_BI = 'JBI';
export const JUNIOR_PY = 'JPY';
export const JUNIOR_GE = 'JGE';
export const JUNIOR_HI = 'JHI';
export const JUNIOR_CT = 'JCT';
export const JUNIOR_CO = 'JCO';
export const JUNIOR_EA = 'JEA';
export const JUNIOR_SO = 'JSO';
export const JUNIOR_PE = 'JPE';
export const JUNIOR_TC = 'JTC';

export const JUNIOR_CH_99 = 'J-PC';
export const JUNIOR_CN_99 = 'J-CN';
export const JUNIOR_EN_99 = 'J-EN';
export const JUNIOR_MA_99 = 'J-MA';
export const JUNIOR_NA_99 = 'J-NA';
export const JUNIOR_BI_99 = 'J-BI';
export const JUNIOR_PY_99 = 'J-PY';
export const JUNIOR_GE_99 = 'J-GE';
export const JUNIOR_HI_99 = 'J-HI';
export const JUNIOR_CT_99 = 'J-CT';
export const JUNIOR_CO_99 = 'J-CO';
export const JUNIOR_EA_99 = 'J-EA';
export const JUNIOR_SO_99 = 'J-SO';
export const JUNIOR_PE_99 = 'J-PE';
export const JUNIOR_TC_99 = 'J-TC';

export const SENIOR_PC = 'HPC';
export const SENIOR_CN = 'HCN';
export const SENIOR_EW = 'HEW';
export const SENIOR_MA = 'HMA';
export const SENIOR_BI = 'HBI';
export const SENIOR_PH = 'HPH';
export const SENIOR_CH = 'HCH';
export const SENIOR_EA = 'HEA';
export const SENIOR_GE = 'HGE';
export const SENIOR_HI = 'HHI';
export const SENIOR_CS = 'HCS';
export const SENIOR_CE = 'HCE';

export const SENIOR_PC_99 = 'H-PC';
export const SENIOR_CN_99 = 'H-CN';
export const SENIOR_EW_99 = 'H-EW';
export const SENIOR_MA_99 = 'H-MA';
export const SENIOR_BI_99 = 'H-BI';
export const SENIOR_PH_99 = 'H-PH';
export const SENIOR_CH_99 = 'H-CH';
export const SENIOR_EA_99 = 'H-EA';
export const SENIOR_GE_99 = 'H-GE';
export const SENIOR_HI_99 = 'H-HI';
export const SENIOR_CS_99 = 'H-CS';
export const SENIOR_CE_99 = 'H-CE';

export const ALL_GRADE = [
  ELEMENTARY_MA,
  ELEMENTARY_CH,
  ELEMENTARY_NA,
  ELEMENTARY_SO,
  ELEMENTARY_PE,
  ELEMENTARY_CN,
  ELEMENTARY_EN,
  ELEMENTARY_LI,

  ELEMENTARY_MA_99,
  ELEMENTARY_CH_99,
  ELEMENTARY_NA_99,
  ELEMENTARY_SO_99,
  ELEMENTARY_PE_99,
  ELEMENTARY_CN_99,
  ELEMENTARY_EN_99,
  ELEMENTARY_LI_99,

  JUNIOR_CH,
  JUNIOR_CN,
  JUNIOR_EN,
  JUNIOR_MA,
  JUNIOR_NA,
  JUNIOR_BI,
  JUNIOR_PY,
  JUNIOR_GE,
  JUNIOR_HI,
  JUNIOR_CT,
  JUNIOR_CO,
  JUNIOR_EA,
  JUNIOR_SO,
  JUNIOR_PE,
  JUNIOR_TC,

  JUNIOR_CH_99,
  JUNIOR_CN_99,
  JUNIOR_EN_99,
  JUNIOR_MA_99,
  JUNIOR_NA_99,
  JUNIOR_BI_99,
  JUNIOR_PY_99,
  JUNIOR_GE_99,
  JUNIOR_HI_99,
  JUNIOR_CT_99,
  JUNIOR_CO_99,
  JUNIOR_EA_99,
  JUNIOR_SO_99,
  JUNIOR_PE_99,
  JUNIOR_TC_99,

  SENIOR_PC,
  SENIOR_CN,
  SENIOR_EW,
  SENIOR_MA,
  SENIOR_BI,
  SENIOR_PH,
  SENIOR_CH,
  SENIOR_EA,
  SENIOR_GE,
  SENIOR_HI,
  SENIOR_CS,
  SENIOR_CE,

  SENIOR_PC_99,
  SENIOR_CN_99,
  SENIOR_EW_99,
  SENIOR_MA_99,
  SENIOR_BI_99,
  SENIOR_PH_99,
  SENIOR_CH_99,
  SENIOR_EA_99,
  SENIOR_GE_99,
  SENIOR_HI_99,
  SENIOR_CS_99,
  SENIOR_CE_99
];

export const SESSION_STORAGE_KEY = {
  OnePaper_NewTest: 'OnePaper_NewTest'
};

// 英聽可用學期
const LISTEN_SEMESTERS = {
  First_Semester: '第一學期',
  Second_Semester: '第二學期',
  Third_Semester: '第三學期',
  Fourth_Semester: '第四學期'
};
export const SEMESTER_OPTIONS = Object.entries(LISTEN_SEMESTERS).map(([value, name]) => ({ value, name }));

// 英聽可用年級
const LISTEN_GRADES = {
  '7': '國中一年級',
  '8': '國中二年級',
  '9': '國中三年級',
};
export const GRADE_OPTIONS = Object.entries(LISTEN_GRADES).map(([value, name]) => ({ value, name }));

// 英聽可用考試範圍
const LISTEN_EXAM_CATEGORY = {
  Midterm: '期中考',
  Final: '期末考',
  FirstStageExam: '第一次段考',
  SecondStageExam: '第二次段考',
  ThirdStageExam: '第三次段考',
  FourthStageExam: '第四次段考',
  FirstRegularExam: '第一次定期評量',
  SecondRegularExam: '第二次定期評量',
  ThirdRegularExam: '第三次定期評量',
  FourthRegularExam: '第四次定期評量',
  PreClassQuiz: '課前隨堂測驗',
  UsualExam: '平時考',
  RandomExam: '抽考',
  MakeUpExam: '補考',
  FirstMockExam: '第一次模擬考',
  SecondMockExam: '第二次模擬考',
  ThirdMockExam: '第三次模擬考',
  FourthMockExam: '第四次模擬考',
  FifthMockExam: '第五次模擬考',
  SixthMockExam: '第六次模擬考',
  FirstMockMockExam: '第一次模模考',
  SecondMockMockExam: '第二次模模考',
  ThirdMockMockExam: '第三次模模考',
  FourthMockMockExam: '第四次模模考',
  FifthMockMockExam: '第五次模模考',
  SixthMockMockExam: '第六次模模考',
  FreshmanExam: '新生入學測驗',
  EndTermExam: '期末會考',
  CompetitiveExam: '競試考',
  FirstCompetitiveExam: '第一次競試考',
  SecondCompetitiveExam: '第二次競試考',
  ThirdCompetitiveExam: '第三次競試考',
};
export const EXAM_CATEGORY_OPTIONS = Object.entries(LISTEN_EXAM_CATEGORY).map(([value, name]) => ({ value, name }));

// 英聽音檔輸出型式
const LISTEN_OUTPUT_AUDIO_FORMAT = {
  OneAudioPerExam: '整份試卷一支音檔',
  OneAudioPerMajorSection: '每大題一支音檔',
  OneAudioPerMinorSection: '每小題一支音檔',
};
export const FORMAT_OPTIONS = Object.entries(LISTEN_OUTPUT_AUDIO_FORMAT).map(([value, name]) => ({ value, name }));

const LISTEN_RECITATION_INTERVAL = {
  // 每大題間隔秒數
  majorSectionInterval: Array.from({ length: 10 }, (_, i) => i + 1),
  // 每小題間隔秒數
  minorSectionInterval: Array.from({ length: 11 }, (_, i) => i),
  // 每小題重複次數
  repetitionTimesPerQuestion: Array.from({ length: 3 }, (_, i) => i + 1),
  // 每小題每次間隔秒數
  repetitionInterval: Array.from({ length: 11 }, (_, i) => i),
};
export const RECITATION_INTERVAL_OPTIONS = Object.fromEntries(Object.entries(
  LISTEN_RECITATION_INTERVAL).map(([key, value]) => [
  key,
  value.map((n) => ({ value: n, name: n })),
]));

// 英聽速率
export const LISTEN_AUDIO_RATE = [
  {
    value: 'Slow_75',
    name: '慢速：速率75%',
    src: 'https://firebasestorage.googleapis.com/v0/b/onepaper-318201.appspot.com/o/Audio%2F%E7%A4%BA%E7%AF%84%E9%9F%B3%E6%AA%94%2F%E7%A4%BA%E7%AF%84%E9%9F%B3%E6%AA%94_%E6%85%A2_75%25%E9%80%9F%E5%BA%A6.mp3?alt=media&token=8e394bc1-e6b5-4520-adba-37e6f735e8d5'
  },
  {
    value: 'Normal_100',
    name: '正常：速率100%',
    src: 'https://firebasestorage.googleapis.com/v0/b/onepaper-318201.appspot.com/o/Audio%2F%E7%A4%BA%E7%AF%84%E9%9F%B3%E6%AA%94%2F%E7%A4%BA%E7%AF%84%E9%9F%B3%E6%AA%94_%E6%99%AE_100%25%E9%80%9F%E5%BA%A6.mp3?alt=media&token=245392d6-52ca-43ea-bdbf-f27edc29fd98'
  },
  {
    value: 'Fast_125',
    name: '快速：速率125%',
    src: 'https://firebasestorage.googleapis.com/v0/b/onepaper-318201.appspot.com/o/Audio%2F%E7%A4%BA%E7%AF%84%E9%9F%B3%E6%AA%94%2F%E7%A4%BA%E7%AF%84%E9%9F%B3%E6%AA%94_%E5%BF%AB_125%25%E9%80%9F%E5%BA%A6.mp3?alt=media&token=14ff1411-c806-4891-89a2-bb89ac1fd771'
  },
];

export const LISTEN_DEFAULT_SETTINGS = {
  audioTitle: {},
  outputAudioFormat: FORMAT_OPTIONS[0].value,
  outputAudioRate: LISTEN_AUDIO_RATE[1].value,
  recitation: {
    majorSectionInterval: 4,
    minorSectionInterval: 4,
    needExamEndAudio: true,
    needExamStartAudio: true,
    repetitionInterval: 3,
    repetitionTimesPerQuestion: 1
  }
};

export const HISTORIC_EXAM_ID = 'B97';

export const PAGE_SIZE_OPTIONS = [100, 50, 20, 10];

// 合科部分
export const multiSubjectMap = {
  JSO: ['JGE', 'JHI', 'JCT'],
  JNA: ['JBI', 'JPY', 'JEA']
};

export const subjectMap = {
  MA: '數學',
  CN: '國文',
  NA: '自然',
  SO: '社會',
  PE: '健康與體育',
  BI: '生物',
  PY: '理化',
  GE: '地理',
  HI: '歷史',
  CT: '公民',
  CS: '公民',
  PH: '物理',
  CH: '化學',
  EA: '地球科學',
  CO: '綜合活動',
};
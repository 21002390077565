// eslint-disable-next-line react/prop-types
import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, PickListDropdown, Input, DifferentTag, Switch, diffStr, Drawer } from '@oneclass/ui-components';
import { RadioGroup } from '@oneclass/onedesign';
import { Radio, Tooltip, DatePicker } from 'antd';
import { QuestionCircleSolid, FilterSolid, ChevronDownOutline, filter } from '@onedesign/icon';
import update from 'immutability-helper';
import { DIFFICULTY, DIFFICULTY_TAG_DISPLAY } from 'constants/index';
import { StyledHomeQuesTypeSelectContent } from './HomeQuesTypeSelectContent.style';
import { Button } from '@oneclass/onedesign';
import { useBoolean } from 'ahooks';
import classNames from 'classnames';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { shuffleArray } from 'utils/common';
import * as GA from 'utils/googleAnalytics';
import QuestionTypeName from 'components/QuestionTypeName';

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
  difficulty: {
    BEGIN: '',
    INTERMEDIATE: '',
    EXPERT: '',
  }
};
const formateTableData = (data) => {
  if (!data) return null;
  let nextData = {};
  Object.entries(data).forEach(([key, value], index) => {
    nextData = {
      ...nextData,
      [key]: {
        ...value,
        inputValue: defaultCountInputValue
      }
    };
  });
  return nextData;
};
/** 題型選擇 **/
export const HomeQuesTypeSelectContent = ({
  className,
  resetToggle,
  examPaperInfo,
  totalQuesTypeData,
  getKeyList,
  keyList,
  globalExamPaperData,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  filterPaperUID,
  renderFilterQuestionModal,
  filterQuestionModalToggle,
  setFilterQuestionModalFalse,
  isChangeFilter,
  setIsChangeFilter,
  tableData,
  setTableData,
}) => {
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [collocationQues, setCollocationQues] = useState('quesAndAns');
  const [canCollocation, setCanCollocation] = useState({
    question: false,
    answer: false,
    difficulty: false
  });
  const [data, setData] = useState([]);
  const [tableQuestions, setTableQuestions] = useState({});
  const [difficultyDistributionArea, setDifficultyDistributionArea] = useState([20, 50, 30]);
  const env = process.env.REACT_APP_NODE_ENV;

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      GA.dragQuestionTypeOrderSelect();
      const dragCard = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [data],
  );

  const onCollocationQuesChange = (e) => {
    setCollocationQues(e.target.value);
  };

  const onSwitchChange = (type, checked) => {
    setCanCollocation({
      ...canCollocation,
      [type]: checked
    });
  };

  const goTop = () => {
    const main = document.getElementById('mainLayout');
    main.scrollTop = 0;
  };

  const tableInputChange = (quesType, inputKey, e, maxcount) => {
    let nextData = { ...tableData };
    Object.keys(tableData).forEach(key => {
      nextData[key].inputValue = {
        ...nextData[key].inputValue,
      };
    });
    const val = parseInt(e?.target?.value) || 0;
    const value = val > maxcount ? maxcount : val;
    const pickUpQuestions = inputKey === 'answer' ? distributeByAnswerAmount(value, quesType) : distributeByQuestionCount(value, quesType);

    let answerAmount = 0;
    let quesAmount = 0;
    Object.values(pickUpQuestions).forEach(questions => {
      questions.forEach(question => {
        quesAmount++;
        answerAmount += question.answerAmount;
      });
    });
    let allScore = 0;
    if (tableData[quesType].inputValue.score.perQues) {
      allScore = (quesAmount * tableData[quesType].inputValue.score.perQues).toFixed(1) || 0;
    }
    if (tableData[quesType].inputValue.score.perAns) {
      allScore = (answerAmount * tableData[quesType].inputValue.score.perAns).toFixed(1) || 0;
    }
    let nextScore = {
      ...tableData[quesType].inputValue.score,
      allScore
    };
    nextData = {
      ...nextData,
      [quesType]: {
        ...tableData[quesType],
        pickUpQuestions,
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          },
          answer: answerAmount,
          question: quesAmount,
          difficulty: {
            [DIFFICULTY[0]]: pickUpQuestions[DIFFICULTY[0]].length,
            [DIFFICULTY[1]]: pickUpQuestions[DIFFICULTY[1]].length,
            [DIFFICULTY[2]]: pickUpQuestions[DIFFICULTY[2]].length,
          }
        }
      }
    };

    if (quesAmount === 0) delete nextData[quesType].pickUpQuestions;
    setTableData({
      ...nextData,
    });
  };
  const diffInputChange = (quesType, inputKey, e, maxcount) => {
    const value = e.target.value > maxcount ? maxcount : e.target.value;
    const pickUpQuestions = distributeByDifficulty(quesType, inputKey, value);
    let nextData = {};

    let answerAmount = 0;
    let quesAmount = 0;
    Object.values(pickUpQuestions).forEach(questions => {
      questions.forEach(question => {
        quesAmount++;
        answerAmount += question.answerAmount;
      });
    });

    let allScore = 0;
    if (tableData[quesType].inputValue.score.perQues) {
      allScore = (quesAmount * tableData[quesType].inputValue.score.perQues).toFixed(1) || 0;
    }
    if (tableData[quesType].inputValue.score.perAns) {
      allScore = (answerAmount * tableData[quesType].inputValue.score.perAns).toFixed(1) || 0;
    }
    let nextScore = {
      ...tableData[quesType].inputValue.score,
      allScore
    };
    nextData = {
      [quesType]: {
        ...tableData[quesType],
        pickUpQuestions,
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          },
          answer: answerAmount,
          question: quesAmount,
          difficulty: {
            ...tableData[quesType].inputValue.difficulty,
            [inputKey]: value,
          }
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  const scoreInputChange = (quesType, inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e?.target?.value;

    let allScore = 0;

    if (inputKey === 'perQues') {
      allScore = tableData[quesType].inputValue.question ? (value * tableData[quesType].inputValue.question).toFixed(1) : 0;
    } else {
      allScore = tableData[quesType].inputValue.answer ? (value * tableData[quesType].inputValue.answer).toFixed(1) : 0;
    }

    let nextScore = {
      ...defaultCountInputValue.score,
      [inputKey]: value,
      allScore
    };
    let nextData = {};
    nextData = {
      [quesType]: {
        ...tableData[quesType],
        inputValue: {
          ...tableData[quesType].inputValue,
          score: {
            ...nextScore
          },
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  // setupDrawer Question/Answer  Input Change時變化
  const setupQuesInputChange = (inputKey, e) => {
    const quesType = setupQuestionType;
    const maxcount = setupDrawerContent[inputKey].maxcount;
    const value = e?.target?.value > maxcount ? maxcount : e?.target?.value;
    let nextData = {};
    const pickUpQuestions = inputKey === 'answer' ? distributeByAnswerAmount(value, quesType) : distributeByQuestionCount(value, quesType);

    let answerAmount = 0;
    let quesAmount = 0;
    Object.values(pickUpQuestions).forEach(questions => {
      questions.forEach(question => {
        quesAmount++;
        answerAmount += question.answerAmount;
      });
    });
    let allScore = 0;
    if (setupDrawerContent.perQues) {
      allScore = (quesAmount * setupDrawerContent.perQues).toFixed(1) || 0;
    }
    if (setupDrawerContent.perAns) {
      allScore = (answerAmount * setupDrawerContent.perAns).toFixed(1) || 0;
    }

    nextData = {
      ...setupDrawerContent,
      question: {
        ...setupDrawerContent.question,
        question: quesAmount || '',
      },
      answer: {
        ...setupDrawerContent.answer,
        answer: answerAmount || '',
      },
      totalScore: allScore,
    };
    if (quesAmount === 0) {
      setSetupDrawerPickUpQuestions([]);
    } else {
      setSetupDrawerPickUpQuestions(pickUpQuestions);
    }

    setSetupDrawerContent(nextData);
  };

  const setupDrawerScoreInputChange = (inputKey, e) => {
    if (!new RegExp(/^(\d{0,3})(\.\d{0,1})?$/g).test(e.target.value)) return;
    const value = e?.target?.value;
    let allScore = 0;
    let perQuesValue = '';
    let perAnsValue = '';
    if (inputKey === 'perQues') {
      allScore = setupDrawerContent.question.question ? (value * setupDrawerContent.question.question).toFixed(1) : 0;
      perQuesValue = value;
    } else {
      allScore = setupDrawerContent.answer.answer ? (value * setupDrawerContent.answer.answer).toFixed(1) : 0;
      perAnsValue = value;
    }

    let nextData = {};
    nextData = {
      ...setupDrawerContent,
      perQues: perQuesValue,
      perAns: perAnsValue,
      totalScore: allScore,
    };
    setSetupDrawerContent(nextData);
  };

  const setupDrawerDoneChange = () => {

    let nextScore = {
      ...defaultCountInputValue.score,
      perQues: setupDrawerContent.perQues,
      perAns: setupDrawerContent.perAns,
      allScore: setupDrawerContent.totalScore
    };
    let nextData = {};
    nextData = {
      [setupQuestionType]: {
        ...tableData[setupQuestionType],
        pickUpQuestions: setupDrawerPickUpQuestions,
        inputValue: {
          ...tableData[setupQuestionType].inputValue,
          question: setupDrawerContent.question.question,
          answer: setupDrawerContent.answer.answer,
          score: {
            ...nextScore
          }
        }
      }
    };
    setTableData({
      ...tableData,
      ...nextData
    });
  };

  const getRandomDifficulty = (difficultyDistribution) => {
    const totalDistribution = difficultyDistribution.reduce((acc, v) => acc + v, 0);
    let dice = Math.floor(Math.random() * totalDistribution);
    let difficulty = DIFFICULTY[0];
    for (let i = 0; i < difficultyDistribution.length; ++i) {
      dice -= difficultyDistribution[i];
      if (dice <= 0) {
        difficulty = DIFFICULTY[i];
        break;
      }
    }
    return difficulty;
  };

  const distributeByQuestionCount = (questionCount, quesType) => {
    if (!tableQuestions[quesType]) return;
    const calData = tableQuestions[quesType] || {};
    let count = 0;
    let pickUpQuestions = {
      [DIFFICULTY[0]]: [],
      [DIFFICULTY[1]]: [],
      [DIFFICULTY[2]]: [],
    };
    let remainingQuestions = {
      [DIFFICULTY[0]]: [].concat(calData[DIFFICULTY[0]] || []),
      [DIFFICULTY[1]]: [].concat(calData[DIFFICULTY[1]] || []),
      [DIFFICULTY[2]]: [].concat(calData[DIFFICULTY[2]] || []),
    };
    let stop = 0;

    let difficultyDistribution = [].concat(difficultyDistributionArea);
    while (count < questionCount && stop < 100000) {
      stop = stop + 1;
      const difficulty = getRandomDifficulty(difficultyDistribution);
      if (remainingQuestions[difficulty].length > 0) {
        const pickUp = Math.floor(Math.random() * remainingQuestions[difficulty].length);
        const [question] = remainingQuestions[difficulty].splice(pickUp, 1);
        pickUpQuestions[difficulty].push(question);
        ++count;
      } else {
        if (difficulty === DIFFICULTY[0]) {
          difficultyDistribution[0] = 0;
        } else if (difficulty === DIFFICULTY[1]) {
          difficultyDistribution[1] = 0;
        } else if (difficulty === DIFFICULTY[2]) {
          difficultyDistribution[2] = 0;
        }
      }
    }

    return pickUpQuestions;
  };

  const distributeByAnswerAmount = (answerCount, quesType) => {
    if (!tableQuestions[quesType]) return;
    const calData = tableQuestions[quesType] || {};
    let preservedQuestions = {};
    shuffleArray(calData.questions).forEach(question => {//先抓各題數的一題
      if (!preservedQuestions[question.answerAmount]) {
        preservedQuestions[question.answerAmount] = question.uid;
      }
    });
    const questionMap = calData.questions.reduce((acc, question) => {
      acc[question.uid] = question;
      return acc;
    }, {});

    let preservedQuestionList = Object.values(preservedQuestions);
    let DEFAULT_DIFFICULTY_DISTRIBUTION = [20, 50, 30];

    let stop = 0;
    let count = 0;
    let pickUpQuestions = {
      [DIFFICULTY[0]]: [],
      [DIFFICULTY[1]]: [],
      [DIFFICULTY[2]]: [],
    };
    let remainingQuestions = {
      [DIFFICULTY[0]]: [].concat(calData[DIFFICULTY[0]] ? calData[DIFFICULTY[0]].filter(v => !preservedQuestionList.includes(v.uid)) : []),
      [DIFFICULTY[1]]: [].concat(calData[DIFFICULTY[1]] ? calData[DIFFICULTY[1]].filter(v => !preservedQuestionList.includes(v.uid)) : []),
      [DIFFICULTY[2]]: [].concat(calData[DIFFICULTY[2]] ? calData[DIFFICULTY[2]].filter(v => !preservedQuestionList.includes(v.uid)) : []),
    };
    let difficultyDistribution = [].concat(DEFAULT_DIFFICULTY_DISTRIBUTION);

    while (count < answerCount && stop < 100000) {
      stop = stop + 1;
      let diff = answerCount - count;
      // 當題數抓到剛好時 跳出迴圈;
      if (diff === 0) break;

      // 保留題目之題數陣列
      let answerArray = Object.keys(preservedQuestions);
      // 保留總題數
      let lastTotal = answerArray.reduce( function(init, currentValue, currentIndex) {
        return init + parseInt(currentValue);
      }, 0);

      // 當題目都選完時，開始 使用一開始保留的 各題數之題目。
      if (diff <= lastTotal) {
        const pickQuestion = (number) => {
          if (!number) return;
          const questionId = preservedQuestions[number];
          const question = questionMap[questionId];
          if (!questionId) return;
          if (!pickUpQuestions[question.difficulty]) {
            if (question.difficulty === 'BASIC' || question.difficulty === 'ADVANCED') {
              pickUpQuestions.INTERMEDIATE = pickUpQuestions.INTERMEDIATE ? pickUpQuestions.INTERMEDIATE.concat(question) : [].concat(question);
            } else {
              pickUpQuestions[question.difficulty] = [].concat(question);
            }
          } else {
            pickUpQuestions[question.difficulty].push(question);
          }
          // 選完後 刪除
          delete preservedQuestions[number];
        };

        // 若差題數 剛好存有的題目中有相符的直接抓取使用，結束選題
        if (preservedQuestions[diff]) {
          pickQuestion(diff);
          break; //結束
        } else {
          // 若差題數，保存題目中沒有剛好的，從能選取的 最大的題數開始抓取
          const canUse = answerArray.filter((item) => {
            return item <= diff;
          }).sort((a, b) => {
            return b - a;
          });

          // 沒有題目符合需求時 結束選題
          if (!canUse | canUse.length === 0) break;

          // 抓取最大題數題目使用
          pickQuestion(canUse[0]);
          count += parseInt(canUse[0]);
        }
      }

      // if (diff <= Math.max(...Object.keys(preservedQuestions))) {
      //   const questionId = preservedQuestions[diff];
      //   const question = questionMap[questionId];
      //   if (!questionId) break;
      //   if (!pickUpQuestions[question.difficulty]) {
      //     if (question.difficulty === 'BASIC' || question.difficulty === 'ADVANCED') {
      //       pickUpQuestions.INTERMEDIATE = pickUpQuestions.INTERMEDIATE ? pickUpQuestions.INTERMEDIATE.concat(question) : [].concat(question);
      //     } else {
      //       pickUpQuestions[question.difficulty] = [].concat(question);
      //     }
      //   } else {
      //     pickUpQuestions[question.difficulty].push(question);
      //   }

      //   break;
      // }

      const difficulty = getRandomDifficulty(difficultyDistribution);
      if (remainingQuestions[difficulty].length > 0) {
        const pickUp = Math.floor(Math.random() * remainingQuestions[difficulty].length);
        const [question] = remainingQuestions[difficulty].splice(pickUp, 1);
        pickUpQuestions[difficulty].push(question);
        count += question.answerAmount;
      } else {
        if (difficulty === DIFFICULTY[0]) {
          difficultyDistribution[0] = 0;
        } else if (difficulty === DIFFICULTY[1]) {
          difficultyDistribution[1] = 0;
        } else if (difficulty === DIFFICULTY[2]) {
          difficultyDistribution[2] = 0;
        }
      }
    }

    return pickUpQuestions;
  };

  const distributeByDifficulty = (quesType, inputKey, inputValue) => {
    if (!tableQuestions[quesType]) return;
    const calData = tableQuestions[quesType] || {};
    let difficultyCount = {};
    Object.entries(tableData[quesType]?.inputValue.difficulty).forEach(([key, value]) => {
      difficultyCount = {
        ...difficultyCount,
        [key]: key === inputKey ? inputValue : value
      };
    });
    let allCount = 0;
    Object.entries(difficultyCount).forEach(([key, value]) => {
      allCount = allCount + Number(value);
    });
    let newDifficultyDistribution = [];
    newDifficultyDistribution = Object.entries(difficultyCount).map(([key, item]) => {
      return (
        item ? Math.floor((Number(item) / allCount) * 100) : 0
      );
    });
    let count = 0;
    let pickUpQuestions = {
      [DIFFICULTY[0]]: [],
      [DIFFICULTY[1]]: [],
      [DIFFICULTY[2]]: [],
    };
    let remainingQuestions = {
      [DIFFICULTY[0]]: [].concat(calData[DIFFICULTY[0]]),
      [DIFFICULTY[1]]: [].concat(calData[DIFFICULTY[1]]),
      [DIFFICULTY[2]]: [].concat(calData[DIFFICULTY[2]]),
    };
    let stop = 0;
    let difficultyDistribution = [].concat(newDifficultyDistribution);
    while (count < allCount && stop < 100000) {
      stop = stop + 1;
      const difficulty = getRandomDifficulty(difficultyDistribution);
      if (remainingQuestions[difficulty].length > 0 && pickUpQuestions[difficulty].length < difficultyCount[difficulty]) {
        const pickUp = Math.floor(Math.random() * remainingQuestions[difficulty].length);
        const [question] = remainingQuestions[difficulty].splice(pickUp, 1);
        pickUpQuestions[difficulty].push(question);
        ++count;
      } else {
        if (difficulty === DIFFICULTY[0]) {
          difficultyDistribution[0] = 0;
        } else if (difficulty === DIFFICULTY[1]) {
          difficultyDistribution[1] = 0;
        } else if (difficulty === DIFFICULTY[2]) {
          difficultyDistribution[2] = 0;
        }
      }
    }
    return pickUpQuestions;

  };

  useEffect(() => {
    if (!examPaperInfo) return;
    if (Object.keys(tableData).length === 0) {
      setTableData(formateTableData(examPaperInfo));
    }
  }, [examPaperInfo]);

  useEffect(() => {
    if (!tableData) { setIsLoading(false); return; }
    setIsLoading(true);
    let nextData = {};
    Object.entries(tableData).forEach(([key, value]) => {
      nextData = {
        ...nextData,
        [key]: {
          questions: value.question,
          [DIFFICULTY[0]]: value.question.filter(question => question.difficulty === DIFFICULTY[0]),
          [DIFFICULTY[1]]: value.question.filter(question => question.difficulty === DIFFICULTY[1] || question.difficulty === DIFFICULTY[3] || question.difficulty === DIFFICULTY[4]),
          [DIFFICULTY[2]]: value.question.filter(question => question.difficulty === DIFFICULTY[2]),
        }
      };
    });
    setTableQuestions(nextData);
    setIsLoading(false);
  }, [tableData]);

  useEffect(() => {
    if (!tableData || data.length > 0) return;
    //全部的題型
    const allKeyList = Object.keys(tableData);
    //因為後面步驟只有題目的才會顯示所以抓與後面步驟差了那些題型
    const diffKeyList = allKeyList.filter(item => !keyList.includes(item));
    //相差的題型擺在後面
    const nextKeyList = keyList.concat(diffKeyList);
    setData(nextKeyList);
  }, [tableData]);

  useEffect(() => {
    getKeyList(data);
  }, [data]);

  const mounted = React.useRef(false);

  useEffect(() => {
    if (mounted.current) {
      // setTableData(tempTableData);
    } else {
      mounted.current = true;
    }
  }, [resetToggle]);

  // --- 手機版 相關 ---

  // 配題與配分 抽屜
  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);

  const doShowSetupDrawer = (quesType) => {
    setSetupDrawerContent({
      question: {
        question: tableData[quesType]?.inputValue.question || '',
        maxcount: tableData[quesType]?.count.question,
      },
      answer: {
        answer: tableData[quesType]?.inputValue.answer || '',
        maxcount: tableData[quesType]?.count.answer,
      },
      perQues: tableData[quesType]?.inputValue.score.perQues || '',
      perAns: tableData[quesType]?.inputValue.score.perAns || '',
      totalScore: tableData[quesType]?.inputValue.score.allScore,
    });
    setSetupQuestionType(quesType);
    setupDrawerToggle();
  };

  const doneSetupDrawer = () => {
    setupDrawerDoneChange();
    closeSetupDrawer();
  };

  const closeSetupDrawer = () => {
    setupDrawerFalse();
    setSetupDrawerContent({
      question: {
        question: 0,
        maxcount: 0,
      },
      answer: {
        answer: 0,
        maxcount: 0,
      },
      perQues: 0,
      perAns: 0,
      totalScore: 0,
    });
    setSetupDrawerPickUpQuestions([]);
  };

  const [setupDrawerContent, setSetupDrawerContent] = useState({
    question: {
      question: 0,
      maxcount: 0,
    },
    answer: {
      answer: 0,
      maxcount: 0,
    },
    perQues: 0,
    perAns: 0,
    totalScore: 0,
  });

  // 目前打開的 題型為何
  const [setupQuestionType, setSetupQuestionType] = useState('');

  // 紀錄目前產生的 題目為何
  const [setupDrawerPickUpQuestions, setSetupDrawerPickUpQuestions] = useState([]);

  // 過濾篩選 抽屜
  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);

  // 日期 篩選器 相關
  const dateFormat = 'YYYY/MM/DD';

  const [datePickerIsDisable, setDatePickerIsDisable] = useState(false);

  const [datePicker, setDatePicker] = useState({
    startTime: '',
    endTime: '',
  });

  const dateChangeStart = (current) => {
    if (current !== undefined) {
      setDatePicker({
        ...datePicker,
        startTime: current,
      });
    }
  };

  const dateChangeEnd = (current) => {
    if (current !== undefined) {
      setDatePicker({
        ...datePicker,
        endTime: current,
      });
    }
  };

  const disabledDateStart = (current) => {
    if (datePicker.endTime) {
      return current.valueOf() > datePicker.endTime.valueOf();
    } else {
      return false;
    }
  };

  const disabledDateEnd = (current) => {
    if (datePicker.startTime) {
      return current && current.valueOf() < datePicker.startTime.valueOf();
    } else {
      return false;
    }
  };

  // 判斷 過濾題目btn 是否能按下
  const datePickerCheckBtn = () => {
    if (datePickerIsDisable) {
      if (datePicker.startTime && datePicker.endTime) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  // 關閉 背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (setupDrawerVisible | filterDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [setupDrawerVisible, filterDrawerVisible]);

  useEffect(() => {
    goTop();
  }, []);

  useEffect(() => {
    if (isChangeFilter) {
      setTableData(formateTableData(examPaperInfo));
      // getTableData(formateTableData(examPaperInfo));
      setIsChangeFilter(false);
    }
  }, [isChangeFilter]);

  const onAnswerInputChange = (item, e) => {
    const value = parseInt(e.target.value);
    const nextData = {
      ...tableData,
      [item]: {
        ...tableData[item],
        inputValue: {
          ...tableData[item].inputValue,
          answer: value,
        }
      }
    };
    setTableData(nextData);
  };

  const renderQuestionTypeCell = (type, isListen = false) => {
    const { name } = type;
    if (isListen) {
      return (
        <div style={{ justifyContent: 'flex-start' }}>
          <QuestionTypeName name={name} isListen={isListen} />
        </div>
      );
    } else if (name.length > 12) {
      return <Tooltip placement="top" title={name} zIndex={100} color='#4e4e5a'>
        {name.substring(0, 12)}...
      </Tooltip>;
    } else {
      return name;
    }
  };

  return (
    isLoading ?
      renderLoadingContent()
      :
      <StyledHomeQuesTypeSelectContent className={className} data-testid="HomeQuesTypeSelectContent">
        {
          deviceData.isDesktop ?
            <>
              <Box mb={6}>
                <div className="pageTitle">題型選擇</div>
              </Box>
              <Box mb={4}>
                <div className={classNames('flexBox', env === 'dev' ? '' : 'hideDate')}>
                  <div className='switchType'>
                    <div className='switchBox'>
                      <div className="switchLabel" >切換配題方式</div>
                      <div className="radioSwitch">
                        <RadioGroup
                          buttonStyle='solid'
                          optionType='button'
                          value={collocationQues}
                          onChange={(e) => {
                            onCollocationQuesChange(e);
                            GA.changeSelectBy(e.target.value);
                          }}
                        >
                          <Radio.Button value='quesAndAns'>題數與答數</Radio.Button>
                          <Radio.Button value='diff'>難易度</Radio.Button>
                          {/*<Radio.Button className="radioHaveSvg" value='custom'>自訂
                            <Tooltip placement="top" title={'可自行調整欄位編輯狀態，系統將自動保留調整記錄'} zIndex={100} color='#4e4e5a' >
                              {
                                collocationQues === 'custom' ?
                                  <QuestionCircleSolid style={{ color: '#fff', marginLeft: '4px' }} /> :
                                  <QuestionCircleSolid style={{ color: '#8B90A0', marginLeft: '4px' }} />
                              }
                            </Tooltip>
                            </Radio.Button> */}
                        </RadioGroup>
                      </div>
                    </div>
                    <Button
                      className={'filterBtn'}
                      variant={'primary2'}
                      onClick={() => {
                        filterQuestionModalToggle(filterPaperUID, totalQuesTypeData.questionCount || false);
                        GA.filterUsedQuestions('open_modal');
                      }}
                    >
                      {
                        filterPaperUID && filterPaperUID.length > 0
                          ?
                          <div>
                            <img src={'/assets/filterCheckSolid.svg'} alt='filter' />
                          </div>
                          :
                          <FilterSolid/>
                      }
                      <span>過濾已出過試題</span>
                    </Button>
                  </div>
                </div>
              </Box>
              <Box pb={12} className="tableZone">
                <Table>
                  <Table.Header>
                    <Table.Row textcenter={true} isFixedWidth={true}>
                      <Table.Item width={'72px'}>順序</Table.Item>
                      <Table.Item width={'192px'}>題型</Table.Item>
                      <Table.Item width={'90px'}>
                        已選題數
                        {
                          collocationQues === 'custom' &&
                          <Switch className="tableSwitch" size='small' checked={canCollocation.question} onChange={(checked) => {onSwitchChange('question', checked);}} />
                        }
                      </Table.Item>
                      <Table.Item width={'90px'}>
                        已選答數
                        {
                          collocationQues === 'custom' &&
                          <Switch className="tableSwitch" size='small' checked={canCollocation.answer} onChange={(checked) => {onSwitchChange('answer', checked);}} />
                        }
                      </Table.Item>
                      <Table.Item width={'378px'}>
                        難易度題數
                        {
                          collocationQues === 'custom' &&
                          <Switch className="tableSwitch" size='small' checked={canCollocation.difficulty} onChange={(checked) => {onSwitchChange('difficulty', checked);}} />
                        }
                      </Table.Item>
                      {/* <Table.Item width={'72px'} borderpos='left'>每題分數</Table.Item> */}
                      <Table.Item width={'72px'}>每答分數</Table.Item>
                      <Table.Item width={'56px'}>小計</Table.Item>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body className="questionTableBody">
                    {
                      data && data?.length > 0 && data.map((item, index) => (
                        <>
                          {
                            tableData[item]?.question.length > 0 &&
                              <Table.TableDndRow isFixedWidth={true} className="quesRow" id={tableData[item].code} index={index} key={tableData[item].code} moveCard={moveCard}>
                                <Table.Item className="havePadding" width={'192px'}>
                                  {renderQuestionTypeCell(tableData[item], tableData[item].isListen)}
                                </Table.Item>
                                <Table.Item width={'90px'}>
                                  <div>
                                    {
                                      collocationQues === 'quesAndAns' || (collocationQues === 'custom' && canCollocation.question) ?
                                        <>
                                          <Input allowClear={false} value={tableData[item]?.inputValue.question || ''} onChange={(e) => {tableInputChange(item, 'question', e, tableData[item].count.question);}} /><span>/{tableData[item].count.question}</span>
                                        </>
                                        :
                                        tableData[item]?.inputValue.question || '--'
                                    }

                                  </div>
                                </Table.Item>
                                <Table.Item width={'90px'}>
                                  <div>
                                    {
                                      collocationQues === 'quesAndAns' || (collocationQues === 'custom' && canCollocation.answer) ?
                                        <>
                                          <Input allowClear={false} value={tableData[item]?.inputValue.answer || ''} onBlur={(e) => {tableInputChange(item, 'answer', e, tableData[item].count.answer);}} onChange={(e) => {onAnswerInputChange(item, e);}} />
                                          <span>/{tableData[item].count.answer}</span>
                                        </>
                                        :
                                        tableData[item]?.inputValue.answer || '--'
                                    }

                                  </div>
                                </Table.Item>
                                <Table.Item width={'378px'} className='diffculyRow'>
                                  {
                                    DIFFICULTY_TAG_DISPLAY.map(diffItem => {
                                      return (
                                        <>
                                          {
                                            collocationQues === 'diff' || (collocationQues === 'custom' && canCollocation.difficulty) ?
                                              <div>
                                                <DifferentTag mr={1} variant={diffStr[diffItem]} />
                                                <Input
                                                  disabled={!tableData[item]?.count?.difficulty[diffItem]?.question}
                                                  allowClear={false}
                                                  value={tableData[item]?.inputValue.difficulty[diffItem] || ''}
                                                  onChange={(e) => {diffInputChange(item, diffItem, e, tableData[item].count.difficulty[diffItem].question );}}
                                                />
                                                <span>/{tableData[item].count.difficulty[diffItem]?.question || 0}</span>
                                              </div>
                                              :
                                              <div><DifferentTag mr={1} variant={diffStr[diffItem]} />{tableData[item]?.inputValue.difficulty[diffItem] || '--'}</div>
                                          }
                                        </>
                                      );
                                    })
                                  }
                                </Table.Item>
                                {/* <Table.Item width={'72px'} borderpos='left' >
                                  <div>
                                    <Input allowClear={false} value={tableData[item]?.inputValue.score.perQues || ''} onChange={(e) => { scoreInputChange(item, 'perQues', e); }} />
                                  </div>
                                </Table.Item> */}
                                <Table.Item width={'72px'}>
                                  <div>
                                    <Input allowClear={false} value={tableData[item]?.inputValue.score.perAns || ''} onChange={(e) => {scoreInputChange(item, 'perAns', e);}} />
                                  </div>
                                </Table.Item>
                                <Table.Item width={'56px'}>
                                  <div>
                                    {tableData[item]?.inputValue.score.allScore}
                                  </div>
                                </Table.Item>
                              </Table.TableDndRow>
                          }
                        </>
                      ))
                    }
                    <Table.Row>
                      <Table.Item className='tableFooter'>
                        總題數 {totalQuesTypeData.questionCount} 題，總答數 {totalQuesTypeData?.totalAnswer} 答，總分 {totalQuesTypeData.score} 分
                      </Table.Item>
                    </Table.Row>

                  </Table.Body>

                </Table>
              </Box>
            </>
            :
            // ---- 手機版本 -----
            <>
              <div className={'filterBox'}>
                <Button
                  className={classNames('filterBtn', filterPaperUID && filterPaperUID.length > 0 ? 'active' : '')}
                  onClick={() => {
                    filterQuestionModalToggle(filterPaperUID, totalQuesTypeData.questionCount || false);
                    GA.filterUsedQuestions('open_modal');
                  }}
                >
                  {
                    filterPaperUID && filterPaperUID.length > 0
                      ?
                      <div>
                        <img src={'/assets/filterCheckSolid_black.svg'} alt='login' />
                      </div>
                      :
                      <FilterSolid/>
                  }
                  過濾
                </Button>
              </div>
              <Box pb={12} className="tableZone">
                <Table>
                  <Table.Header>
                    <Table.Row textcenter={true}>
                      <Table.Item flex={1}>順序</Table.Item>
                      <Table.Item flex={5} className={'questionTypeName'} textalign={'flex-start'}>題型</Table.Item>
                      <Table.Item flex={1.5} textalign={'flex-start'}>配題</Table.Item>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body className="questionTableBody">
                    {
                      data && data.length > 0 && data.map((item, index) => (
                        <>
                          {
                            tableData[item]?.question.length > 0 &&
                              <Table.TableDndRow className="quesRow" id={tableData[item].code} index={index} key={tableData[item].code} moveCard={moveCard}>
                                <Table.Item className="havePadding" flex={5}>
                                  {
                                    tableData[item].name.length > 12 ?
                                      <>
                                        <Tooltip placement="top" title={tableData[item].name} zIndex={100} color='#4e4e5a'>
                                          {tableData[item].name.substring(0, 12)}...
                                        </Tooltip>
                                      </>
                                      : tableData[item].name
                                  }
                                  <div className={classNames('scoreText', tableData[item]?.inputValue.question > 0 ? 'scoreTextSelected' : '')}>
                                    已選題數{tableData[item]?.inputValue.question || '0' }題 ｜ 總分{tableData[item]?.inputValue.score.allScore}分
                                  </div>
                                </Table.Item>
                                <Table.Item flex={1.5}>
                                  <Button
                                    className={'setupBtn'}
                                    variant={'brand1'}
                                    onClick={() => doShowSetupDrawer(item)}
                                  >
                                    配題
                                  </Button>
                                </Table.Item>
                              </Table.TableDndRow>
                          }
                        </>
                      ))
                    }
                  </Table.Body>
                </Table>
                <div className={'scoreBottom'}>
                  總題數 {totalQuesTypeData?.questionCount} 題，總答數 {totalQuesTypeData?.totalAnswer} 答，總分 {totalQuesTypeData?.score} 分
                </div>
              </Box>
              <Drawer
                visible={setupDrawerVisible}
                title={'配題與配分'}
                onClose={setupDrawerFalse}
                id={'setupDrawer'}
                placement={'bottom'}
                isMobile={true}
                footer={
                  <Button variant={'brand1'} className={'setupDrawerFooter'} onClick={doneSetupDrawer}>儲存</Button>
                }
              >
                {
                  setupQuestionType &&
                    <>
                      <div className={'drawerTitle'}>
                        {tableData[setupQuestionType]?.name}
                      </div>
                      <div className={'drawerSection'}>
                        <div className={'sectionTitle'}>
                          配題
                        </div>
                        <div className={'drawerTable'}>
                          <div>
                            <div>已選題數</div>
                            <div>已答題數</div>
                          </div>
                          <div>
                            <div>
                              <Input allowClear={false} value={setupDrawerContent.question.question} onChange={(e) => {setupQuesInputChange('question', e);}}/>
                              <span>/{tableData[setupQuestionType]?.count.question}</span>
                            </div>
                            <div>
                              <Input allowClear={false} value={setupDrawerContent.answer.answer} onChange={(e) => {setupQuesInputChange('answer', e);}} />
                              <span>/{tableData[setupQuestionType]?.count.answer}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={'drawerSection'}>
                        <div className={'sectionTitle'}>
                          配分
                        </div>
                        <div className={'sectionSubTitle'}>
                          後續步驟可再修改配分
                        </div>
                        <div className={'drawerTable'}>
                          <div>
                            {/* <div>每題分數</div> */}
                            <div>每答分數</div>
                          </div>
                          <div className={'setupDrawerScoreBox'}>
                            {/* <Input allowClear={false} value={setupDrawerContent.perQues} onChange={(e) => {setupDrawerScoreInputChange('perQues', e);}} /> */}
                            <Input allowClear={false} value={setupDrawerContent.perAns} onChange={(e) => {setupDrawerScoreInputChange('perAns', e);}} />
                          </div>
                        </div>
                      </div>
                      <div className={'drawerTotalScore'}>
                        小計 {setupDrawerContent.totalScore} 分
                      </div>
                    </>
                }
              </Drawer>
              <Drawer
                visible={filterDrawerVisible}
                title={'過濾題目'}
                onClose={filterDrawerFalse}
                placement={'bottom'}
                isMobile={true}
                footer={
                  <Button disabled={datePickerCheckBtn()} variant={datePickerCheckBtn() ? 'ui01' : 'brand1'} className={'setupDrawerFooter'} onClick={() => filterDrawerToggle()}>確定</Button>
                }
              >
                <div className={'filterDrawerContent'}>
                  <div>
                    <span>排除過去期間內已出過的題目</span>
                    <Switch checked={datePickerIsDisable} onClick={() => {setDatePickerIsDisable(!datePickerIsDisable);}}/>
                  </div>
                  <div>
                    <span>開始日期</span>
                    <div>
                      <DatePicker
                        disabled={!datePickerIsDisable}
                        format={dateFormat}
                        allowClear={false}
                        onChange={dateChangeStart}
                        disabledDate={disabledDateStart}
                      />
                    </div>
                  </div>
                  <div>
                    <span>結束日期</span>
                    <div>
                      <DatePicker
                        disabled={!datePickerIsDisable}
                        format={dateFormat}
                        allowClear={false}
                        onChange={dateChangeEnd}
                        disabledDate={disabledDateEnd}
                      />
                    </div>
                  </div>
                </div>
              </Drawer>
            </>
        }
        {
          renderFilterQuestionModal()
        }
      </StyledHomeQuesTypeSelectContent>
  );
};

HomeQuesTypeSelectContent.propTypes = {
  className: PropTypes.string,
  resetToggle: PropTypes.bool,
  examPaperInfo: PropTypes.object,
  getTableData: PropTypes.object,
  getKeyList: PropTypes.func,
  keyList: PropTypes.array,
  tempTableData: PropTypes.func,
};
/* eslint-disable react/prop-types */
import { Button } from '@oneclass/onedesign';
import { StyledFontsPage } from './FontsPage.style';

export const FontsPage = ({ className }) => {
  return (
    <StyledFontsPage className={className} data-testid="FontsPage">
      <img src="/assets/downloadFolder.svg" alt="" className='download-icon' />
      <div className='title'>南一題庫字型安裝說明</div>
      <div className='description'>
        <p>
          當您無法正確顯示試卷字型時，請下載壓縮檔並依照下列方式安裝。
        </p>
        <p className='subtitle'>
          下載檔案：
        </p>
        <Button
          borderRadius="4px"
          variant={'brand1'}
          className="download-button"
        >
          <a href="https://firebasestorage.googleapis.com/v0/b/onepaper-1126a.appspot.com/o/fonts%2F南一題庫產品字型.zip?alt=media">
            <img src="/assets/downloadDisk.svg" alt="" />
            <span>
              下載題庫字型包
            </span>
          </a>
        </Button>
        <p className='subtitle'>
          安裝說明：
        </p>
        <ol>
          <li>於下載資料夾找到下載的檔案，檔名為：南一題庫產品字型.zip</li>
          <li>對壓縮檔按下滑鼠右鍵，點擊 [解壓縮]</li>
          <li>選取所有 .ttf 與 .ttc 字型檔（即 TrueType 字型檔案），按下滑鼠右鍵，點擊 [安裝]</li>
          <img src="/assets/fontsPage/install.png" alt="" />
          <li>即完成字型安裝</li>
        </ol>
        <p>*若在第三步字型檔的右鍵選項中找不到「安裝」，可點擊 [顯示其他選項]，即可看到 [安裝]</p>
        <img src="/assets/fontsPage/install2.png" alt="" />
      </div>
    </StyledFontsPage>
  );
};
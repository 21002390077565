import { useEffect } from 'react';


export const useLeavePageAlert = (isLast) => {

  useEffect(() => {

    const beforeunload = (event) => {
      event.preventDefault();
      if (!isLast) {
        event.returnValue = '您確定要離開這個頁面嗎？您的變更可能會丟失。';
      }
    };

    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };

  }, [isLast]);
};

/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, TreeCheck, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE } from '@oneclass/ui-components';
import { Checkbox, RadioGroup } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { UiFlexBox } from 'styles/index';
import { format } from 'date-fns';
import { StyledKnowledgeAreaSelectContent, UiSelectList } from './KnowledgeAreaSelectContent.style';
import { postRangeSelection } from 'api/query';
import { VERSION_NAME } from 'constants/index';

export const KnowledgeAreaSelectContent = ({
  className,
  resetToggle,
  examPaperType,
  saveData,
  getSendAreaParams,
  memberEduSubject,
  getKeyList,
  getSaveData,
  identity,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  setIsLanguageSubject,
  knowledgeList,
  setKnowledgeList,
  treeCheckedList,
  setTreeCheckedList,
  globalExamPaperData,
}) => {
  const [sendParams, setSendParams] = useState({
    ...saveData.areaParams,
    year: saveData.areaParams.year,
  });
  const [selectLoading, setSelectLoading] = useState(false);
  const [sendYear, setYear] = useState(saveData?.areaParams?.year);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [stateGetScopes, sendGetScopes] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setSelectLoading(true);
        setIsLoading(true);
        const res = await postRangeSelection(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { content } = data;
        setSelectLoading(false);
        if (!isEditCopy) setIsLoading(false);
        setKnowledgeList(content);
        return {
          isSuccess, systemCode, message,
          // knowledgeList: content,
        };
      },
    },
  });

  // const {
  //   knowledgeList
  // } = stateGetScopes.context.result || {};

  const setRadioOptions = (type, array) => {
    return array.map((item) => {
      if (type === 'curriculum') {
        return {
          label: item + '課綱',
          value: item,
        };
      } else if (type === 'year') {
        return {
          label: item + '學年度',
          value: item,
        };
      } else if (type === 'version') {
        return {
          label: VERSION_NAME[item],
          value: item,
        };
      } else if (type === 'books') {
        return {
          value: item[0],
          name: item[1].volumnName,
        };
      }
    });
  };

  const curriculumOptions = useMemo(() => {
    if (!knowledgeList || JSON.stringify(knowledgeList) === '{}') return [];
    const data = (memberEduSubject.edu === 'J' || memberEduSubject.edu === 'H') ? setRadioOptions('curriculum', Object.keys(knowledgeList)).filter(item => item.value !== '99') : setRadioOptions('curriculum', Object.keys(knowledgeList));
    return data.filter(({ value }) => memberEduSubject.output === 'Series' || value !== '000').sort((a, b) => {
      return Number(b?.value) - Number(a?.value);
    });
  }, [knowledgeList, memberEduSubject]);

  const yearOptions = useMemo(() => {
    if ((!knowledgeList || JSON.stringify(knowledgeList) === '{}') || !sendParams.curriculum) return [];
    const data = knowledgeList[sendParams.curriculum] ? setRadioOptions('year', Object.keys(knowledgeList[sendParams.curriculum])).filter(item => item.value !== '109') : [];
    return data.sort((a, b) => {
      return Number(b?.value) - Number(a?.value);
    });
  }, [sendParams.curriculum, knowledgeList]);

  const versionOptions = useMemo(() => {
    if ((!knowledgeList || JSON.stringify(knowledgeList) === '{}') || !sendParams.curriculum || !sendParams.year) return [];
    const data = knowledgeList[sendParams.curriculum][sendParams.year] ? setRadioOptions('version', Object.keys(knowledgeList[sendParams.curriculum][sendParams.year])) : [];
    return data.sort((a, b) => {
      return b?.value.charCodeAt() - a?.value.charCodeAt();
    });
  }, [sendParams.curriculum, sendParams.year, knowledgeList]);

  let initIndex = 1000;

  const renderIndex = () => {
    initIndex++;
    return initIndex;
  };

  const chapterOptions = useMemo(() => {

    if ((!knowledgeList || JSON.stringify(knowledgeList) === '{}') || !sendParams.curriculum || !sendParams.year || !sendParams.version || !memberEduSubject?.subject) return [];
    const nextData = knowledgeList[sendParams.curriculum][sendParams.year][sendParams.version]?.topics
      ? knowledgeList[sendParams.curriculum][sendParams.year][sendParams.version]?.topics.map((topics) => {
        return {
          title: topics?.Name || '',
          key: renderIndex(),
          children: topics?.SubTopics ? topics?.SubTopics.map((subTopic) => {
            return {
              title: subTopic?.Name || '',
              key: renderIndex(),
              children: subTopic?.Knowledges ?
                subTopic?.Knowledges.map((item) => {
                  return {
                    title: item.name,
                    key: item.code
                  };
                })
                : []
            };
          }) : [],
        };
      })
      : knowledgeList[sendParams.curriculum][sendParams.year][sendParams.version]?.categories.map((type) => {
        return {
          title: type?.name || '',
          key: type?.code,
        };
      });
    return nextData;
  }, [knowledgeList, sendParams.curriculum, sendParams.year, sendParams.version, memberEduSubject?.subject]);

  useEffect(() => {
    let res = true;
    let haveChildren = chapterOptions.filter((item) => {
      return item.children ? item.children.length > 0 : false;
    });
    if (haveChildren.length > 0) {
      res = false;
    }
    setIsLanguageSubject(res);
  }, [chapterOptions]);

  const getAllTreeData = (data) => {
    if (!data || data.length === 0) return [];
    let nextData = [];
    data.forEach(item => {
      let children = [];
      if ('children' in item) {
        children = getAllTreeData(item.children);
        nextData = [...nextData, ...children];
      }
      nextData = [...nextData, item.key];
    });
    return nextData;
  };

  const treeDataLength = useMemo(() => {
    if (!chapterOptions) return 0;
    return getAllTreeData(chapterOptions).length;
  }, [chapterOptions]);

  const onTreeChange = (data) => {
    setTreeCheckedList(data);
  };

  const onCheckAllChange = e => {
    setTreeCheckedList(e.target.checked ? getAllTreeData(chapterOptions) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onRadioChange = (type, e) => {
    if (type === 'curriculum') {
      const curriculum = e.target.value;
      const year = setRadioOptions('year', Object.keys(knowledgeList[curriculum])).filter(item => item.value !== '109')[0].value;
      setSendParams({
        ...sendParams,
        [type]: curriculum,
        year: year,
        version: 'N',
      });
      setTreeCheckedList([]);
    } else if (type === 'year') {
      const year = e.target.value;
      setSendParams({
        ...sendParams,
        [type]: year,
        version: 'N',
      });
      setTreeCheckedList([]);
    } else if (type === 'version') {
      setSendParams({
        ...sendParams,
        [type]: e.target.value,
      });
      setTreeCheckedList([]);
    } else {
      setSendParams({
        ...sendParams,
        [type]: e.target.value
      });
    }
  };

  const onChangeYear = (type, e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    if (isEditCopy) {
      setIsLoading(true);
    }

    if (!memberEduSubject?.edu || !memberEduSubject?.subject || sendYear === '') return;

    sendGetScopes(FETCH_DATA_EVENT.FETCH, {
      payload: {
        edusubject: memberEduSubject?.edu + memberEduSubject?.subject,
        outputStyle: memberEduSubject?.output,
        drawUpStyle: 'KnowledgePattern',
      }
    });

    setTreeCheckedList([]);
    if (memberEduSubject?.edu === saveData.education && memberEduSubject?.subject === saveData.subject && sendYear === saveData?.areaParams?.year) return;
    setSendParams({
      curriculum: sendParams.curriculum,
      year: sendYear
    });

    getKeyList([]);
  }, [memberEduSubject.edu, memberEduSubject.subject]);

  useEffect(() => {
    if (stateGetScopes.matches(FETCH_DATA_STATE.DONE)) {
      if (sendYear === '' || sendYear === saveData?.areaParams?.year) {
        setSendParams(saveData.areaParams);
        setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
      }
    }
  }, [stateGetScopes.value, sendYear]);

  useEffect(() => {
    if (treeCheckedList.length === 0) {
      setIndeterminate(false);
      setCheckAll(false);
    } else {
      setIndeterminate(treeCheckedList.length !== treeDataLength ? true : false);
      setCheckAll(treeCheckedList.length !== treeDataLength ? false : true);
    }
  }, [treeDataLength, treeCheckedList]);

  useEffect(() => {
    if (!sendParams) return;
    var inValid = /\s/;
    getSendAreaParams({
      ...sendParams,
      keys: treeCheckedList.filter(item => !inValid.test(item)),
      treeCheckedList
    });
  }, [sendParams, treeCheckedList, memberEduSubject]);

  useEffect(() => {
    if (sendParams.curriculum === saveData.areaParams.curriculum) return;
    if (sendParams.version === saveData.areaParams.version) return;
  }, [sendParams.curriculum, sendParams.version]);

  useEffect(() => {
    const nextData = treeCheckedList.filter(item => getAllTreeData(chapterOptions).includes(item));
    setTreeCheckedList(nextData);
  }, [sendParams.books]);

  const mounted = React.useRef(false);
  useEffect(() => {
    if (mounted.current) {
      setSendParams(saveData.areaParams);
      setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
    } else {
      mounted.current = true;
    }
  }, [resetToggle]);

  // 預設選取 課綱＆年度＆版本 第一個。
  useEffect(() => {
    if (!curriculumOptions || !yearOptions || !versionOptions) return;
    if (sendParams?.curriculum && sendParams?.year && sendParams?.version) return;
    setSendParams({
      ...sendParams,
      curriculum: curriculumOptions[0]?.value,
      year: yearOptions[0]?.value,
      version: versionOptions[0]?.value,
    });
  }, [curriculumOptions, yearOptions, versionOptions, sendParams.curriculum, sendParams.year, sendParams.version]);

  return (
    isLoading ?
      renderLoadingContent()
      :
      <StyledKnowledgeAreaSelectContent className={className} data-testid="KnowledgeAreaSelectContent">
        <UiFlexBox>
          <Box mb={6}>
            <div className="label">課綱</div>
            <UiSelectList>
              <RadioGroup buttonStyle='solid' optionType='button' options={curriculumOptions} value={sendParams?.curriculum} onChange={(e) => {onRadioChange('curriculum', e);}} />
            </UiSelectList>
          </Box>
          <Box mb={6}>
            <div className="label">年度</div>
            <UiSelectList>
              <RadioGroup buttonStyle='solid' optionType='button' options={yearOptions} value={sendParams?.year} onChange={(e) => {onRadioChange('year', e); onChangeYear('year', e);}} />
            </UiSelectList>
          </Box>
          <Box mb={6}>
            <div className='areaBorder'></div>
          </Box>
          {
            !selectLoading &&
            <>
              {
                versionOptions && versionOptions.length > 0 && identity !== 'SchoolTeacher' &&
                  <>
                    <Box mb={6}>
                      <div className="label">版本</div>
                      <UiSelectList>
                        <RadioGroup buttonStyle='solid' optionType='button' options={versionOptions} value={sendParams?.version} onChange={(e) => {onRadioChange('version', e);}} />
                      </UiSelectList>
                    </Box>
                    <Box mb={6}>
                      <div className='areaBorder'></div>
                    </Box>
                  </>
              }

              <Box mb={6}>
                <div className="label">
                  知識向度
                  <div>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全選</Checkbox>
                  </div>
                </div>
                <UiSelectList>
                  <TreeCheck treeData={chapterOptions} checkedList={treeCheckedList} onChange={onTreeChange} />
                </UiSelectList>
              </Box>
            </>
          }

        </UiFlexBox>
      </StyledKnowledgeAreaSelectContent>
  );
};
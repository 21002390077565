import React from 'react';
import PropTypes from 'prop-types';

export const QuestionTypeName = ({ name, isListen }) => {
  return (
    <>
      {name}
      {isListen && <img src='/assets/greyHeadphones.svg' alt='' style={{ marginLeft: '8px' }} />}
    </>
  );
};

QuestionTypeName.propTypes = {
  name: PropTypes.string.isRequired,
  isListen: PropTypes.bool
};

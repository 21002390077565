import { Modal } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { ButtonBlock, TextBlock } from './RouteLeaveAlert.style';

export default function RouteLeaveAlert({
  show,
  onLeave,
  onStay
}) {
  return (
    <Modal visible={show}
      width='600px'
      onCancel={onStay}
      title={
        <div>此卷尚未完成，您確定要離開嗎？</div>
      }
      footer={
        <ButtonBlock>
          <Button className='leave' onClick={onLeave}>確定離開</Button>
          <Button className='think' onClick={onStay}>再考慮一下</Button>
        </ButtonBlock>
      }>
      <TextBlock>
        離開後此次出卷將被放棄，系統不會保存你的出卷紀錄
      </TextBlock>
    </Modal>
  );
}

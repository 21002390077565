import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useBoolean, useClickAway } from 'ahooks';
import {
  FrontLayout,
  Dropdown,
  AuthProvider,
  ThemeProvider,
  Modal,
} from '@oneclass/ui-components';
import { OneTestHeader } from 'containers';
import { Button } from '@oneclass/onedesign';
import RenderRoutes from 'routes/RenderRoutes';
import {
  UserSolid,
  UserCogSolid,
  BackstageManagementSolid,
  SignOutSolid,
  XmarkOutline,
  ChevronDownToBottomSolid,
  ChevronUpToTopSolid,
  SearchSolid,
  FolderOpenSolid,
  ScrollSolid,
  CaretDownSolid,
  CaretUpSolid
} from '@onedesign/icon';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { useSize } from 'ahooks';
import {
  StyledMainLayout,
  UiUserBox,
  StyledFooterModal,
  StyledMenuBox,
  StyledPageHeader,
} from './MainLayout.style';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { getAllRemoteConfig } from 'utils/hooks/useFirebaseRemoteConfig';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { useLoadingMark } from 'utils/hooks/useLoadingMark';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as firebaseRemoteConfigProvider } from 'providers/firebaseRemoteConfigProvider.js';
import { default as UserProvider } from 'providers/UserProvider.js';
import * as GA from 'utils/googleAnalytics';
import { useBitrix } from 'utils/hooks/useBitrix';
import { RouteLeaveProvider } from 'providers/routeLeaveProvider';
import LogoutAlert from 'components/LogoutAlert/LogoutAlert';
import { HideOrdinalNumberChaptersProvider } from 'providers/hideOrdinalNumberChaptersProvider';

const version = process.env.REACT_APP_VERSION;

const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};

const goBottom = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = main.scrollHeight;
};

export const MainLayout = ({ className, routes = [] }) => {
  const { deviceData, deviceDataProviderChange } = useContext(
    deviceProvider.deviceProviderContext
  );
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const { remoteConfigData, RemoteConfigDataProviderChange } = useContext(
    firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext
  );
  const history = useHistory();
  const env = process.env.REACT_APP_NODE_ENV;
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const { logout, settingEduSubject } = useAuthDispatch();
  const authState = useAuthState();
  const location = useLocation();
  const [state, { toggle, setFalse }] = useBoolean(false);
  const [
    modalVisible,
    { toggle: setModalToggle, setFalse: setImportModalFalse },
  ] = useBoolean(false);
  const [
    copyrightModalVisible,
    { toggle: copyrightModalToggle, setFalse: copyrightModalFalse },
  ] = useBoolean(false);
  const drawRef = useRef();
  const modalRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);
  const [showCustomerServiceTip, setShowCustomerServiceTip] = useState(true);
  const {
    renderEduSubjectModal,
    setEduSubjectModalToggle,
    setEduSubjectModalFalse,
  } = useEduSubjectModal(
    authState.context.userDetails?.preference,
    'mainLayout',
    location.pathname
  );
  const layoutRef = useRef();
  const size = useSize(layoutRef);
  useClickAway(() => {
    setFalse();
  }, drawRef);
  useClickAway(() => {
    setImportModalFalse();
  }, modalRef);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const {
    renderLoadingMark,
    isLoading: isLoadingMark,
    setIsLoading: setIsLoadingMark,
  } = useLoadingMark();
  const props = {
    setIsLoadingMark,
  };
  const [configData, setConfigData] = useState({});
  const { showButton, hideButton } = useBitrix();
  const [isLogoutAlertOpen, setIsLogoutAlertOpen] = useState(false);

  const scrollPosition = useMemo(() => {
    const main = document.getElementById('mainLayout');
    if (scrollTop === 0) return 'top';
    if (main.offsetHeight + scrollTop >= main.scrollHeight) return 'bottom';
    return 'middle';
  }, [scrollTop]);

  const isPro = useMemo(() => {
    return userInfo.userType === 'Premium'; //(userData.organizationType === 'Agency' || userData.organizationType === 'Tutoring') ? true : false;
  }, [userInfo]);

  const isTest = useMemo(() => {
    return userInfo.onePaperIdentity === 'TestCram';
  }, [userInfo]);

  const goToSearchPage = () => {
    history.push('/SearchPaperListPage');
  };

  const addNowPageClassName = useMemo(() => {
    switch (location.pathname) {
      case '/login':
        return 'loginPage';
      case '/computerQuestion':
      case '/manualProposition':
      case '/knowledgeProposition':
      case '/equationDojo':
      case '/studentIntelligentProposition':
        return 'questioningProcess';
      case '/examPaperList':
      case '/studentExamPaperList':
        return 'examPaperList';
      case '/':
        return 'homePage';
      case '/SearchPaperListPage':
        return 'SearchPaperListPage';
      default:
        return '';
    }
  }, [location]);

  useEffect(() => {
    const main = document.getElementById('mainLayout');
    main.addEventListener('scroll', (e) => {
      setScrollTop(e.target.scrollTop);
    });
  }, []);

  // 針對手機 瀏覽器 上下方工具列 導致vh變化 做調整
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  // 取得 firebase RemoteConfig 資料
  const getConfigData = async () => {
    const data = await getAllRemoteConfig();
    setIsLoading(false);
    setConfigData(data);
  };

  const isRepairing = useMemo(() => {
    if (!configData || JSON.stringify(configData) === '{}') return;
    return JSON.parse(configData?.maintenancePage?._value)?.isRepairing;
  }, [configData]);

  useEffect(() => {
    if (!configData || JSON.stringify(configData) === '{}') return;
    RemoteConfigDataProviderChange(configData);
  }, [configData]);

  // 隨時監控 螢幕寬度並改變 全域參數
  const getScreenWidth = () => {
    const width = document.body.clientWidth;
    deviceDataProviderChange('screenWidth', width);
  };

  useEffect(() => {
    getConfigData();

    window.addEventListener('resize', getScreenWidth);
    return () => {
      window.removeEventListener('resize', getScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (!isRepairing) return;
    if (isRepairing) {
      history.push('/maintenancePage');
    }
  }, [isRepairing]);

  useEffect(() => {
    if (['/', '/login'].includes(location.pathname)) {
      showButton();
    } else {
      hideButton();
    }
  }, [location.pathname]);

  return (
    <DndProvider backend={!deviceData.isDesktop ? TouchBackend : HTML5Backend}>
      <ThemeProvider isPro={isPro}>
        <RouteLeaveProvider>
          <HideOrdinalNumberChaptersProvider>
            <StyledMainLayout
              className={className}
              id="mainLayout"
              data-testid="MainLayout"
              ref={layoutRef}
              isTest={isTest}
              pathname={location.pathname}
            >
              {isLoading ? (
                renderLoadingContent()
              ) : (
                <>
                  <div className="scrollBtnZone">
                    {(scrollPosition === 'bottom' ||
                      scrollPosition === 'middle') && (
                      <div
                        className="scrollBtn"
                        onClick={() => {
                          goTop();
                        }}
                      >
                        <ChevronUpToTopSolid />
                      </div>
                    )}
                    {(scrollPosition === 'top' || scrollPosition === 'middle') && (
                      <div
                        className="scrollBtn"
                        onClick={() => {
                          goBottom();
                        }}
                      >
                        <ChevronDownToBottomSolid />
                      </div>
                    )}
                  </div>
                  <FrontLayout
                    className={addNowPageClassName}
                    header={
                      <>
                        {userInfo?.onePaperIdentity ? (
                          <>
                            {isTest ? (
                              <>
                                <OneTestHeader
                                  logout={logout}
                                  userData={userInfo}
                                  authStateValue={authState.value}
                                  env={env}
                                  history={history}
                                  openEduSubjectModalToggle={
                                    setEduSubjectModalToggle
                                  }
                                />
                                {renderEduSubjectModal(
                                  authState.context.userDetails?.onePaperIdentity
                                )}
                              </>
                            ) : (
                              <>
                                {location.pathname === '/login' ? (
                                  ''
                                ) : (
                                  <StyledPageHeader
                                    isPro={isPro}
                                    nowheight={size.height || null}
                                  >
                                    {isTest ? (
                                      <div></div>
                                    ) : (
                                      <div
                                        className="logo"
                                        onClick={() => {
                                          history.push('/');
                                        }}
                                      >
                                        {isPro ? <img src='/assets/OnePaperProLogo.png' alt="logo"
                                          height="46" /> :
                                          <img
                                            src='/assets/logoNani.svg'
                                            alt="logo"
                                          />}
                                        {isPro && (
                                          <div className="logoProText">專業版</div>
                                        )}
                                      </div>
                                    )}
                                    <div className="headerRightBox">
                                      <div className="pcHeader">

                                        {env === 'dev' && (
                                          <div
                                            className="searchBtn"
                                            onClick={goToSearchPage}
                                          >
                                            <SearchSolid></SearchSolid>搜尋
                                          </div>
                                        )}

                                        {userInfo.identity !== 'Student' &&
                                          location.pathname !== '/' &&
                                          location.pathname !==
                                          '/computerQuestion' &&
                                          location.pathname !==
                                          '/manualProposition' &&
                                          location.pathname !==
                                          '/knowledgeProposition' &&
                                          location.pathname !== '/equationDojo' && (
                                          <>
                                            <Button
                                              borderRadius="24px"
                                              variant={
                                                isPro
                                                  ? 'linearGradient'
                                                  : 'brand1'
                                              }
                                              className="makeATextPaperBtn"
                                              onClick={setEduSubjectModalToggle}
                                            >
                                              <ScrollSolid />
                                                我要出卷
                                            </Button>
                                            {renderEduSubjectModal(
                                              authState.context.userDetails
                                                ?.onePaperIdentity
                                            )}
                                          </>
                                        )}
                                        {location.pathname === '/login' ||
                                          isTest ? (
                                            ''
                                          ) : (
                                            <Dropdown
                                              className="userAvatar"
                                              list={[
                                                (userInfo.identity === 'Admin' ||
                                                userInfo.identity === 'Editor') && {
                                                  name: '後台管理',
                                                  icon: <BackstageManagementSolid />,
                                                  onClick: () => {
                                                    window.location.href = `https://onepaper-cms${env !== 'release'
                                                      ? `-${env}`
                                                      : ''
                                                    }.oneclass.com.tw/`;
                                                  },
                                                },
                                                userInfo.identity === 'Student'
                                                  ? {
                                                    name: '我的練習卷',
                                                    icon: <FolderOpenSolid />,
                                                    onClick: () => {
                                                      history.push(
                                                        '/studentExamPaperList'
                                                      );
                                                    },
                                                  }
                                                  : {
                                                    name: '試卷列表',
                                                    icon: <FolderOpenSolid />,
                                                    onClick: () => {
                                                      history.push('/examPaperList');
                                                    },
                                                  },
                                                {
                                                  name: '個人設定',
                                                  icon: <UserCogSolid />,
                                                  onClick: () => {
                                                    GA.clickUserSetting();
                                                    history.push('/memberCenter');
                                                  },
                                                },
                                                {
                                                  name: '登出',
                                                  icon: <SignOutSolid />,
                                                  onClick: () => {
                                                    setIsLogoutAlertOpen(true);
                                                  },
                                                },
                                              ]}
                                            >
                                              <UiUserBox>
                                                <UserSolid />
                                                {authState.context.userDetails
                                                  ?.userStatus.name || ''}
                                                <CaretDownSolid />
                                              </UiUserBox>
                                            </Dropdown>
                                          )}
                                      </div>
                                    </div>
                                    <div className="mobileHeader">
                                      <input
                                        id="nav-toggle"
                                        type="checkbox"
                                      ></input>
                                      <label htmlFor="nav-toggle" className="ham">
                                        <div className="ham-origin">
                                          <div className="ham-bar ham-bar--top"></div>
                                          <div className="ham-bar ham-bar--middle"></div>
                                          <div className="ham-bar ham-bar--bottom"></div>
                                        </div>
                                      </label>
                                      <StyledMenuBox>
                                        <div className="menuBtnBox">
                                          {userInfo.identity !== 'Student' &&
                                            location.pathname !== '/' &&
                                            location.pathname !==
                                            '/computerQuestion' &&
                                            location.pathname !==
                                            '/manualProposition' &&
                                            location.pathname !==
                                            '/knowledgeProposition' &&
                                            location.pathname !==
                                            '/equationDojo' && (
                                            <>
                                              <Button
                                                borderRadius="24px"
                                                variant={
                                                  isPro
                                                    ? 'linearGradient'
                                                    : 'brand1'
                                                }
                                                className="makeATextPaperBtn"
                                                onClick={setEduSubjectModalToggle}
                                              >
                                                  我要出卷
                                              </Button>
                                              {renderEduSubjectModal(
                                                authState.context.userDetails
                                                  ?.onePaperIdentity
                                              )}
                                            </>
                                          )}
                                          {env === 'dev' && (
                                            <Button
                                              textcolor={isPro ? '#fff' : '#000'}
                                              type="solid"
                                              borderRadius="24px"
                                              className="makeATextPaperBtn searchBtn"
                                              onClick={goToSearchPage}
                                            >
                                              <SearchSolid></SearchSolid>搜尋
                                            </Button>
                                          )}
                                        </div>
                                        <ul className="menu">
                                          <li className="mobileAvatar">
                                            <UserSolid />
                                            {authState.context.userDetails
                                              ?.userStatus?.name || ''}
                                          </li>
                                          {(userInfo.identity === 'Admin' ||
                                            userInfo.identity === 'Editor') && (
                                            <li>
                                              <Link
                                                to={`https://onepaper-cms${env !== 'release' ? `-${env}` : ''
                                                }.oneclass.com.tw/`}
                                              >
                                                <BackstageManagementSolid />
                                                  後台管理
                                              </Link>
                                            </li>
                                          )}
                                          {userInfo.identity === 'Student' ? (
                                            <li>
                                              <Link to={'studentExamPaperList'}>
                                                <FolderOpenSolid />
                                                我的練習卷
                                              </Link>
                                            </li>
                                          ) : (
                                            <li>
                                              <Link to={'examPaperList'}>
                                                <FolderOpenSolid />
                                                試卷列表
                                              </Link>
                                            </li>
                                          )}
                                          <li onClick={GA.clickUserSetting}>
                                            <Link to={'memberCenter'}>
                                              <UserCogSolid />
                                              個人設定
                                            </Link>
                                          </li>
                                          <li>
                                            <div
                                              onClick={() => {
                                                setIsLogoutAlertOpen(true);
                                              }}
                                            >
                                              <SignOutSolid />
                                              登出
                                            </div>
                                          </li>
                                        </ul>
                                        <div className="menuBackground"></div>
                                      </StyledMenuBox>
                                    </div>
                                  </StyledPageHeader>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                    footer={
                      <>
                        <div className="footerLeft">
                          {!isTest && !isPro && (
                            <div>
                              <div>
                                <a href="https://trans.nani.com.tw/NaniTeacher/" onClick={() => GA.clickFooterItem('nani_teacher')}>
                                  南一教師網
                                </a>
                              </div>
                              <div>
                                <a href="https://onebox2.oneclass.com.tw/" onClick={() => GA.clickFooterItem('one_box')}>
                                  OneBox2.0
                                </a>
                              </div>
                              <div>
                                <a href="/fonts" onClick={() => GA.clickFooterItem('fonts')}>
                                  <img src='/assets/fontSolid.svg' alt='' />字型包下載
                                </a>
                              </div>
                            </div>
                          )}
                          <div>
                            © NANI BOOK ENTERPRISE CO.,LTD. All Rights Reserved. v
                            {version || '1.1.1'}
                          </div>
                        </div>
                        <div className="browserSuggestAll" ref={modalRef}>
                          <div
                            className="browserSuggest"
                            onClick={() => {
                              copyrightModalToggle();
                            }}
                          >
                            版權聲明
                          </div>
                          <div
                            className="browserSuggest"
                            onClick={() => {
                              setModalToggle();
                            }}
                          >
                            瀏覽器建議
                          </div>

                          <StyledFooterModal isShow={modalVisible}>
                            <div className="modalContent">
                              <div className="title">
                                <span>瀏覽器建議</span>{' '}
                                <div
                                  className="close"
                                  onClick={() => {
                                    setImportModalFalse();
                                  }}
                                >
                                  <XmarkOutline />
                                </div>
                              </div>

                              <div>
                                為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為：IE
                                Edge、Google Chrome 或 Firefox。
                              </div>
                              <div>
                                如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。
                              </div>
                            </div>
                          </StyledFooterModal>

                          <Modal
                            id={'copyrightModal'}
                            visible={copyrightModalVisible}
                            zIndex={1000}
                            onCancel={copyrightModalFalse}
                            width={!deviceData.isDesktop ? '100%' : '600px'}
                            title={'版權聲明'}
                          >
                            <div className="modalContent">
                              {userInfo.onePaperIdentity !== 'TestCram' ? (
                                <>
                                  <div>
                                    南一雲端出題之內容包括但不限於內容、文字、圖片、聲音、影像、檔案、網站架構、軟體及程式或未來各種形式之利用及衍生著作均為南一書局企業股份有限公司所有或已獲得版權持有者授權使用，並受國際著作權公約以及中華民國法律保護。
                                  </div>
                                  <div>
                                    非經南一書局企業股份有限公司明示之授權或同意，任何人均不得做全部或一部之嵌入、重製、使用、修改、公開播送、公開傳輸、公開演出、改作、引用、散布、發行、公開發表、進行還原工程、解編、出售、出租、互易、出借、或反向組譯等透過網路及其他方式對外公佈。
                                  </div>
                                  <div>
                                    一切其他涉嫌商業利益之公開展示、公開傳輸、出租、出售、互易或任何部份內容之重製，均屬違法之侵權行為，南一書局企業股份有限公司基於維護著作權益之精神，必追究到底，絕無寬待。
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    NaniPaper之內容包括但不限於內容、文字、圖片、聲音、影像、檔案、網站架構、軟體及程式或未來各種形式之利用及衍生著作均為南一書局企業股份有限公司所有或已獲得版權持有者授權使用，並受國際著作權公約以及中華民國法律保護。
                                  </div>
                                  <div>
                                    非經南一書局企業股份有限公司明示之授權或同意，任何人均不得做全部或一部之嵌入、重製、使用、修改、公開播送、公開傳輸、公開演出、改作、引用、散布、發行、公開發表、進行還原工程、解編、出售、出租、互易、出借、或反向組譯等透過網路及其他方式對外公佈。
                                  </div>
                                  <div>
                                    一切其他涉嫌商業利益之公開展示、公開傳輸、出租、出售、互易或任何部份內容之重製，均屬違法之侵權行為，南一書局企業股份有限公司基於維護著作權益之精神，必追究到底，絕無寬待。
                                  </div>
                                </>
                              )}
                            </div>
                          </Modal>
                        </div>
                      </>
                    }
                  >
                    {(authState.value === 'loggedIn' ||
                      authState.value === 'settingEduSubject' ||
                      location.pathname === '/login' ||
                      location.pathname === '/maintenancePage') && (
                      <RenderRoutes routes={routes} props={props} />
                    )}
                  </FrontLayout>
                </>
              )}
            </StyledMainLayout>
            {/* ------ loadingMark ------ */}
            <LogoutAlert
              show={isLogoutAlertOpen}
              onLeave={logout}
              onStay={() => setIsLogoutAlertOpen(false)}
            />
            {renderLoadingMark()}
          </HideOrdinalNumberChaptersProvider>
        </RouteLeaveProvider>
      </ThemeProvider>
    </DndProvider>
  );
};

MainLayout.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.array,
};

import pako from 'pako';

export const formateQuestionList = (data, allQuestion) => {
  let allDiffQuestion = [];
  if (!data) return [];
  Object.entries(data).forEach(([key, value]) => {
    allDiffQuestion = allDiffQuestion.concat(value.map(item => item?.uid));
  });
  return allQuestion.filter(item => allDiffQuestion.includes(item?.uid));
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const hostname = window.location.hostname;
    if (hostname.indexOf('oneclass.com.tw') > 0) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw';
    } else {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
    }
  }
};
export const deleteCookie = (name) => {
  const hostname = window.location.hostname;
  if (hostname.indexOf('oneclass.com.tw') > 0) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw';
  } else {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
  }
};
export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();

  const hostname = window.location.hostname;
  if (hostname.indexOf('oneclass.com.tw') > 0) {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=oneclass.com.tw';
  } else {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
};

export const getCookie = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};


export const arrayUnique = (array) => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
};
export const arrayEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};


export const blobDownload = (url, filename, type) => {
  const xhr = new XMLHttpRequest();
  xhr.open(type, url, true);
  xhr.responseType = 'blob';
  const requestOnload = () => {
    xhr.onload = function (e) {
      if (this.status === 200) {
        const blob = this.response;
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        }, 0);
      }
    };
  };
  if (type === 'POST') {
    xhr.setRequestHeader('Content-type', 'application/json');
    requestOnload();
  }
  if (type === 'GET') {
    requestOnload();
    xhr.send();
  }
};

export const transformTime = (timeString) => {
  let newString = new Date(+new Date(timeString) + 8 * 3600 * 1000).toISOString().replace('T', ' ');
  newString = newString.substring(0, 16);
  return newString;
};

export const shuffleArray = (array) => {
  let newArray = array;
  for (let i = newArray.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export const numberToFirstDecimal = (number) => {
  return Number(Number(number).toFixed(1));
};

// 過濾成 已選取使用的 Book 樹狀圖。
export const formatSelectedBook = (bookList, treeCheckedList) => {
  const knowledgesFilter = (chapter) => {
    if (chapter.data) {
      return {
        ...chapter,
        data: chapter.data.map(chapter_ => knowledgesFilter(chapter_))
      };
    } else if (chapter.Knowledges) {
      return {
        ...chapter,
        Knowledges: chapter.Knowledges
          .filter(knowledge => filterTreeCheckedList
            .map(item => removeCustomUid(item))
            .indexOf(knowledge?.code) !== -1
          )
      };
    } else {
      return {
        ...chapter,
        data: []
      };
    }
  };
  const bookFilter = (chapter) => {
    if (chapter?.Knowledges) {
      return chapter.Knowledges.length > 0;
    } else {
      return chapter.data.filter(chapter_ => bookFilter(chapter_)).some(Boolean);
    }
  };

  if (!bookList || JSON.stringify(bookList) === '{}' || !treeCheckedList) return {};

  // 去除自創UID的，實際勾選知識點陣列。
  const filterTreeCheckedList = treeCheckedList.filter((item) => {
    return typeof item !== 'number';
  });
  const filterBookList = { ...bookList };

  Object.entries(filterBookList).forEach(([key, value]) => {
    // 去除 沒有選到的知識點
    let newBook = {
      ...value,
      chapters: value?.chapters ? value.chapters.map((chapter) => knowledgesFilter(chapter)) : []
    };
    // 去除沒有選到 課本、冊次、章節
    newBook = {
      ...newBook,
      chapters: newBook.chapters.filter(bookFilter)
    };
    // 若完全沒有選取到，則刪除。
    if (newBook?.chapters.length !== 0) {
      filterBookList[key] = newBook;
    } else {
      delete filterBookList[key];
    }
  });

  const res = Object.entries(filterBookList).map(([key, value]) => {
    return value;
  });

  return res;
};

// 過濾 已選取的知識向度範圍。
export const formatSelectedKnowledge = (knowledgeList, treeCheckedList) => {
  if (!knowledgeList || JSON.stringify(knowledgeList) === '{}' || !treeCheckedList) return [];
  const { topics, categories } = knowledgeList;
  // 去除自創UID的，實際勾選知識點陣列。
  const filterTreeCheckedList = treeCheckedList.filter((item) => {
    return typeof item !== 'number';
  });
  let res = [];
  if (topics) {
    // 數理科
    const nextData = topics.map((topic) => {
      let subTopics = topic?.SubTopics ? topic.SubTopics.map((subTopic) => {
        const knowledgeArray = subTopic?.Knowledges ? subTopic.Knowledges.filter((knowledge) => {
          return filterTreeCheckedList.indexOf(knowledge?.code) !== -1;
        }) : [];
        return {
          name: subTopic?.Name,
          knowledges: knowledgeArray.length !== 0 ? knowledgeArray : [],
          code: subTopic?.Code,
        };
      }) : [];
      subTopics = subTopics.length !== 0 ? subTopics.filter((item) => {
        return item?.knowledges.length !== 0;
      }) : [];
      return {
        name: topic?.Name,
        subTopics,
        code: topic?.Code,
      };
    });
    res = nextData.filter((item) => {
      return item?.subTopics.length !== 0;
    });
  } else {
    // 語文科
    if (!categories) return [];
    res = categories.filter((category) => {
      return filterTreeCheckedList.indexOf(category?.code) !== -1;
    });
  }

  return res;
};

// 自動配分至 接近100分。（ 100 / 總答數 ＝ 每答分數）
// export const autoSettingScore = (questionGroups, totalAnswer) => {
//   if (!questionGroups || questionGroups.length === 0 || !totalAnswer) return [];
//   // 若總答數 大於1000，將不會進行自動配分（因最小配分為0.1分）
//   if (totalAnswer > 1000) return questionGroups;

//   // 無條件捨去法，能控制到小數點 第幾位。
//   const roundDown = ( num, decimal ) => {
//     return Math.floor( ( num + Number.EPSILON ) * Math.pow( 10, decimal ) ) / Math.pow( 10, decimal );
//   };

//   const perAnswerScore = roundDown((100 / totalAnswer), 1);
//   const nextData = questionGroups.map((quesType) => {
//     return {
//       ...quesType,
//       score: perAnswerScore,
//       scoreType: 'PerAnswer',
//     };
//   });
//   return nextData;
// };

// 自動配分至 接近100分。（ 100 / 總答數 ＝ 每答分數）
export const autoSettingScore = (tableData, keyList, totalAnswer) => {
  if (!tableData || JSON.stringify(tableData) === '{}') return {};
  if (!keyList || keyList.length === 0) return {};
  if (!totalAnswer) return {};
  // 若總答數 大於1000，將不會進行自動配分（因最小配分為0.1分）
  if (totalAnswer > 1000) return tableData;

  // 無條件捨去法，能控制到小數點 第幾位。
  const roundDown = (num, decimal) => {
    return Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
  };

  const perAnswerScore = roundDown((100 / totalAnswer), 1);

  const nextData = { ...tableData };

  keyList.forEach((questionTypeName) => {
    nextData[questionTypeName] = {
      ...nextData[questionTypeName],
      inputValue: {
        ...nextData[questionTypeName]?.inputValue,
        score: {
          allScore: numberToFirstDecimal(nextData[questionTypeName]?.inputValue?.answer * perAnswerScore),
          perAns: perAnswerScore,
          perQues: '',
        }
      }
    };
  });

  // const nextData = questionGroups.map((quesType) => {
  //   return {
  //     ...quesType,
  //     score: perAnswerScore,
  //     scoreType: 'PerAnswer',
  //   };
  // });
  return nextData;
};

// 解壓縮 gzip
export const unGzip = (data) => {
  const strData = atob(data);
  // split it into an array rather than a "string"
  const charData = strData.split('').map((x) => {
    return x.charCodeAt(0);
  });
  // convert to binary
  const binData = new Uint8Array(charData);
  // inflate
  const result = pako.inflate(binData, { to: 'string' });

  return JSON.parse(result);
};

// 重新將 題目資訊 轉變為跟最一開始一樣的名稱 (隨著gzip時欄位更改名稱)
export const formateQuestionObject = (array) => {
  if (!array || array.length === 0) return [];

  const nextData = [].concat(array).map((question) => {

    const { uid, _c, _i, _md, _qg } = question;
    const { _a, _d, _k, _lc, _qt, _s, _ss, _ks, _sf } = _md;

    return {
      uid: uid, // 題目代碼
      content: _c, // 題目內容
      image: _i, // 題目圖檔
      questionGroup: _qg, // 題型種類
      metaData: {
        answer: _a, // 答案數
        difficulty: _d, // 難易度
        // knowledge: _k, // 知識向度
        learnContent: _lc, // 學習內容
        questionType: _qt, // 題型
        source: _s, // 出處
        subSource: _ss, // 來源
        knowledges: _ks, // 知識向度(陣列 複數)
        soundFiles: _sf, // 音檔
      },
    };
  });

  return nextData;
};

// 知識向度陣列 轉換成 知識向度 顯示字串，for 試題選擇頁。
export const knowledgeArrayToString = (array) => {
  let str = '';
  array.forEach((knowledge, index) => {
    str += index === 0 ? knowledge?.name : `、${knowledge?.name}`;
  });
  return str;
};

// 存入 localStorage。
export const setLocalStorage = (key, value) => {
  if (!key || !value) return;
  localStorage.setItem(key, JSON.stringify(value));
};

// 取出 localStorage。
export const getLocalStorage = (key) => {
  if (!key) return null;
  return (localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : null;
};

// 計算分數，將數值鎖在小數點後第一位。
export const numberToFirst = (number) => {
  let val = Number(Number(number).toFixed(1));
  return val;
};

// 試卷、測驗名稱，特殊字元處理
export const formatSpecialCharacters = (string) => {
  let newValue = string;
  const rule_1 = new RegExp(/[\\/|?|>|<|\s|\\|{|}|\\|]/g);
  const rule_2 = new RegExp(/[:]/g);
  const rule_3 = new RegExp(/[*]/g);

  newValue = newValue.replace(rule_1, '_');
  newValue = newValue.replace(rule_2, '：');
  newValue = newValue.replace(rule_3, '＊');

  return newValue;
};

export const CUSTOM_UID = 'CUSTOM_UID';

export const getCustomUid = (chapter, knowledge) =>
  chapter.code + chapter.name + CUSTOM_UID + knowledge.code;

export const removeCustomUid = (string) => {
  const codePosition = string.indexOf(CUSTOM_UID) + CUSTOM_UID.length;
  return string.substring(codePosition);
};

export const getSources = (chapter) => {
  if (chapter.data) {
    return chapter.data.map(chapter_ => getSources(chapter_)).flat();
  } else if (chapter.Knowledges) {
    return chapter.Knowledges.map(knowledge => getSources(knowledge)).flat();
  } else {
    return chapter.sources || [];
  }
};

//可選題目做隨機排序
export const shuffle = (array) => {
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]
    ];
  }
  return array;
};

export const downloadByUrl = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
};

/**
 * 將秒數格式化成 H:m:s
 */
export const formatSeconds = (seconds) => {
  const hhmmssString = new Date(seconds * 1000).toISOString().slice(11, 19);
  const [h, m, s] = hhmmssString
    .split(':')
    .map(string => parseInt(string, 10));
  return `${h ? `${h}:` : ''}${m}:${String(s).padStart(2, '0')}`;
};

// 英聽可用學年度
export const schoolYearOptions = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear() - 1911;
  const month = currentDate.getMonth();
  const date = currentDate.getDate();
  // 如果是八月的第二週以後，就可以選下個學年度
  const schoolYears = (month === 7 && date >= 8) || month > 7
    ? [year - 1, year]
    : [year - 1];
  return schoolYears.map(year => ({ name: String(year), value: String(year) }));
};

/**
 * 特定版本、學制、科目、章節不需顯示序數
 */
export const shouldHideOrdinalNumber = (chapterName, version, eduSubject) => {
  const MAP = {
    N: {
      ECH: ['總複習', '語文天地', '統整活動'],
      JPC: ['自學', '語文常識'],
      HPC: ['自學'],
    },
    K: {
      ECH: ['複習', '統整活動', '閱讀階梯', '學習地圖'],
      JPC: ['自學', '語文常識'],
    },
    H: {
      ECH: ['統整活動', '閱讀課', '愛閱讀'],
      JPC: ['自學', '語文常識'],
    },
  };
  return Boolean(MAP?.[version]?.[eduSubject]?.some(chapter => chapterName.includes(chapter)));
};

export const safelyJSONParse = (string) => {
  try {
    return JSON.parse(string);
  } catch (error) {
    return null;
  }
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledAudioPlayer } from './AudioPlayer.style';
import { formatSeconds } from 'utils/common';

const CURRENT_TIME_STEP = 0.1;

export const AudioPlayer = ({ className, src, defaultDuration }) => {
  const [duration, setDuration] = useState(null);
  const [audio, setAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audio = new Audio(src);
    setAudio(audio);

    const handleLoadedmetadata = (e) => {
      setDuration(e.target.duration);
    };
    audio.addEventListener('loadedmetadata', handleLoadedmetadata);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedmetadata);
    };
  }, []);

  useEffect(() => {
    if (!audio) return;
    let interval;
    const handlePlay = () => {
      setIsPlaying(true);
      interval = setInterval(() => {
        setCurrentTime((prev) => prev + CURRENT_TIME_STEP);
      }, CURRENT_TIME_STEP * 1000);
    };
    const handlePause = () => {
      setIsPlaying(false);
      clearInterval(interval);
    };
    const handleEnded = () => {
      setIsPlaying(false);
      clearInterval(interval);
      setCurrentTime(0);
    };
    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
      clearInterval(interval);
    };
  }, [audio]);

  return (
    audio && <StyledAudioPlayer
      className={className}
      data-testid="AudioPlayer"
      onClick={() => isPlaying ? audio.pause() : audio.play()}
    >
      {isPlaying ? (
        <img src="/assets/pauseCircle.svg" alt="暫停" />
      ) : (
        <img src="/assets/playCircle.svg" alt="播放" />
      )}
      <div>
        {formatSeconds(currentTime)} / {formatSeconds(defaultDuration || Math.floor(duration))}
      </div>
    </StyledAudioPlayer>
  );
};

AudioPlayer.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  defaultDuration: PropTypes.number.isRequired,
};

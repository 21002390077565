import styled, { css } from 'styled-components';

export const StyledMenuBox = styled.div`
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 301;
    transition: .15s;
    .menuBtnBox{
        position: relative;
        background: #fff;
        z-index: 3;
        padding: 24px;
        & > div:last-child{
            margin-top: 16px;
        }
    }
    .menu {
        position: relative;
        background: ${({ theme }) => theme.colors.ui.ui_01};
        z-index: 2;
        padding: 0 24px 24px 24px;
        li {
            padding:16px 0;
            padding-left: 25px;
            a,div {
            display: flex;
            align-items: center;
            color:${({ theme }) => theme.colors.text.text_08};
            &:hover {
                background: #5d5d5d;
                color: #fff;
            }
            }
            &:first-child{
                padding-left: 10px;
                padding-bottom: 16px;
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
            }
        }
    }
    .menuBackground{
        background: #000000;
        opacity: 0.6;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
`;


export const StyledMainLayout = styled.div`
/* Code Here */
background-color: ${({ theme }) => theme.colors.ui.white};
overflow: auto;
overflow-y: overlay;
#FrontLayout{
    width: unset;
    .container{
        /* width: 1024px; */
        /* margin: 3.5rem auto; */
        ${({ pathname }) =>
    (pathname === '/') ?
      css`
            width: 100%;
            margin: 0 0 !important;
        `
      :
      css`
            width: 1024px;
            margin: 3.5rem auto;
        `
}
        @media (max-width: 1024px) {
            width: 100%;
            margin: 0 0;
            .content{
                margin: 0 0;
            }
        }

        ${({ isTest, pathname }) =>
    isTest && (pathname === '/examPaperList') &&
        css`
           margin: 0 auto;
    `}
    }
    .footer{
        padding: 20px 40px 16px 40px;

        ${({ pathname }) =>
    (pathname === '/') &&
      css`
            margin-top: 12px;
        `
}
    }

    ${({ isTest }) =>
    isTest &&
		css`
			.footer{
                height: unset;
            }
	`}

    ${({ pathname }) =>
    (pathname === '/maintenancePage') &&
        css`
           .header{
                display: none !important;
            }
           .container{
               width: unset !important;
               margin:  auto auto !important;
           }
           .footer{
               display: none !important;
           }
    `}

    .mobileModal{
        .ant-modal{
            @media (max-width: 1024px) {
                width: 100% !important;

                .ant-modal-content{
                    width: 100%;
                }
            }
        }
    }
    .markBox{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        z-index: 2799;
        opacity: 0.7;
        .loadingIcon{
            z-index: 2800;
        }
    }
}
.homePage{
    .container{
        margin: 0 auto !important;
    }
}
.isShowModal{
		height: 100vh;
		overflow: hidden;
	}
svg{
        margin-right:8px
    }
    .rightIcon{
        margin-right:0px;
        margin-left: 8px
    }
    font-size: 14px;
    .browserSuggestAll{
        position: relative;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .browserSuggest{
        cursor: pointer;
        padding: 0px 16px;
        
        @media (max-width: 1024px) {
            padding: 16px 16px;
        }
        transition: .5s;
        display: inline-flex;
        &:hover{
            border-radius: 2px;
            background-color: ${({ theme }) => theme.colors.ui.ui_01};
            
        }
    }
    .container{
        align-items: flex-start;
        @media (max-width: 1024px) {
            margin: 0 0;
        }
    }
    .loginPage{
        > .container {
            > .content {
                min-height: calc(100vh - 212px);
                margin: 0 20px 0;
                .scroll{
                    > div{
                        min-height: calc(100vh - 212px);
                    }
                }
            }
        }
    }

    .draw{
        position: relative;
        color: ${({ theme }) => theme.colors.text.white};
        background-color: ${({ theme }) => theme.colors.brand.brand02};
        border-radius: 8px 8px 0px 0px;
        padding: 0 0;
        min-width: 180px;
        .drawBtn{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            height: 100%;
            padding: 12px 4px;
        }
        .drawList{
            position: absolute;
            width: 125%;
            background-color: ${({ theme }) => theme.colors.ui.white};
            color: ${({ theme }) => theme.colors.text.black};
            top: 100%;
            left: 0;
            z-index: 10;
            padding: 16px;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            .drawRow{
                .drawListTitle{
                    font-weight: 500;
                    font-size: 18px;
                }
                .drawItemList{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
    .testBtnList{
        position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 10;
    }
    .scrollBtnZone{
        display: ${({ pathname }) => (pathname === '/maintenancePage' || pathname === '/') ? 'none' : ''};
        position: fixed;
        z-index: 300;
		bottom: 44px;
		right: 28px;
        @media (max-width: 1024px) {
            display: none;
        }
    }
    .scrollBtn{
		cursor: pointer;
		display:flex;
		align-items: center;
		justify-content: center;
		
		background: ${({ theme }) => theme.colors.ui.ui_03};
		padding: 12px;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.ui.white};
		
		svg{
			margin-right: 0;
    		font-size: 24px;
		}
        & + div{
            margin-top:16px ;
        }
	}
    .modalContent{
		color:${({ theme }) => theme.colors.text.text_06}
	}
    .footerBtn{
		display: flex;
		&> *:first-child{
			margin-right: 8px;
		}
	}
    .footerLeft{
        padding: 0px 16px;
        @media (max-width: 1024px) {
            text-align: center;
        }
        >div:first-child{
            font-size: 14px !important;
            display:flex;
            justify-content: flex-start;
            @media (max-width: 1024px) {
                justify-content: center;
                flex-wrap: wrap;
            }
            >div{
                margin-left: 24px;
                margin-bottom: 16px;
                >a{
                    color: ${({ theme }) => theme.colors.text.text_06} ;
                    &:hover{
                        color: ${({ theme }) => theme.colors.text.text_08} ;
                    }
                }
            }
            >div:last-child{
                margin-left: 24px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                >a{
                    color: ${({ theme }) => theme.colors.scale.$brand01.$brand01_10} ;
                    >img {
                        margin-bottom: 4px;
                    }
                }

            }
            >div:first-child{
                margin-left: 0;
            }
        }
    }

    .mobileHeader{
        display: none;
        .ham {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            /* background: rgba(0,0,0,.3); */
            display: block;
            position: relative;
            }

            .ham-origin {
            position: absolute;
            top: 50%;
            left: 50%;
            }

            .ham-bar {
            height: 3px;
            width: 20px;
            position: absolute;
            border-radius: 4px;
            margin: -1px 0 0 -10px;
            transition: all .15s;
            background: ${({ theme }) => theme.colors.brand.brand02};
            }

            .ham-bar--top {
            transform: translateY(-6px);
            }

            .ham-bar--bottom {
            transform: translateY(6px);
            }

            ul {
                list-style: none; 
                padding-left: 0; 
            }
            
            
            

            #nav-toggle {
            display: none;
            }

            #nav-toggle:checked ~ .ham .ham-bar--middle {
            opacity: 0;
            }

            #nav-toggle:checked ~ .ham .ham-bar--top {
            transform: rotate(-45deg);
            }

            #nav-toggle:checked ~ .ham .ham-bar--bottom {
            transform: rotate(45deg);
            }   
            
            #nav-toggle:checked ~ ${StyledMenuBox}{
                height: ${({ nowheight }) => `${nowheight}px` || '100vh'};
            }
    }
    .pcHeader{
        display: flex;
    }
    @media (max-width: 1024px) {
        .mobileHeader{
            display: flex;
        }
        .pcHeader{
            display: none;
        }
		.questioningProcess{
            ${({ pathname }) =>
    pathname === '/computerQuestion' ?
      css`
    .content{
        padding-bottom: 170px;
    }
` :
      css`
    .content{
        padding-bottom: 120px;
    }
`
}
            .footer{
                display: none;
            }
        } 
        .examPaperList {
            .footer{
                display: none;
            }
        }
        .SearchPaperListPage{
            .header, .footer{
                display: none;
            }
        }
    }
    #copyrightModal{
        .modalContent{
            text-align: left;
            color: ${({ theme }) => theme.colors.text.text_08};
            >div:not(:last-child){
                margin-bottom: 20px;
            }
        }
        @media (max-width: 1024px) {
            .ant-modal{
                position: absolute;
                bottom: 0;
                max-width:100%;
                padding: 0 0;
                margin: 0 0;
            }
            .ant-modal-content{
                position: absolute;
                bottom: 0;
                border-radius: 12px 12px 0 0;
                width: 100vw;
                height: 97vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
                height: calc(var(--vh, 1vh) * 97);
                .ant-modal-close{
                    right: 0;
                }
            }
            .ant-modal-header{
                display: flex;
                justify-content:center;
                align-items:center;
                padding: 21px 40px 21px;
            }
            .ant-modal-close-x{
                display: flex;
                justify-content:center;
                align-items:center;
                width: 50px;
                height:63px;
            }
            .ant-modal-body{
                padding: 16px 24px 16px;
                background-color: #fff;
                height: calc(calc(var(--vh, 1vh) * 97) - 64px);
                overflow: auto;
            } 
        }
    }
    >.loadingContent{
        height: calc(var(--vh, 1vh) * 100);
    }
`;
export const StyledDrawList = styled.div`
    position: absolute;
    width: 125%;
    background-color: ${({ theme }) => theme.colors.ui.white};
    color: ${({ theme }) => theme.colors.text.black};
    top: 100%;
    left: 0;
    z-index: 10;
    padding: 0 16px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 0;
    transition: all 0.15s ease-out;
    overflow: hidden;

    ${({ isshow }) =>
    isshow &&
		css`
            padding:16px;
			max-height: 1000px;
            transition: all 0.25s ease-in;
		`}
            .drawRow{
                .drawListTitle{
                    font-weight: 500;
                    font-size: 18px;
                }
                .drawItemList{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
`;

export const StyledFooterModal = styled.div`
display: ${({ isShow }) => isShow ? 'block' : 'none'};
text-align:left;
    position: absolute;
    top: -225px;
    right:0;
    width: 268px;
    padding: 8px 16px 0px;
    background: ${({ theme }) => theme.colors.ui.ui_01};
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_01};
    border-radius: 8px;
    .modalContent{
        position: relative;
        >div{
            margin-bottom: 16px;
        }
        .title{
            display: flex;
            justify-content: space-between;
            /* margin-bottom: 8px; */
            color:  ${({ theme }) => theme.colors.ui.ui_07};
            .close{
                cursor: pointer;
            }
        }
    }
`;

export const StyledStudentHeader = styled.div`
    display: flex;
    padding: 0px 64px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    .logo{
        padding: 25px 52px 25px 0;
    }
    .headerSearch{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        & > .ant-input-group-wrapper{
            width: auto;
        }
    }
`;
export const StyledLink = styled.div`
    a{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 100%;
        flex:1;
        color: ${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.text.text_06};
        &.disabled-link {
            pointer-events: none;
        }
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            svg{
                font-size: 20px;
            }
            &::after{
                content: '';
                background-color: ${({ theme }) => theme.colors.scale.$brand01.$brand01_10};
                height: ${({ active }) => active ? '4px' : '0px'};
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        
    }
    
    
`;
export const StyledNavigationBar = styled.div`
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    & > div{
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        cursor: pointer;
        /* padding: 13px 0; */
        color: ${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.text.text_06};
        :last-child{
            &::before{
                content: '';
                height: 50%;
                width: 1px;
                position: absolute;
                left: 0;
                top: 25%;
                background-color: ${({ theme }) => theme.colors.ui.ui_04};
            }
        }
    }
    
    
`;

export const StyledStudentNavigationBar = styled.div`
    display: flex;
    align-items: flex-end;
    flex: 0.8;
    ${StyledNavigationBar}{
        box-shadow:none;
        width: 100%;
    }
`;

export const StyledDrawItem = styled.div`
    margin: 4px;
    padding: 0px 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: ${({ theme, active }) => active ? theme.colors.text.white : theme.colors.text.black};
    background-color: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.ui.white};
    :hover{
        background: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.ui.ui_02};
        color: ${({ theme, active }) => active ? theme.colors.text.white : theme.colors.text.black};
    }
`;

export const UiUserBox = styled.div`
	display: flex;
	align-items: center;
    padding: 9px 8px;
    border-radius: 12px;
    gap: 8px;
    color: ${({ theme }) => theme.colors.ui.ui_07};
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_05};

    svg:first-child {
        color: ${({ theme }) => theme.colors.ui.ui_07};
        margin: 0%;
    }
    svg:last-child {
        color: ${({ theme }) => theme.colors.ui.ui_05};
    }

	img {
		overflow: hidden;
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	&:hover {
        background: ${({ theme }) => theme.colors.ui.ui_04};
		opacity: 0.8;
	}
`;

export const StyledPageHeader = styled.div`
    background-color: ${({ isPro, theme }) => isPro ? theme.colors.brand.brand02 : theme.colors.ui.white };
            padding: 15px 40px;
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            position: relative;
    .searchBtn{
        /* display: none !important; */
    }
        .logo{
            cursor: pointer;
            display: flex;
            align-items: center;
            .logoProText{
                font-size: 18px;
                color:${({ theme }) => theme.colors.pro.text.color};
                margin-left:4px;
                padding-top: 6px;
                @media (max-width: 1024px) {
                    font-size: 12px;
                }
            }
        }
        
        .headerRightBox{
            display: flex;
            justify-content: space-around;
            align-items: center;
            >div>div{
                margin: 0 20px;
            }
            .searchBtn{
                cursor: pointer;
                display: flex;;
                justify-content: center;
                align-items: center;
                transition: .5s;
                color: ${({ theme, isPro }) => isPro ? theme.colors.ui.white : theme.colors.text.text_06};
                &:hover{
                    color: ${({ theme, isPro }) => isPro ? theme.colors.text.text_06 : theme.colors.text.text_08};
                }
            }
            .makeATextPaperBtn{
                padding:8px 16px;

                svg {
                    font-size: 16px;
                }
            }
            .userAvatar{
                height: 100%;
                display: flex;
                align-items: center;
                /* padding: 8px 20px; */
                transition: .5s;
                margin-right: 30px;
                color: ${({ theme, isPro }) => isPro ? theme.colors.pro.text.color : theme.colors.text.text_06};
                cursor: pointer;
                :hover{
                    /* background-color:${({ theme }) => theme.colors.scale.$brand01.$brand01_09}; */
                    color: ${({ theme, isPro }) => isPro ? theme.colors.pro.text.hover : theme.colors.text.text_06} !important;
                }
                >div:nth-child(1):hover{
                    opacity: 1 !important;
                    color: ${({ theme, isPro }) => isPro ? theme.colors.pro.text.hover : theme.colors.text.text_08} !important;
                }
            }
        }
        .mobileHeader{
        display: none;
        .ham {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            /* background: rgba(0,0,0,.3); */
            display: block;
            position: relative;
            }

            .ham-origin {
            position: absolute;
            top: 50%;
            left: 50%;
            }

            .ham-bar {
            height: 3px;
            width: 20px;
            position: absolute;
            border-radius: 4px;
            margin: -1px 0 0 -10px;
            transition: all .15s;
            background: ${({ theme, isPro }) => isPro ? theme.colors.ui.white : theme.colors.brand.brand02};
            }

            .ham-bar--top {
            transform: translateY(-6px);
            }

            .ham-bar--bottom {
            transform: translateY(6px);
            }

            ul {
                list-style: none; 
                padding-left: 0; 
            }
            
            
            

            #nav-toggle {
            display: none;
            }

            #nav-toggle:checked ~ .ham .ham-bar--middle {
            opacity: 0;
            }

            #nav-toggle:checked ~ .ham .ham-bar--top {
            transform: rotate(-45deg);
            }

            #nav-toggle:checked ~ .ham .ham-bar--bottom {
            transform: rotate(45deg);
            }   
            
            #nav-toggle:checked ~ ${StyledMenuBox}{
                height: ${({ nowheight }) => `${nowheight}px` || '100vh'};
            }
    }
    .pcHeader{
        display: flex;
        align-items: center;
    }

    .list-btn {
        border: ${({ theme }) => `1px solid ${theme.colors.scale.$brand01.$brand01_10}`};
        border-radius: 24px;
        color: ${({ theme }) => theme.colors.scale.$brand01.$brand01_10};
        padding: 9px 16px;

        svg {
            font-size: 16px;
        }
    }
    @media (max-width: 1024px) {
        padding: 14px 15px 14px 24px;
        .mobileHeader{
            display: flex;
        }
        .pcHeader{
            display: none;
        }
        .menu{
            >li>a:hover{
                background: unset;
            }
        }
    }
    ${StyledMenuBox}{
        .menuBtnBox,.menu{
            background-color: ${({ theme, isPro }) => isPro ? theme.colors.brand.brand02 : theme.colors.ui.white};
            
        }
        .menu{
            li{
                a,div{
                    color:${({ theme, isPro }) => isPro ? theme.colors.ui.white : theme.colors.text.text_08};
                }
                
            }
            .mobileAvatar{
                color:${({ theme, isPro }) => isPro ? theme.colors.pro.text.color : theme.colors.text.text_08};
            }
        }
        
    }
`;
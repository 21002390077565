import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Button } from '@oneclass/onedesign';
import { CopySolid } from '@onedesign/icon';
import { StyledHintModal } from 'containers/ExamPaperListContent/ExamPaperListContent.style';
import deviceProvider from 'providers/deviceProvider';

export const DownloadFailedModal = ({ downloadFailedPaperId, closeModal }) => {
  const { deviceData } = useContext(deviceProvider.deviceProviderContext);

  return <StyledHintModal
    visible={downloadFailedPaperId}
    title='紙本卷組卷失敗'
    className='downloadFailed'
    onCancel={closeModal}
    centered={deviceData.isDesktop}
    footer={
      <Button
        variant='brand2'
        onClick={closeModal}
        className='submit'
      >
        了解
      </Button>
    }
  >
    <div>紙本卷在組卷過程發生問題，請至首頁右下角客服聊天室，回報您遇到的情況，或直接聯繫我們，將有專員盡快提供協助。</div>
    <div className='paperId'>
      <span>卷號：{downloadFailedPaperId}</span>
      <Tooltip
        trigger='click'
        title={
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
            <img src='/assets/checkCircle.svg' alt='' />
            <span>已複製</span>
          </div>
        }
        onVisibleChange={(visible) => {
          if (visible) {
            navigator.clipboard.writeText(downloadFailedPaperId);
          }
        }}
      >
        <div style={{ height: '24px', cursor: 'pointer' }}><CopySolid color='#A1A4B1' fontSize='24px'/></div>
      </Tooltip>
    </div>
    <div className='hint'>您可以在試卷列表再次取得卷號。</div>
  </StyledHintModal>;
};

DownloadFailedModal.propTypes = {
  downloadFailedPaperId: PropTypes.string,
  closeModal: PropTypes.func,
};

import React, { useState, useEffect, useContext } from 'react';
import { Box, AuthProvider, CreateExamPaperParamsProvider } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { getCookie, deleteCookie } from 'utils/common';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { StyledHomePage } from './HomePage.style';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useHistory } from 'react-router-dom';
import { Announcement } from 'components';
import { default as UserProvider } from 'providers/UserProvider.js';

export const HomePage = ({ className }) => {
  const history = useHistory();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData } = useContext(
    deviceProvider.deviceProviderContext
  );
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { renderEduSubjectModal, setEduSubjectModalToggle } =
    useEduSubjectModal();
  const { createExamPaperParamsChange } = useContext(
    CreateExamPaperParamsProvider.createExamPaperContext
  );
  const [isShowHint, setIsShowHint] = useState(true);
  const { userData } = useContext(UserProvider.UserProviderContext);
  const { userInfo } = userData;
  const [isShowBanner, setIsShowBanner] = useState(false);
  useEffect(() => {
    if (
      authState.value === 'loggedIn' ||
      authState.value === 'settingEduSubject'
    ) {
      if (getCookie('createNewPaperBackHome') !== null) {
        setEduSubjectModalToggle();
        deleteCookie('createNewPaperBackHome');
        deleteCookie('createNewPaper');
      }
    }
  }, [authState.value]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.onePaperIdentity === 'TestCram')
        history.push('/examPaperList');
    }
  }, [userInfo]);

  return (
    <StyledHomePage className={className} data-testid="HomePage">
      {isShowHint && <Announcement setIsShowHint={setIsShowHint} />}
      <Box>
        {isShowBanner && userInfo.userType === 'General' && (
          <div className="banner">
            <div className="bannerContent">
              <div className="bannerNew">
                <p>NEW</p>
              </div>
              <div className="title">無限命題</div>
              <div className="description">
                以題目類型選題出卷，快速選擇大量同類型題目！
              </div>
              <div className="onlyMath">
                <p>國中數學限定</p>
              </div>
              <button
                className="btn"
                onClick={() => {
                  createExamPaperParamsChange('J', 'MA', 'Files', {
                    edu: '國中',
                    subject: '數學',
                  });
                  history.push('/equationDojo');
                }}
                variant="primary2"
              >
                立即體驗
              </button>
            </div>
            <div
              className="bannerCancel"
              onClick={() => {
                setIsShowBanner(false);
              }}
            >
              <img src="assets/cancel.png" alt="cancel" />
            </div>
          </div>
        )}
        <div className="start">
          <div className="startContent">
            <div className="title">雲端題庫</div>
            <div className="title">無光碟綠校園</div>
            <div className="text">輕鬆組卷 快速出題</div>
            <div className="btn">
              <Button className='start-book'
                onClick={() => {
                  setEduSubjectModalToggle();
                }}
              >
                開始出卷
              </Button>
              <Button className='start-list'
                onClick={() => {
                  history.push('/examPaperList');
                }}
              >
                試卷列表
              </Button>
            </div>
          </div>
          <div className="startImage">
            {!deviceData.isDesktop ? (
              <img src="assets/startImage.svg" alt="startImage" />
            ) : (
              <img src="assets/startImage_large.svg" alt="startImage" />
            )}
          </div>
        </div>
      </Box>
      {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
    </StyledHomePage>
  );
};

import styled, { css } from 'styled-components';

export const StyledHomeQuestionCompleteContent = styled.div.attrs(props => ({
  className: props.className
}))`
    padding: 76px 0;
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    border-radius: 8px;

    @media (max-width: 1024px) {
        border: none
    }

    .pageTitle{
        font-size: 24px;
        font-weight:500;
    }
    .hint{
        text-align: center;
        color: ${({ theme }) => theme.colors.text.text_09};
        span{
            color: #4C99A5;
            border-bottom: 1px solid #4C99A5;
            cursor: pointer;
        }
        @media (max-width: 1024px) {
            text-align: center;
        }
    }
    > div{
        display: flex;
        justify-content: center;
    }
    .titleRow{
        text-align: center;
    }
    .textRow{
        text-align: center;
        color: ${({ theme }) => theme.colors.text.text_09};
    }
    .load-wrapp {
        width: 100px;
    }
    .bar {
        float: left;
        width: 15px;
        height: 4px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.ui.ui_04};
    }

    .load-10 .bar {
        animation: loadingJ 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    }
    .fixedBottomBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .brand-btn {
            background: #EC7963;
            width: 296px;
            height: 44px;
            color: white;
            border-radius: 12px;
            padding: 10px 16px;
            border-bottom: 4px solid #CF492F;
            font-size: 20px;
            font-weight: 700;

            &:hover {
                background: #E46850;
            }
            &:active {
                background: #EC7963;
                border-bottom: none;
            }
        }
    }
    .btnBox{
        display: flex;
        >div{
            width: 142px;
            height: 44px;
            color: white;
            border-radius: 12px;
            padding: 10px 16px;
            border-bottom: 4px solid #3B9318;
            font-size: 20px;
            font-weight: 700;

            &:not(:last-child){
                margin-right: 12px;
            }

            @media (max-width: 1024px){
                width: 50%;
            }
        }
        .again-btn {

            background: #3983FF;
            border-bottom: 4px solid #2C66C2;
            &:hover {
                background: #1E55AA;
            }
            &:active {
                background: #3983FF;
                border-bottom: none;
            }
        }

        .list-btn{
            background: #53C228;

            &:hover {
                background: #3B9318;
            }
            &:active {
                background: #53C228;
                border-bottom: none;
            }
        }


        
    }

    .controlBtnBox{
        >div{
            background: #EC7963;
            width: 296px;
            height: 44px;
            color: white;
            border-radius: 12px;
            padding: 10px 16px;
            border-bottom: 4px solid #CF492F;
            font-size: 20px;
            font-weight: 700;

            &:hover {
                background: #E46850;
            }
            &:active {
                background: #EC7963;
                border-bottom: none;
            }
        }
    }

    @keyframes loadingJ {
        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(80px, 0);
            
            width: 30px;
        }
    }
    @media (max-width: 1024px) {
        .fixedBottomBox{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1003;
            background-color: #fff;
            padding: 8px 24px 35px 24px;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
            >div{
                width: 100%;
            }
        }
    }
`;
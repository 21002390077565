import React, { useMemo, useState, useEffect, useContext } from 'react';
import { StudentIpAreaSelectContent, StudentIpExamSettingContent, StudentIpExamCompleteContent } from 'containers';
import {
  StepBox,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  CreateExamPaperParamsProvider,
  AuthProvider,
  MobileStepBox,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { ExamPaperHeader } from 'components';
import { Button } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { setCookie, getCookie, formatSelectedBook } from 'utils/common';
import { getDrawUpAutoCacheQuery } from 'api/drawUpAuto';
import { postCreatePractice } from 'api/practice';
import { useHistory } from 'react-router-dom';
import { StyledStudentIntelligentPropositionPage, StyledPageContent, UiBtnZone } from './StudentIntelligentPropositionPage.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { postGetQuestionBucket } from 'api/query';
import { postExamCreate } from 'api/examPaper';

const steps = [
  {
    title: '範圍選擇',
  },
  {
    title: '測驗設定',
  },
  {
    title: '出卷完成',
  },
];
const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};

const initSaveData = {
  education: '',
  subject: '',
  areaParams: {
    filterUsed: false
  },
  bookOptions: [],
};
export const StudentIntelligentPropositionPage = ({
  className,
  props,
}) => {
  // const { isDesktop } = useDeviceDetect();
  const { setIsLoadingMark } = props;
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { createExamPaperParams: globalExamPaperData } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const [saveData, setSaveData] = useState({ ...initSaveData });
  const [areaParams, setAreaParams] = useState({});
  const [createExamPayload, setCreateExamPayload] = useState([]);
  const [step, setStep] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [bookList, setBookList] = useState({});
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [questionGroup, setQuestionGroup] = useState([]);
  const [treeCheckedList, setTreeCheckedList] = useState([]);

  // 建立測驗
  const [statePostExamCreate, sendPostExamCreate] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postExamCreate(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { examUID, examUrl } = data;
        if (isSuccess) {

        } else {
          openNotificationWithIcon('error', message);
        }
        // setIsLoading(false);
        return {
          examUID,
          examUrl,
          createIsSuccess: isSuccess,
          isSuccess, systemCode, message,
          data,
        };
      },
    },
  });

  const { examUID, examUrl } = statePostExamCreate.context.result || {};

  // 取得題目資訊。
  const [stateGetQuestionType, sendGetQuestionType] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        setIsLoading(true);
        const res = await postGetQuestionBucket(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          // 前端 先擋著 MT,MX,GR 題型。 20220914 by PM 待題目洗出新格式。
          const filterData = data.filter((item) => {
            return !(new RegExp(/MT|MX|GR/g).test(item?.questionType));
          });
          setQuestionGroup(formatQuestionToDiff(filterData));
        } else {
          openNotificationWithIcon('error', message);
        }
        setIsLoading(false);
        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });

  // 重新組成 依照難度分類的題目。
  const formatQuestionToDiff = (questionTypeArray) => {
    if (!questionTypeArray || questionTypeArray.length === 0) return {};

    const questionByDiff = {
      BEGIN: [],
      INTERMEDIATE: [],
      EXPERT: [],
    };

    questionTypeArray.forEach((questionType) => {
      if (!questionType?.questions || questionType?.questions.length === 0) return;
      questionType?.questions.forEach((question) => {
        questionByDiff[question?.difficulty].push({
          ...question,
          quesType: questionType?.questionType,
          quesTypeName: questionType?.questionTypeName,
        });
      });
    });

    return questionByDiff;
  };

  // const [stateGetDrawUpAutoCacheQuery, sendGetDrawUpAutoCacheQuery] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async(_context, event) => {
  //       const res = await getDrawUpAutoCacheQuery(event.pattern, event.payload);
  //       const { data, isSuccess, systemCode, message } = res;
  //       // const { curriculumMap = {}, bookMap = {}, sourceMap = {}, chapterMap = {}, yearMap = {} } = data?.selection || {};
  //       if (isSuccess) { goTop();}
  //       return {
  //         isSuccess, systemCode, message,
  //         data
  //       };
  //     },
  //   },
  // });

  // const [statePostCreatePractice, sendPostCreatePractice] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async(_context, event) => {
  //       const res = await postCreatePractice(event.payload);
  //       const { isSuccess, systemCode, message, data } = res;
  //       if (isSuccess) setStep(step + 1);
  //       const { uid = '' } = data || {};
  //       return {
  //         isSuccess, systemCode, message, uid
  //       };
  //     },
  //   },
  // });

  // const { data } = stateGetDrawUpAutoCacheQuery.context.result || {};
  // const { uid } = statePostCreatePractice.context.result || {};

  const jumpStep = (current) => {
    setStep(current + 1);
  };
  const isChangeData = useMemo(() => {
    let result = false;
    if (step === 1) {
      if (Object.keys(saveData.areaParams).length === 1 || Object.keys(areaParams).length === 0) return false;
      Object.entries(areaParams).forEach(([key, value]) => {
        if (key !== 'filterUsed') {
          if (Array.isArray(value)) {
            if (saveData.areaParams[key].length === value.length) {
              if (saveData.areaParams[key].filter(item => !value.includes(item)).length > 0) result = true;
            } else {
              result = true;
            }
          } else {
            if (saveData.areaParams[key] !== value) result = true;
          }
        }

      });
    }

    return result;
  }, [areaParams, step]);

  const disabled = useMemo(() => {
    let result = false;
    switch (step) {
      default:
      case 2:
        if (!createExamPayload || createExamPayload.length === 0) result = true;
        break;
      case 1:
        Object.entries(areaParams).forEach(([key, value]) => {
          if (key !== 'filterUsed') {
            if (!value || value.length === 0) result = true;
          }

        });
        break;
    }
    return result;
  }, [step, areaParams, createExamPayload]);


  const resetData = () => {
    setSaveData({ ...initSaveData });
  };

  const getSendAreaParams = (data) => {
    setAreaParams({ ...data });
  };

  const getCreateExamPayload = (data) => {
    setCreateExamPayload(data);
  };

  const onChangeFilterUsedQuestions = (e) => {
    setAreaParams({
      areaParams:
      {
        ...saveData.areaParams,
        filterUsed: e.target.checked
      }
    });
    setSaveData({
      ...saveData,
      areaParams: {
        ...saveData.areaParams,
        filterUsed: e.target.checked
      },
      education: globalExamPaperData?.edu || 'J',
      subject: globalExamPaperData?.subject || 'GE',
    });
    const { year, keys, bookIDs } = areaParams;
    // sendGetDrawUpAutoCacheQuery(FETCH_DATA_EVENT.FETCH, {
    //   pattern: 'AutoPattern',
    //   payload: {
    //     year,
    //     education: globalExamPaperData?.edu || 'J',
    //     subject: globalExamPaperData?.subject || 'GE',
    //     bookIDs,
    //     keys,
    //     filterUsed: e.target.checked
    //   }
    // });
  };

  // 已選取的範圍。
  const selectedBooks = useMemo(() => {
    // 非編輯
    if (!areaParams?.version || !areaParams?.year || !areaParams?.curriculum) return {};
    if (!bookList || JSON.stringify(bookList) === '{}' || treeCheckedList.length === 0) return {};
    const filterBookList = { ...bookList[areaParams?.curriculum][areaParams?.year][areaParams?.version] };
    return formatSelectedBook(filterBookList, treeCheckedList);
  }, [bookList, areaParams?.version, areaParams?.year, areaParams?.curriculum, treeCheckedList]);

  const searchParams = useMemo(() => {
    return {
      eduSubject: (globalExamPaperData?.edu + globalExamPaperData?.subject),
      books: areaParams?.bookIDs,
      knowledges: areaParams?.keys,
      sources: areaParams?.source,
      curriculum: areaParams.curriculum,
      year: areaParams.year,
      version: areaParams.version,
      isQuick: true,
    };
  }, [globalExamPaperData.edu,
    globalExamPaperData.subject,
    areaParams.bookIDs,
    areaParams.keys,
    areaParams.source,
    areaParams.year,
    areaParams.version,
    areaParams.curriculum,
    globalExamPaperData.output]);

  const nextStep = async () => {
    const time = new Date().toISOString().substring(0, 10);
    switch (step) {
      case 2:
        sendPostExamCreate(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: searchParams.eduSubject,
            examName: `${time}-${globalExamPaperData?.eduSubjectName?.edu + globalExamPaperData?.eduSubjectName?.subject}自主練習`,
            searchCondition: {
              bookIDs: searchParams.books,
              knowledgeIDs: searchParams.knowledges,
              sourceIDs: searchParams.sources,
              curriculum: searchParams.curriculum,
              year: searchParams.year,
              version: searchParams.version,
              bookSelection: selectedBooks,
            },
            // examType: '',
            outputType: 'SelfLearning', // 自主練習。
            drawUp: 'AutoPattern',
            questionGroups: createExamPayload,
            paperFilter: [],
          }
        });
        setStep(step + 1);
        break;
      case 1:
        if (isChangeData) {
          setSaveData({
            ...initSaveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        } else {
          setSaveData({
            ...saveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        }
        sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: globalExamPaperData?.edu + globalExamPaperData?.subject,
            knowledges: areaParams.keys,
            sources: areaParams.source,
            paperConfig: {
              outputStyle: 'Online',
              drawUpStyle: 'AutoPattern'
            },
          }
        });
        setStep(step + 1);
        break;
      default:
        setStep(step + 1);
        goTop();
        break;
    }


  };
  const prevStep = () => {
    setStep(step - 1);
    goTop();
  };

  const getSaveData = (key, data) => {
    setSaveData({ ...saveData, [key]: data });
  };

  // useEffect(() => {
  //   if (stateGetDrawUpAutoCacheQuery.matches(FETCH_DATA_STATE.DONE)) {
  //     setStep(2);
  //   }
  // }, [stateGetDrawUpAutoCacheQuery.value]);

  // useEffect(() => {
  //   if (!data) return;
  //   setSearchKey(data.searchKey);
  // }, [data]);

  useEffect(() => {
    if (step === 2 && getCookie('createNewPaper') === null) {
      setCookie('createNewPaper', true, 1);
    }
  }, [step]);

  useEffect(() => {
    if (saveData.education === '' || saveData.subject === '') return;
    if (globalExamPaperData?.edu === saveData.education && globalExamPaperData?.subject === saveData.subject) return;
    resetData();
    setStep(1);
  }, [globalExamPaperData]);

  useEffect(() => {
    Object.entries(globalExamPaperData).forEach(([key, value]) => {
      if (value === '') history.push('./');
    });
  }, []);

  const isPro = useMemo(() => {
    return authState.context.userDetails?.userStatus?.useType === 'Premium';
  }, [authState]);

  return (
    <StyledStudentIntelligentPropositionPage className={className} data-testid="StudentIntelligentPropositionPage">
      <ExamPaperHeader pageName='智能命題' globalExamPaperData={globalExamPaperData} step={step} />

      {
        deviceData.isDesktop ?
          <>
            <StepBox steps={steps} current={step - 1} onChange={jumpStep} stepdisabled={step === 3 ? true : false}>
              <StyledPageContent>
                {
                  step === 1 &&
                  <StudentIpAreaSelectContent
                    saveData={saveData}
                    examPaperType="AutoPattern"
                    memberEduSubject={globalExamPaperData}
                    getSendAreaParams={getSendAreaParams}
                    getSaveData={getSaveData}
                    identity={authState.context.userDetails?.userStatus?.identity}
                    isPro={isPro}
                    bookList={bookList}
                    setBookList={setBookList}
                    renderLoadingContent={renderLoadingContent}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    treeCheckedList={treeCheckedList}
                    setTreeCheckedList={setTreeCheckedList}
                  />
                }
                {
                  step === 2 &&
                  <StudentIpExamSettingContent
                    memberEduSubject={globalExamPaperData}
                    saveData={saveData}
                    // examPaperInfo={data?.questionGroup || {}}
                    onChangeFilterUsedQuestions={onChangeFilterUsedQuestions}
                    getCreateExamPayload={getCreateExamPayload}
                    renderLoadingContent={renderLoadingContent}
                    isLoading={isLoading}
                    questionGroup={questionGroup}
                  />
                }
                {
                  step === 3 &&
                  <StudentIpExamCompleteContent
                    examUID={examUID}
                    examUrl={examUrl}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    renderLoadingContent={renderLoadingContent}
                  >

                  </StudentIpExamCompleteContent>
                }
              </StyledPageContent>

              <UiBtnZone>
                {
                  step !== 1 && step !== 3 &&
                  <Button type="solid" textcolor={'#A1A4B1'} variant="brand1" size='large' isFull={false} onClick={() => { prevStep(); }}>
                    上一步
                  </Button>
                }
                {
                  step !== 3 &&
                  <Button
                    disabled={disabled}
                    variant={disabled ? 'ui02' : 'brand1'}
                    size='large'
                    isFull={false}
                    onClick={() => {
                      nextStep();
                      // if (isChangeData) {
                      //   if (step === 1 ) {
                      //     setAreaModalToggle();
                      //   }
                      //   if (step === 2) {
                      //     setModalToggle();
                      //   }
                      // } else {
                      //   nextStep();
                      // }
                    }}
                  >
                    {
                      step !== 2 ?
                        '下一步' :
                        '輸出試卷'
                    }
                  </Button>
                }


              </UiBtnZone>
            </StepBox>
          </>
          :
          <MobileStepBox steps={steps} current={step - 1} onChange={jumpStep} stepdisabled={step === 3 ? true : false}>
            <StyledPageContent>
              {
                step === 1 &&
                <StudentIpAreaSelectContent
                  saveData={saveData}
                  examPaperType="AutoPattern"
                  memberEduSubject={globalExamPaperData}
                  getSendAreaParams={getSendAreaParams}
                  getSaveData={getSaveData}
                  identity={authState.context.userDetails?.userStatus?.identity}
                  bookList={bookList}
                  setBookList={setBookList}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  treeCheckedList={treeCheckedList}
                  setTreeCheckedList={setTreeCheckedList}
                />
              }
              {
                step === 2 &&
                <StudentIpExamSettingContent
                  memberEduSubject={globalExamPaperData}
                  saveData={saveData}
                  // examPaperInfo={data?.questionGroup || {}}
                  onChangeFilterUsedQuestions={onChangeFilterUsedQuestions}
                  getCreateExamPayload={getCreateExamPayload}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  questionGroup={questionGroup}
                />
              }
              {
                step === 3 &&
                <StudentIpExamCompleteContent
                  examUID={examUID}
                  examUrl={examUrl}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  renderLoadingContent={renderLoadingContent}
                >

                </StudentIpExamCompleteContent>
              }
            </StyledPageContent>

            <UiBtnZone>
              {
                step !== 1 && step !== 3 &&
                <Button type="solid" textcolor={'#A1A4B1'} variant="brand1" size='large' isFull={false} onClick={() => { prevStep(); }}>
                  上一步
                </Button>
              }
              {
                step !== 3 &&
                <Button
                  disabled={disabled}
                  variant={disabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => {
                    nextStep();
                    // if (isChangeData) {
                    //   if (step === 1 ) {
                    //     setAreaModalToggle();
                    //   }
                    //   if (step === 2) {
                    //     setModalToggle();
                    //   }
                    // } else {
                    //   nextStep();
                    // }
                  }}
                >
                  {
                    step !== 2 ?
                      '下一步' :
                      '輸出試卷'
                  }
                </Button>
              }
            </UiBtnZone>
          </MobileStepBox>
      }

    </StyledStudentIntelligentPropositionPage>
  );
};